import React, { useEffect, useRef } from 'react';
import Input from '../../../../../components/input/Input';
import { useTranslation } from 'react-i18next';
import { useGeoField } from 'react-geokbd';

const PersonalInformation = ({
  userType,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  validationError,
  executeScroll,
  activeStep,
  setFieldValue
}) => {
  const { t } = useTranslation();
  const validationRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    if (activeStep === 0 || (userType === 'legal' && activeStep === 1)) {
      const timer = setTimeout(() => inputRef.current?.focus(), 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [activeStep]);

  useEffect(() => {
    if (validationError) {
      executeScroll(validationRef);
    }
  }, [validationError]);

  const [firstNameGe, setFirstNameGe] = useGeoField(values.firstNameGe);
  const [lastNameGe, setLastNameGe] = useGeoField(values.firstNameGe);

  useEffect(() => {
    if (userType !== 'foreigner') {
      if (firstNameGe?.length > 1) {
        setFieldValue('firstNameGe', firstNameGe);
      }
    }
  }, [firstNameGe, userType]);

  useEffect(() => {
    if (userType !== 'foreigner') {
      if (lastNameGe?.length > 1) {
        setFieldValue('lastNameGe', lastNameGe);
      }
    }
  }, [lastNameGe, userType]);

  return (
    <div>
      <p
        ref={validationRef}
        className="font-size-16 mb-4 color-red bold text-center"
      >
        {validationError}
      </p>
      <p className="font-size-16 mb-4 bold text-center">
        {t('_PERSONAL_INFORMATION')}
      </p>
      {userType !== 'foreigner' && (
        <Input
          ref={inputRef}
          label={t('_SITE_FIRST_NAME_GEO')}
          value={firstNameGe}
          name="firstNameGe"
          onChange={setFirstNameGe}
          error={touched.firstName && errors.firstNameGe ? t(errors.firstNameGe) : ''}
        />
      )}
      <Input
        ref={values.nonGeorgianCitizen === 1 ? inputRef : null}
        label={t('_SITE_FIRST_NAME_LAT')}
        value={values.firstName}
        name="firstName"
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.firstName && errors.firstName ? t(errors.firstName) : ''}
      />
      {
        (userType !== 'foreigner' ? (
          <Input
            label={t('_SITE_LAST_NAME_GEO')}
            value={lastNameGe}
            onChange={setLastNameGe}
            error={touched.lastNameGe && errors.lastNameGe ? t(errors.lastNameGe) : ''}
          />
        ) : null)}
      <Input
        label={t('_SITE_LAST_NAME_LAT')}
        value={values.lastName}
        name="lastName"
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.lastName && errors.lastName ? t(errors.lastName) : ''}
      />
      {userType !== 'legal' && (
        <Input
          label={t(userType === 'foreigner' ? '_IDENTNO_NON_CITIZEN' : '_IDENTNO')}
          value={values.identification}
          name="identification"
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.identification && errors.identification
              ? t(errors.identification)
              : ''
          }
        />
      )}
    </div>
  );
};

export default PersonalInformation;
