import React from 'react';
import Select, { components } from 'react-select';
import '../DropDown/style.scss';


const { Option, ValueContainer } = components;
const IconOption = props => (
  <Option {...props} className='dropdown-option' >
    <img
      src={require('../../assets/images/flags/' + props.data.icon)}
      //  src={''}
      style={{ width: 30, marginRight: '7px' }} z
      alt={props.data.label}
    />
    {props.data.label}
  </Option>
);

const SelectedValue = (props) => {
  const value = props?.selectProps?.value;
  return <ValueContainer {...props} className='dropdown-option'>
    <img
      src={require('../../assets/images/flags/' + value?.icon)}
      style={{ width: 30 }}
      alt={value?.label}
    />
    {value?.label}
  </ValueContainer>;
};

const Dropdown = ({ value = {}, options, defaultValue, onChange = () => { }, disabled = false }) => {
  return (
    <Select
      value={value}
      options={options || []}
      components={{ Option: IconOption, ValueContainer: SelectedValue, IndicatorSeparator: () => null }}
      onChange={onChange}
      isDisabled={disabled}
      className='custom-dropdown'
    />
  );
};

export default Dropdown;
