// hooks
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

// scss
import 'swiper/swiper-bundle.min.css';

// components
import ChangablePackageDetails from '../../../../components/ChangablePackageDetails';
import apiRequest from '../../../../components/ApiRequest';
import Button from '../../../../components/Button';
import GlobalContext from '../../../../components/GlobalContext';
import LockerSettings from './components/LockerSettings';
import BranchListSettings from './components/BranchListSettings';
import SideBar from '../../../../components/sidebar/SideBar';
import Radio from '../../../../components/radio/Radio';

// assets
import { LocationIcon } from '../../../../assets/icons';

const BranchesAndLockers = ({
  setOpenSettings,
  branchesData,
  originalBranch,
  locker,
  branch
}) => {
  const { setAlert } = useContext(GlobalContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [openBranches, setopenBranches] = useState(false);
  const branchesListRef = useRef(null);
  const [selectedBranch, setSelectedBranch] = useState(branch);
  const [selectedLocker, setSelectedLocker] = useState(locker?.default_locker);
  const [wantToUseLocker, setWantToUseLocker] = useState(locker?.want_to_use_locker);

  const updateBranchAndLocker = useMutation(
    () =>
      apiRequest(
        {
          command: 'save_customer_settings',
          sessionId: localStorage.getItem('token')
        },
        {
          data: {
            branch_settings: {
              default_branch: selectedBranch
            },
            locker_settings: {
              want_to_use_locker: wantToUseLocker,
              locker_id: selectedLocker
            }
          }
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          setOpenSettings(false);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      }
    }
  );

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      await updateBranchAndLocker.mutateAsync();
      queryClient.invalidateQueries('getCustomerSettings');
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleOpenSecondStep = () => {
    branchesListRef.current.open();
  };

  const handleCloseSecondStep = () => {
    branchesListRef.current.close();
  };

  const selectedBranchTitle = branchesData.filter((item) => item.id === selectedBranch)?.length > 0
    ? branchesData.filter((item) => item.id === selectedBranch).map(branch => t(`${branch.name}`)) : t('_CHOOSE_BRANCH');

  const selectedBranchContent = branchesData.filter((item) => item.id === selectedBranch)?.length > 0
    ? branchesData.filter((item) => item.id === selectedBranch).map(branch => t(`${branch.address}`)) : '';

  useEffect(() => {
    setSelectedBranch(originalBranch);
  }, []);

  return (
    <>
      <div className="d-flex flex-column height-100 justify-content-between sideBarChildComponent">
        <h1 className="font-size-16 mb-5 bold">{t('_select_branch_or_locker')}</h1>
        <form
          className="d-flex flex-column height-100 justify-content-between form"
          onSubmit={handleSubmit}
        >
          <div className="sidebar-child-scroll">
            <h1 className="font-size-14 mb-2 bold">{t('_BRANCH')}</h1>
            <div className="pr-60 d-flex">
              <Radio
                value={wantToUseLocker}
                onChange={() => setWantToUseLocker(0)}
                selected={wantToUseLocker === 0}
              />
              <ChangablePackageDetails
                onClickPassed={handleOpenSecondStep}
                containerStyle="cursor-pointer w-full"
                icon={<LocationIcon />}
                iconStyle="black-icon"
                title={selectedBranchTitle}
                viewable
                content={selectedBranchContent}
              />
            </div>
            <div className='mt-4'>
              <h1 className="font-size-14 mb-2 bold">{t('_LOCKER')}</h1>
              <div className='d-flex align-items-center w-full'>
                <Radio
                  value={wantToUseLocker}
                  onChange={() => setWantToUseLocker(1)}
                  selected={wantToUseLocker === 1}
                />
                <LockerSettings
                  selectedLocker={selectedLocker}
                  setSelectedLocker={setSelectedLocker}
                  locker={locker}
                />
              </div>
            </div>
          </div>
          <div className="sidebarButtonDiv">
            <Button
              isLoading={updateBranchAndLocker.isLoading}
              type="submit"
              btnStyle="bg-orange primary-btn-w-h"
              text={t('_SAVE')}
              color="color-white"
            />
          </div>
        </form>
      </div>
      <SideBar
        openSidebar={openBranches}
        setOpenSidebar={setopenBranches}
        ref={branchesListRef}
      >
        <BranchListSettings
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
          handleCloseSecondStep={handleCloseSecondStep}
          setWantToUseLocker={setWantToUseLocker}
        />
      </SideBar>
    </>
  );
};

export default BranchesAndLockers;
