export const branches = [
  {
    id: 'OFFICE_20',
    name: '_TSERETELI_BRANCH',
    location: {
      latitude: 41.744329, 
      longitude: 44.774899
    },
    map_link: 'https://maps.app.goo.gl/PYS1guU9XKo2f4QC8',
    working_hour: {
      en: [
        {
          key: '_MON_FRI',
          value: '9am - 9pm'
        },
        {
          key: '_SAT',
          value: '10am - 5pm'
        }
      ],
      ka: [
        {
          key: '_MON_FRI',
          value: '09:00 - 21:00'
        },
        {
          key: '_SAT',
          value: '10:00 - 17:00'
        }
      ]
    },
    address: '_TSERETELI_ADDRESS',
    isNew: true
  },
  {
    id: 'OFFICE_19',
    name: '_BAGEBI_BRANCH',
    location: {
      latitude: 41.710765, 
      longitude: 44.727381
    },
    map_link: 'https://maps.app.goo.gl/4iVchuG8iKopsJyH7',
    working_hour: {
      en: [
        {
          key: '_MON_FRI',
          value: '9am - 9pm'
        },
        {
          key: '_SAT',
          value: '10am - 5pm'
        }
      ],
      ka: [
        {
          key: '_MON_FRI',
          value: '09:00 - 21:00'
        },
        {
          key: '_SAT',
          value: '10:00 - 17:00'
        }
      ]
    },
    address: '_BAGEBI_ADDRESS',
    isNew: false
  },
  {
    id: 'OFFICE_15',
    name: '_KUTAISI_BRANCH',
    location: {
      latitude: 42.261399,
      longitude: 42.708262
    },
    map_link: 'https://maps.app.goo.gl/Kb1mMAaeKEEi7dKD7',
    working_hour: {
      en: [
        {
          key: '_MON_FRI',
          value: '9am - 9pm'
        },
        {
          key: '_SAT',
          value: '10am - 5pm'
        }
      ],
      ka: [
        {
          key: '_MON_FRI',
          value: '09:00 - 21:00'
        },
        {
          key: '_SAT',
          value: '10:00 - 17:00'
        }
      ]
    },
    address: '_KUTAISI_BRANCH_ADDRESS',
    isNew: false
  },
  {
    id: 'OFFICE_18',
    name: '_BATUMI_BRANCH',
    location: {
      latitude: 41.643344,
      longitude: 41.633054
    },
    map_link: 'https://goo.gl/maps/RdqQrVj6PAhnjMsv6',
    working_hour: {
      en: [
        {
          key: '_MON_FRI',
          value: '9am - 9pm'
        },
        {
          key: '_SAT',
          value: '10am - 5pm'
        }
      ],
      ka: [
        {
          key: '_MON_FRI',
          value: '09:00 - 21:00'
        },
        {
          key: '_SAT',
          value: '10:00 - 17:00'
        }
      ]
    },
    address: '_BATUMI_BRANCH_ADDRESS',
    isNew: false
  },
  {
    id: 'OFFICE_17',
    name: '_VARKETILI_BRANCH',
    location: {
      latitude: 41.711968,
      longitude: 44.882500
    },
    map_link: 'https://maps.app.goo.gl/V1MpYqMcPizMcS6GA',
    working_hour: {
      en: [
        {
          key: '_MON_FRI',
          value: '9am - 9pm'
        },
        {
          key: '_SAT',
          value: '10am - 5pm'
        }
      ],
      ka: [
        {
          key: '_MON_FRI',
          value: '09:00 - 21:00'
        },
        {
          key: '_SAT',
          value: '10:00 - 17:00'
        }
      ]
    },
    address: '_VARKETILI_BRANCH_ADDRESS',
    isNew: false
  },
  {
    id: 'OFFICE_7',
    name: '_VAJA_BRANCH_96',
    location: {
      latitude: 41.722761,
      longitude: 44.721804
    },
    map_link: 'https://goo.gl/maps/Lk54HJviTfj47Hmw5',
    working_hour: {
      en: [
        {
          key: '_MON_FRI',
          value: '9am - 9pm'
        },
        {
          key: '_SAT',
          value: '10am - 5pm'
        }
      ],
      ka: [
        {
          key: '_MON_FRI',
          value: '09:00 - 21:00'
        },
        {
          key: '_SAT',
          value: '10:00 - 17:00'
        }
      ]
    },
    address: '_VAJA_ADDRESS_96',
    isNew: false
  },
  {
    id: 'OFFICE_1',
    name: '_MIC_BRANCH',
    map_link: 'https://goo.gl/maps/ZNRVHUTcQT4WuBov5',
    location: {
      latitude: 41.723842,
      longitude: 44.764105
    },
    working_hour: {
      en: [
        {
          key: '_MON_FRI',
          value: '9am - 9pm'
        },
        {
          key: '_SAT',
          value: '10am - 5pm'
        }
      ],
      ka: [
        {
          key: '_MON_FRI',
          value: '09:00 - 21:00'
        },
        {
          key: '_SAT',
          value: '10:00 - 17:00'
        }
      ]
    },
    address: '_MIC_ADDRESS',
    isNew: false
  },
  {
    id: 'OFFICE_6',
    name: '_VAKE_BRANCH',
    map_link: 'https://goo.gl/maps/PW3dN9SUZxyqpgWM7',
    location: {
      latitude: 41.710139,
      longitude: 44.758487
    },
    working_hour: {
      en: [
        {
          key: '_MON_FRI',
          value: '9am - 9pm'
        },
        {
          key: '_SAT',
          value: '10am - 5pm'
        }
      ],
      ka: [
        {
          key: '_MON_FRI',
          value: '09:00 - 21:00'
        },
        {
          key: '_SAT',
          value: '10:00 - 17:00'
        }
      ]
    },
    address: '_VAKE_ADDRESS',
    isNew: false
  },
  {
    id: 'OFFICE_16',
    name: '_KEKELIDZE_BRANCH',
    location: {
      latitude: 41.708194,
      longitude: 44.778543
    },
    map_link: 'https://goo.gl/maps/MvZTmdQdgGAnoZ6FA',
    working_hour: {
      en: [
        {
          key: '_MON_FRI',
          value: '9am - 9pm'
        },
        {
          key: '_SAT',
          value: '10am - 5pm'
        }
      ],
      ka: [
        {
          key: '_MON_FRI',
          value: '09:00 - 21:00'
        },
        {
          key: '_SAT',
          value: '10:00 - 17:00'
        }
      ]
    },
    address: '_KEKELIDZE_ADDRESS',
    isNew: false
  },
  {
    id: 'OFFICE_2',
    name: '_DIG_BRANCH',
    map_link: 'https://maps.app.goo.gl/fcMAQCu1HCGJnzwX9',
    location: {
      latitude: 41.767479,
      longitude: 44.775156
    },
    working_hour: {
      en: [
        {
          key: '_MON_FRI',
          value: '9am - 9pm'
        },
        {
          key: '_SAT',
          value: '10am - 5pm'
        }
      ],
      ka: [
        {
          key: '_MON_FRI',
          value: '09:00 - 21:00'
        },
        {
          key: '_SAT',
          value: '10:00 - 17:00'
        }
      ]
    },
    address: '_DIG_ADDRESS',
    isNew: false
  },
  {
    id: 'OFFICE_4',
    name: '_GLD_BRANCH',
    map_link: 'https://maps.app.goo.gl/sUBuepCGbCX1Zzks7?g_st=ic',
    location: {
      latitude: 41.803583, 
      longitude: 44.828806
    },
    working_hour: {
      en: [
        {
          key: '_MON_FRI',
          value: '9am - 9pm'
        },
        {
          key: '_SAT',
          value: '10am - 5pm'
        }
      ],
      ka: [
        {
          key: '_MON_FRI',
          value: '09:00 - 21:00'
        },
        {
          key: '_SAT',
          value: '10:00 - 17:00'
        }
      ]
    },
    address: '_GLD_ADDRESS',
    isNew: false
  },
  {
    id: 'OFFICE_5',
    name: '_ISANI_BRANCH',
    location: {
      latitude: 41.688204,
      longitude: 44.840438
    },
    map_link: 'https://goo.gl/maps/qAKdN29Td2efa7GB8',
    working_hour: {
      en: [
        {
          key: '_MON_FRI',
          value: '9am - 9pm'
        },
        {
          key: '_SAT',
          value: '10am - 5pm'
        }
      ],
      ka: [
        {
          key: '_MON_FRI',
          value: '09:00 - 21:00'
        },
        {
          key: '_SAT',
          value: '10:00 - 17:00'
        }
      ]
    },
    address: '_ISANI_ADDRESS',
    isNew: false
  },
  {
    id: 'OFFICE_13',
    name: '_DIDI_DIGOMI_BRANCH',
    location: {
      latitude: 41.796447,
      longitude: 44.759612
    },
    map_link: 'https://maps.app.goo.gl/b8oHasJYLbvgwp9TA',
    working_hour: {
      en: [
        {
          key: '_MON_FRI',
          value: '9am - 9pm'
        },
        {
          key: '_SAT',
          value: '10am - 5pm'
        }
      ],
      ka: [
        {
          key: '_MON_FRI',
          value: '09:00 - 21:00'
        },
        {
          key: '_SAT',
          value: '10:00 - 17:00'
        }
      ]
    },
    address: '_DIDI_DIGOMI_ADDRESS',
    isNew: false
  }
];
