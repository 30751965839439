// hooks
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

// components
import apiRequest from '../../components/ApiRequest';
import GlobalContext from '../../components/GlobalContext';
import ChangablePackageDetails from '../../components/ChangablePackageDetails';

// assets
import { LockerSettingsIcon, RecycleBinIcon } from '../../assets/icons';

// libraries
import Wrapper from '../../components/Wrapper';
import SideBar from '../../components/sidebar/SideBar';
import Button from '../../components/Button';

const Notifications = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { token, setShowLoader } = useContext(GlobalContext);
  const notificationSidebarRef = useRef();
  const [currentNotification, setCurrentNotification] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const { data: getNotifications, isLoading } = useQuery('getCustomerNotifications', () =>
    apiRequest({
      command: 'get_notification_list',
      sessionId: localStorage.getItem('token')
    })
  );

  const { mutateAsync: removeNotificationHandler } = useMutation((name) => {
    apiRequest(
      {
        command: 'remove_notification',
        sessionId: localStorage.getItem('token')
      },
      {
        notificationId: currentNotification?.id
      }
    ).then((res) => {
      setShowLoader(false);
      setCurrentNotification(null);
      setShowNotification(false);
      queryClient.invalidateQueries('getCustomerNotifications');
    });
  });

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  const notifications = !isLoading && getNotifications.data.result;

  // console.log(notifications[0].body);

  return (
    <Wrapper>
      <div className='settings-container'>
        <div className='notifications-container pt-5'>
          <h1 className='mb-5'>{t('_NOTIFICATIONS')}</h1>
          <SideBar openSidebar={showNotification} setOpenSidebar={setShowNotification} ref={notificationSidebarRef}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 20, marginBottom: 20 }}>
              <div style={{ fontFamily: 'HelveticaBoldCaps' }}>{currentNotification?.title}</div>
              <div>
                <Button
                  handleClick={() => {
                    setShowLoader(true);
                    removeNotificationHandler();
                  }}
                  text={t('_KEY_DELETE_CARD')}
                  btnStyle='btn-danger color-white mybutton-danger notificationDeleteBtn'
                  icon={<RecycleBinIcon color='white' />}
                />
              </div>
            </div>
            <div style={{ height: '97%', overflow: 'scroll' }}>
              <div style={{ fontFamily: 'HelveticaNeueLTGEO-55Roman', fontSize: '13px', marginTop: 10 }}>{currentNotification?.startDate}</div>
              <div
                className='web-notification-item'
                style={{ fontFamily: 'HelveticaNeueLTGEO-55Roman', fontSize: 14, marginTop: 10 }}
                dangerouslySetInnerHTML={{ __html: currentNotification?.body }}
              ></div>
            </div>
          </SideBar>
          {!isLoading && (
            <div>
              {notifications?.length === 0 ? (
                <div className='bg-white d-flex flex-column align-items-center pt-5 pb-5 border-rad-12'>
                  <p className='font-size-16 fw-bolder'>{t('_NO_NOTIFICATION_FOUND')}</p>
                </div>
              ) : (
                notifications?.map((item, index) => (
                  <ChangablePackageDetails
                    title={
                      <>
                        <div>
                          <div>{item.title}</div>
                          <div style={{ display: 'flex', marginTop: 10 }}>
                            <div
                              style={{ fontFamily: 'HelveticaNeueLTGEO-55Roman', height: 18, overflow: 'hidden', flexGrow: 1 }}
                              dangerouslySetInnerHTML={{ __html: item.body !== null ? item.body.replace(/<style>[^<]*<\/style>/gi, '').replace(/<\/?[^>]+(>|$)/g, '') : '' }}
                            ></div>
                            <div style={{ flexShrink: 0 }}>{item.startDate}</div>
                          </div>
                        </div>
                      </>
                    }
                    key={index}
                    onClickPassed={() => {
                      setCurrentNotification(item);
                      setShowNotification(true);
                    }}
                    containerStyle='bg-white cursor-pointer'
                    noFlex={true}
                  />
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Notifications;
