import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-awesome-lightbox';
import { ImageScale } from '../../assets/icons/index';

export const CutdownedBlock = ({ trackingImage, trackingImage2, chargeables }) => {
  const { t } = useTranslation();
  const [showImage, setShowImage] = useState(false);
  const [currentImage, setCurrentImage] = useState(trackingImage);

  const changeImage = (image) => {
    setCurrentImage(image);
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <div style={{ display: 'flex' }}>
        <div className={`imageTab ${currentImage === trackingImage ? 'activeImageTab' : ''}`}
          onClick={() => changeImage(trackingImage)}
        >{t('_BEFORE_CUTDOWN')}</div>
        <div className={`imageTab rightTab ${currentImage === trackingImage2 ? 'activeImageTab' : ''}`}
          onClick={() => changeImage(trackingImage2)}
        >{t('_AFTER_CUTDOWN')}</div>
      </div>
      <>
        <div
          className="PackageDetailsImageDiv2"
          onClick={() => setShowImage(!showImage)}
        >
          <img src={currentImage} alt="Package" />
          <div className="PackageImageHoverDiv d-flex justify-content-center align-items-center">
            <ImageScale />
          </div>
        </div>
        {showImage && (
          <Lightbox
            image={currentImage}
            onClose={() => setShowImage(false)}
            keyboardInteraction
          />
        )}
      </>
      <div className='hawbComment d-flex justify-content-between align-items-stretch mb-2' style={{ fontSize: 14 }}>
        {t('_BEFORE_CHARGABLE')}: {chargeables.before} {t('_KG')} <br />
        {t('_AFTER_CHARGABLE')}: {chargeables.after} {t('_KG')} <br />
        {t('_BENEFIT')}: {(chargeables.before - chargeables.after).toFixed(2)} {t('_KG')} (${((chargeables.before - chargeables.after) * 9).toFixed(2)})
      </div>
    </div>
  );
};
