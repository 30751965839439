// hooks
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import { Col, Container, Row } from 'react-bootstrap';
import IndividualCustomer from './components/IndividualCustomer';
import { Swiper, SwiperSlide } from 'swiper/react';
import StepIcons from './components/StepIcons';

// libraries
import SwiperCore, { Pagination } from 'swiper';

// scss
import 'swiper/swiper-bundle.min.css';

// assets
import {
  IndividualPersonIcon,
  LegalPersonIcon,
  OrangeArrowIcon
} from '../../../assets/icons';
import SmallIcon from '../../../components/SmallIcon';
import LegalCustomer from './components/LegalCustomer';

SwiperCore.use([Pagination]);
export default function Registration({
  setOpenSidebar,
  activeForm,
  activeStep,
  setActiveStep,
  setActiveForm
}) {
  const [sliderStep, setSliderStep] = useState(null);
  const { t } = useTranslation();
  const [swiper, setSwiper] = useState(null);

  const increaseStep = () => setActiveStep(activeStep + 1);
  const closeForm = () =>
    activeStep > 0 ? setActiveStep(activeStep - 1) : setActiveStep(0);

  useEffect(() => {
    if (activeStep > 0) {
      setSliderStep(0);
    }
    if (swiper) swiper.slideTo(activeStep);
  }, [activeStep]);

  useEffect(() => {
    if (activeForm === '') {
      setOpenSidebar(false);
    }
  }, [activeForm]);

  return (
    <div className="height-100 registration-container overflow-hidden">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="font-size-16 bold mb-12">{t('_REGISTER')}</h1>
        {activeStep > 0 && (
          <StepIcons activeForm={activeForm} sliderStep={sliderStep} />
        )}
      </div>
      <Swiper
        onSwiper={setSwiper}
        allowTouchMove={false}
        slidesPerView={1}
        spaceBetween={20}
        className="mySwiper height-100 registration form"
      >
        <SwiperSlide>
          <div
            className={`${activeForm === 'registration'
              ? 'registration-first-slide pr-60'
              : 'hidden'
              }`}
          >
            <p className="font-size-16 mb-4 text-center">
              {t('_CHOOSE_CUSTOMER_TYPE')}
            </p>
            <div className="d-flex user-type-div flex-column align-items-center">
              <Container>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="d-flex align-items-center">
                      <SmallIcon
                        bg="icon-light-bg"
                        containerStyle="me-3"
                        img={<IndividualPersonIcon />}
                      />
                      <p className="font-size-16"> {t('_INDIVIDUAL_PERSON')}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex justify-content-center pb-3 flex-column">
                    <div
                      onClick={() => {
                        setActiveForm('individual');
                        increaseStep();
                      }}
                      className="d-flex mt-50 cursor-pointer mb-5 justify-content-between"
                    >
                      <p className="ms-87 registr-user-type-text mb-3">
                        {t('_GEORGIAN')}
                      </p>
                      <span>
                        <OrangeArrowIcon />
                      </span>
                    </div>
                    <div className="gray-line" />
                    <div
                      onClick={() => {
                        setActiveForm('foreigner');
                        increaseStep();
                      }}
                      className="d-flex cursor-pointer  justify-content-between"
                    >
                      <p className="ms-87 registr-user-type-text cursor-pointer">
                        {t('_FOREIGNER')}
                      </p>
                      <span>
                        <OrangeArrowIcon />
                      </span>
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
            <div className="d-flex user-type-div justify-content-between align-items-center">
              <Container>
                <Row>
                  <Col lg={12} xs={12}>
                    <div
                      onClick={() => {
                        setActiveForm('legal');
                        increaseStep();
                      }}
                      className="d-flex flex-row justify-content-between"
                    >
                      <div className="d-flex align-items-center">
                        <SmallIcon
                          bg="icon-light-bg"
                          containerStyle="me-3 pt-2"
                          img={<LegalPersonIcon />}
                        />
                        <p className="cursor-pointer">{t('_LEGAL_PERSON')}</p>
                      </div>
                      <span className="mt-2">
                        <OrangeArrowIcon />
                      </span>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {activeForm === 'individual' || activeForm === 'foreigner' ? (
            <IndividualCustomer
              setActiveStep={setActiveStep}
              userType={activeForm}
              close={setActiveForm}
              setOpenSidebar={setOpenSidebar}
              closeForm={closeForm}
              setActiveForm={setActiveForm}
              sliderStep={sliderStep}
              setSliderStep={setSliderStep}
            />
          ) : (
            activeForm === 'legal' && (
              <LegalCustomer
                setActiveStep={setActiveStep}
                userType={`${activeForm}`}
                close={setActiveForm}
                setOpenSidebar={setOpenSidebar}
                closeForm={closeForm}
                setActiveForm={setActiveForm}
                sliderStep={sliderStep}
                setSliderStep={setSliderStep}
              />
            )
          )}
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
