import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PinIcon } from '../../assets/icons';
import ChangablePackageDetails from '../ChangablePackageDetails';
import Toggle from '../toggle/Toggle';

export default function HawbBranch({
  branches,
  defaultBranch,
  defaultLocker,
  onClick,
  lockers
}) {
  const { t } = useTranslation();
  const [branchType, setBranchType] = useState(true);

  useEffect(() => {
    defaultLocker && setBranchType(false);
  }, []);

  return (
    <div className="HawbBranch sideBarChildComponent">
      <h3 className="SectionTitle sidebarTitle">
        {t('_CHOOSE_BRANCH_LOCKER')}
      </h3>
      <div className="sidebar-child-scroll">
        <div className="parmaTypeWrapper">
          <Toggle
            text={t('_BRANCH')}
            checked={branchType}
            onChange={() => {
              setBranchType(!branchType);
            }}
          />
          <Toggle
            text={t('_LOCKER')}
            checked={!branchType}
            onChange={() => {
              setBranchType(!branchType);
            }}
          />
        </div>
        <div className="branch-locker">
          {branchType
            ? branches?.map((branch) => {
              return (
                  <ChangablePackageDetails
                    icon={<PinIcon />}
                    title={t(branch.name)}
                    key={branch.id}
                    item={branch}
                    showNew={true}
                    selected={branch.id === defaultBranch || false}
                    onClickPassed={() => onClick(branch.id, -1)}
                  />
              );
            })
            : lockers &&
              lockers
                .filter((locker) => locker.title !== '_TEST_LOCKER')
                .map((locker) => {
                  return (
                    <ChangablePackageDetails
                      title={t(locker.title)}
                      content={t(locker.address)}
                      key={locker.id}
                      selected={locker.title === defaultLocker?.title || false}
                      src={locker.logo}
                      onClickPassed={() => onClick(null, locker.id)}
                    />
                  );
                })}
        </div>
      </div>
    </div>
  );
}
