import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../components/GlobalContext';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button';
import './CustomPopup.css';
import { useMutation } from 'react-query';
import apiRequest from '../ApiRequest';
import { getCustomerInfoAsyncThunk } from '../../store/thunks/customer.thunk';
import { Alert, LoaderIcon } from '../../assets/icons';
import warningIco from '../../assets/images/Antu_dialog-warning.svg';

function CustomPopup() {
  const dispatch = useDispatch();
  const { setShowLoader, token } = useContext(GlobalContext);
  const notification = useSelector((state) => state.customer.notification);
  const confirmation = useSelector((state) => state.customer.confirmation);
  const confirmationData = useSelector((state) => state.customer.confirmationData);
  const [isLoading, setIsLoading] = useState(false);

  const [confirmOrder, setConfirmOrder] = useState(0);
  const [currentMessage, setCurrentMessage] = useState('');
  const [yesButtonTitle, setYesButtonTitle] = useState('');
  const [noButtonTitle, setNoButtonTitle] = useState('');
  const [hasWarning, setHasWarning] = useState(false);

  const confirmPopup = useMutation(
    (name) =>
      apiRequest(
        {
          command: 'confirm_popup',
          sessionId: token
        },
        {
          confirmationId: confirmation?.id,
          button: name
        }
      ),
    {
      onSuccess: (response) => {
        setShowLoader(false);
        if (response.data.status === 'OK') {
          dispatch(getCustomerInfoAsyncThunk());
        }
      },
      onError: (response) => {
        console.log(response);
      }
    }
  );

  const clickHandler = (name) => {
    setShowLoader(true);
    confirmPopup.mutate(name);
  };

  useEffect(() => {
    setCurrentMessage(confirmationData?._CONFIRMATION_1);
    setYesButtonTitle(confirmationData?._YES_BUTTON_TITLE_1);
    setNoButtonTitle(confirmationData?._NO_BUTTON_TITLE_1);
    setHasWarning(false);
  }, [confirmationData]);

  const yesButtonClickHandler = () => {
    if (confirmOrder === 0) {
      clickHandler('TURN_ON_AUTO_CUT_DOWN');
      // setConfirmOrder(1);
      // setCurrentMessage(confirmationData?._CONFIRMATION_2);
      // setYesButtonTitle(confirmationData?._YES_BUTTON_TITLE_2);
      // setNoButtonTitle(confirmationData?._NO_BUTTON_TITLE_2);
      // setHasWarning(true);
    } else if (confirmOrder === 1) {
      // Alert.alert('ჩავრთეთ გადაფუთვა და დავხურეთ ფანჯრები');
    } else if (confirmOrder === 2) {
      clickHandler('NOT_AGREE');
      // Alert.alert('არ! ჩაგვირთვია გადაფუთვა და დავხურეთ ფანჯრები');
    }
  };

  const noButtonClickHandler = () => {
    if (confirmOrder === 0) {
      setConfirmOrder(2);
      setCurrentMessage(confirmationData?._CONFIRMATION_3);
      setYesButtonTitle(confirmationData?._YES_BUTTON_TITLE_3);
      setNoButtonTitle(confirmationData?._NO_BUTTON_TITLE_3);
      setHasWarning(true);
    } else if (confirmOrder === 1 || confirmOrder === 2) {
      setConfirmOrder(0);
      setCurrentMessage(confirmationData?._CONFIRMATION_1);
      setYesButtonTitle(confirmationData?._YES_BUTTON_TITLE_1);
      setNoButtonTitle(confirmationData?._NO_BUTTON_TITLE_1);
      setHasWarning(false);
    }
  };

  if (confirmation?.id !== '11') {
    return <></>;
  } else if (notification !== null) {
    return <></>;
  }
  return (
    <>
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 60
        }}
      >
        <div style={{ zIndex: 61, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 12 }} className='popup-width'>
            {hasWarning && (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <img style={{ width: 64 }} src={warningIco} alt />
                <div style={{ color: '#b45309', paddingBottom: 20 }}>{confirmationData?._WARNING}</div>
              </div>
            )}
            <div style={{ overflow: 'auto', maxHeight: 550 }}>
              <div dangerouslySetInnerHTML={{ __html: currentMessage }}></div>

              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  gap: 10
                }}
              >
                <Button
                  handleClick={yesButtonClickHandler}
                  btnStyle='bg-orange p-2 min-width'
                  text={yesButtonTitle}
                  color='color-white'
                  btnColor={'#F68220'}
                />

                <Button
                  handleClick={noButtonClickHandler}
                  btnStyle='bg-orange  p-2 min-width'
                  text={noButtonTitle}
                  color='color-white'
                  btnColor={'#475569'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomPopup;
