// hooks
import React, { useContext } from 'react';

// scss
import './Loader.scss';

// assets
import { LoaderIcon } from '../../assets/icons';
import GlobalContext from '../GlobalContext';

const Loader = () => {
  const { showLoader } = useContext(GlobalContext);

  return (
    <div className={'loader-container loader-container-' + showLoader}>
      <div className={'loader-bg loader-bg-' + showLoader}>
        <div className="loader">
          <LoaderIcon />
        </div>
      </div>
    </div>
  );
};

export default Loader;
