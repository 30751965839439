// hooks
import React, { useState } from 'react';

// assets
import { Arrow, FaqIcon } from '../../assets/icons';

// components
import SmallIcon from '../../components/SmallIcon';
import SidebarItem from './SidebarItem';

export default function Item({ item, opened }) {
  const [hoverIcon, setHoverIcon] = useState(false);
  const [showChild, setShowChild] = useState(false);

  const isOpened = opened || showChild;

  return (
    <div
      onMouseEnter={() => setHoverIcon(true)}
      onMouseLeave={() => setHoverIcon(!hoverIcon)}
      className={`faq-div cursor-pointer mb-2 ${isOpened && 'faq-div-open'}`}
    >
      <div
        onClick={() => setShowChild(!showChild)}
        className={`d-flex justify-content-between opened-margin ${showChild}`}
      >
        <div className="d-flex align-items-center">
          <div className="me-4 ">
            <SmallIcon
              containerStyle="faqlist-icon"
              bg="small-icon-primary-bg faq-icon"
              img={<FaqIcon />}
            />
          </div>
          <div className="font-size-14" dangerouslySetInnerHTML={{ __html: item.category_name }} />
        </div>
        <div className="d-flex align-items-center me-3">
          <div className={`arrow-span ${showChild}`}>
            <Arrow />
          </div>
        </div>
      </div>
      {item.items.map((i, index) => (
        <SidebarItem key={i.id} showChild={isOpened} index={index} i={i} />
      ))}
    </div>
  );
}
