import * as Yup from 'yup';

export default [
  Yup.object().shape({
    nonGeorgianCitizen: Yup.number(),
    firstNameGe: Yup.string().when('nonGeorgianCitizen', {
      is: (value) => value === 0,
      then: Yup.string()
        .min(2, '_ERR_FIRSTNAMEGE_MIN_LENGTH')
        .required('_PARAMETER_IS_REQUEIRED')
        .matches(/^[ა-ჰ]+$/, '_ERR_INVLID_FIRSTNAMEGE'),
      otherwise: Yup.string()
    }),
    firstName: Yup.string()
      .required('_PARAMETER_IS_REQUEIRED')
      .min(2, '_ERR_FIRSTNAMEGE_MIN_LENGTH')
      .matches(/^[A-Za-z]+$/, '_ERR_INVLID_FIRSTNAME'),
    lastNameGe: Yup.string().when('nonGeorgianCitizen', {
      is: (value) => value === 0,
      then: Yup.string()
        .min(1, '_ERR_FIRSTNAMEGE_MIN_LENGTH')
        .required('_PARAMETER_IS_REQUEIRED')
        .matches(/^[ა-ჰ]+$/, '_ERR_INVLID_FIRSTNAMEGE'),
      otherwise: Yup.string()
    }),
    lastName: Yup.string()
      .required('_PARAMETER_IS_REQUEIRED')
      .min(3, '_ERR_LASTNAMEGE_MIN_LENGTH')
      .matches(/^[A-Za-z]+$/, '_ERR_INVLID_LASTNAME'),
    identification: Yup.string().when('nonGeorgianCitizen', {
      is: (value) => value === 0,
      then: Yup.string()
        .min(11, '_ERR_INVLID_ID_NO')
        .max(11, '_ERR_INVLID_ID_NO')
        .required('_PARAMETER_IS_REQUEIRED')
        .matches(/^[0-9]+$/, 'დასაშვებია მხოლოდ რიცხვები'),
      otherwise: Yup.string()
        .required('_PARAMETER_IS_REQUEIRED')
        .min(6, '_IDENTNO_NON_CITIZEN')
        .matches(/^[a-zA-Z0-9._]+$/, '_ERR_NOT_LATIN_CHAR')
    })
  }),
  Yup.object().shape({
    email: Yup.string()
      .email('_ERR_EMAIL_REQUIRED email')
      .required('_PARAMETER_IS_REQUEIRED'),
    mobile: Yup.string()
      .min(9, '_ERR_PHONE_LENGTH')
      .max(9, '_ERR_PHONE_LENGTH')
      .required('_PARAMETER_IS_REQUEIRED')
      .matches(/^[0-9]+$/, 'დასაშვებია მხოლოდ რიცხვები')
  }),
  Yup.object().shape({
    otp: Yup.string()
  }),
  Yup.object().shape({
    password: Yup.string().required('_PARAMETER_IS_REQUEIRED'),
    retryPassword: Yup.string().required('_PARAMETER_IS_REQUEIRED')
  })
];
