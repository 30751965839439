import React, { useContext, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router';
import GlobalContext from './GlobalContext';

const ProtectedRoutes = () => {
  const { setToken } = useContext(GlobalContext);
  const loginedUser = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!loginedUser) {
      navigate('/');
      setToken(null);
    }
  }, [loginedUser]);

  return loginedUser ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
