// hooks
import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { useSelector, useDispatch } from 'react-redux';

// components
import Button from '../../../../components/Button';
import apiRequest from '../../../../components/ApiRequest';
import GlobalContext from '../../../../components/GlobalContext';
import Modal from '../../../../components/modal/Modal';
import { customerSlice, customerSliceActions } from '../../../../store/slices/customer.slice';
import { testCustomerIds } from '../../../../utils/utils';

const CutDownSettings = ({ userInfo, setOpenSettings }) => {
  const dispatch = useDispatch();
  const automaticCutdown = useSelector((state) => state.customer.settings.cutdown.automatic);
  const queryClient = useQueryClient();
  const { setAlert, token, customerId } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [showRequestCutdownModal, setShowRequestCutdownModal] = useState(false);
  const [showWaitingCutdownModal, setShowWaitingCutdownModal] = useState(false);
  const [showRepeatCutdownModal, setShowRepeatCutdownModal] = useState(false);
  const [hasWaiting, setHasWaiting] = useState(false);

  useEffect(() => {
    // console.log(userInfo);
  }, []);

  const { data: awaitingForCutdown, isLoading: awaitingForCutdownIsLoading } = useQuery('awaitingForCutdown', () =>
    apiRequest(
      {
        command: 'check_awaiting_for_cut_down',
        sessionId: localStorage.getItem('token')
      },
      {
        cutDown: !automaticCutdown ? 1 : 0
      }
    ).then((response) => {
      setHasWaiting(parseInt(response.data?.result) > 0);
    })
  );

  const saveSettings = useMutation(
    (waiting) =>
      apiRequest(
        {
          command: 'save_customer_cut_down_setting',
          sessionId: token
        },
        {
          cutDown: !automaticCutdown ? 1 : 0,
          doAwaiting: waiting ? 1 : 0
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);

          setShowWaitingCutdownModal(false);
          setShowRequestCutdownModal(false);
          setShowRepeatCutdownModal(false);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      }
    }
  );

  // Request Popup
  const requestCutdownAgree = async () => {
    if (automaticCutdown) {
      setShowRepeatCutdownModal(true);
    } else {
      if (hasWaiting) {
        setShowRequestCutdownModal(false);
        setShowWaitingCutdownModal(true);
      } else {
        try {
          await saveSettings.mutateAsync(false).then(() => {
            dispatch(customerSliceActions.setAutomaticCutdownSetting(!automaticCutdown));
            // setShowRepeatCutdownModal(true);
          });
        } catch (error) {
          console.log('error', error);
        }
      }
    }
  };

  const requestCutdownCancel = async () => {
    if (automaticCutdown) {
      setShowRequestCutdownModal(false);
    } else {
      setShowRepeatCutdownModal(true);
    }
  };

  const waitingCutdownAgree = async () => {
    try {
      await saveSettings.mutateAsync(true).then(() => {
        dispatch(customerSliceActions.setAutomaticCutdownSetting(!automaticCutdown));
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const waitingCutdownCancel = async () => {
    try {
      await saveSettings.mutateAsync(false).then(() => {
        dispatch(customerSliceActions.setAutomaticCutdownSetting(!automaticCutdown));
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  // Confirmation
  const repeatConfirm = async () => {
    if (automaticCutdown) {
      if (hasWaiting) {
        setShowRequestCutdownModal(false);
        setShowRepeatCutdownModal(false);
        setShowWaitingCutdownModal(true);
      } else {
        try {
          await saveSettings.mutateAsync(false).then(() => {
            dispatch(customerSliceActions.setAutomaticCutdownSetting(!automaticCutdown));
            setShowRequestCutdownModal(false);
            setShowRepeatCutdownModal(false);
            setShowWaitingCutdownModal(false);
          });
        } catch (error) {
          console.log('error', error);
        }
      }
    } else {
      setShowRequestCutdownModal(false);
      setShowRepeatCutdownModal(false);
      setShowWaitingCutdownModal(false);
    }
  };

  const repeatCancel = async () => {
    setShowRepeatCutdownModal(false);
    if (automaticCutdown) {
      setShowRequestCutdownModal(false);
    }
  };

  return (
    <div className='d-flex flex-column height-100 justify-content-between sideBarChildComponent'>
      <h1 className='font-size-16 bold'>{t('_CUTDOWN_SETTINGS')}</h1>
      <form className='d-flex flex-column height-100 justify-content-between form'>
        <div className='sidebar-child-scroll'>
          <div className='pr-60'>
            <div>
              <div className={'d-flex py-3 justify-content-between align-items-center flex-row'}>
                <p className={'font-size-14'}>{t('_AUTOMATIC_CUTDOWN_SETTING')}</p>
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  width={50}
                  height={24}
                  text={t('_RECEIVING_OF_PACKAGE_IN_USA')}
                  handleDiameter={20}
                  onColor='#30bf54'
                  offColor='#e9e9eb'
                  onChange={() => setShowRequestCutdownModal(true)}
                  checked={automaticCutdown}
                  disabled={false}
                />
              </div>
            </div>

            <div
              className='bold'
              style={{
                border: '1px solid #f68424',
                backgroundColor: 'rgba(246, 132, 36, 0.1)',
                borderRadius: 8,
                padding: 20,
                fontSize: 14,
                color: '#f68424',
                fontWeight: 'bold'
              }}
              dangerouslySetInnerHTML={{ __html: t('_CUTDOWN_USA_WARNING') }}
            ></div>
          </div>
        </div>
      </form>

      <Modal showModal={showRequestCutdownModal} setShowModal={setShowRequestCutdownModal} modal={true}>
        <div className='d-flex flex-column'>
          <p
            className='modalText'
            style={{ maxHeight: 500, overflow: 'auto', paddingRight: 15 }}
            dangerouslySetInnerHTML={{ __html: automaticCutdown ? t('_POPUP_MESSAGE_FOR_TURNOFF_AUTOMATIC_CUTDOWN') : t('_POPUP_MESSAGE_FOR_TURNON_AUTOMATIC_CUTDOWN') }}
          ></p>
          <div className='d-flex align-items-center justify-content-center modal-btn-div mt-4'>
            <Button handleClick={requestCutdownAgree} type='button' btnStyle='bg-orange me-4 fill-balance-btn' text={t('_AGREE')} color='color-white' isLoading={false} />
            <Button handleClick={requestCutdownCancel} type='button' btnStyle='bg-orange fill-balance-btn secondary-btn-bg' text={t('_NOT_AGREE')} color='color-white' />
          </div>
        </div>
      </Modal>

      <Modal showModal={showRepeatCutdownModal} setShowModal={setShowRepeatCutdownModal} modal={true}>
        <div className='d-flex flex-column'>
          <p
            className='modalText'
            dangerouslySetInnerHTML={{ __html: automaticCutdown ? t('_POPUP_CONFIRMATION_FOR_TURNOFF_AUTOMATIC_CUTDOWN') : t('_POPUP_CONFIRMATION_FOR_TURNON_AUTOMATIC_CUTDOWN') }}
          ></p>
          <div className='d-flex align-items-center justify-content-center modal-btn-div mt-4'>
            <Button
              handleClick={() => {
                repeatConfirm();
              }}
              type='button'
              btnStyle='bg-orange me-4 fill-balance-btn'
              text={t('_CONFIRM')}
              color='color-white'
              isLoading={false}
            />
            <Button
              handleClick={() => {
                repeatCancel();
              }}
              type='button'
              btnStyle='bg-orange fill-balance-btn secondary-btn-bg'
              text={t('_DO_NOT_CONFIRM')}
              color='color-white'
            />
          </div>
        </div>
      </Modal>

      <Modal showModal={showWaitingCutdownModal} setShowModal={setShowWaitingCutdownModal} modal={true}>
        <div className='d-flex flex-column'>
          <p
            className='modalText'
            dangerouslySetInnerHTML={{ __html: automaticCutdown ? t('_POPUP_CONFIRMATION_AWAITING_TURNOFF') : t('_POPUP_CONFIRMATION_AWAITING_TURNON') }}
          ></p>
          <div className='d-flex align-items-center justify-content-center modal-btn-div mt-4'>
            <Button
              handleClick={() => {
                waitingCutdownAgree();
              }}
              type='button'
              btnStyle='bg-orange me-4 fill-balance-btn'
              text={t('_YES')}
              color='color-white'
              isLoading={false}
            />
            <Button
              handleClick={() => {
                waitingCutdownCancel();
              }}
              type='button'
              btnStyle='bg-orange fill-balance-btn secondary-btn-bg'
              text={t('_NO')}
              color='color-white'
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CutDownSettings;
