import { createSlice } from '@reduxjs/toolkit';
import { addTrackingAsyncThunk, getAwaitingAsyncThunk } from '../thunks/packages.thunk';

const initialState = {
  awaiting: {
    data: {},
    loading: false
  },
  customLoadings: {
    addTracking: false
  }
};

export const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    // setAutomaticCutdownSetting: (state, action) => {
    //   state.settings.cutdown.automatic = action.payload;
    // },
    // setAddressSetting: (state, action) => {
    //   state.settings.address.lat = parseFloat(action.payload.lat);
    //   state.settings.address.lng = parseFloat(action.payload.lng);
    //   state.settings.address.comment = action.payload.comment;
    // },
    // setNeedsUploadIdentification: (state, action) => {
    //   state.flags.needsUploadIdentification = action.payload;
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAwaitingAsyncThunk.pending, (state) => {
        state.awaiting.loading = true;
      })
      .addCase(getAwaitingAsyncThunk.fulfilled, (state, action) => {
        if (action.payload.status === 'OK') {
          state.awaiting.data = action.payload.result;
        }
        state.awaiting.loading = false;
      }).addCase(addTrackingAsyncThunk.pending, (state) => {
        state.customLoadings.addTracking = true;
      })
      .addCase(addTrackingAsyncThunk.fulfilled, (state, action) => {
        if (action.payload.status === 'OK') {
          state.awaiting.data = [action.payload.result, ...state.awaiting.data];
        }
        state.customLoadings.addTracking = false;
      });
  }
});

export const customerSliceActions = packagesSlice.actions;
export default packagesSlice.reducer;
