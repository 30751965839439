import React from 'react';

export const AudioMessage = ({ data }) => {
  console.log(data);
  return (
    // data.fileType
    <div style={{ display: 'flex', backgroundColor: '#eee', padding: 15, alignItems: 'center', gap: 10, borderRadius: 8, userSelect: 'none' }}>
      <div>
        <div style={{ fontSize: 11, color: '#999' }}>
          <audio style={{ width: '250px' }} src={data.url} controls='controls' />
        </div>
      </div>
    </div>
  );
};
