import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance, sendPostRequest } from '../../api/api';

export const getAwaitingAsyncThunk = createAsyncThunk('getAwaitingAsyncThunk', async () => {
  return await sendPostRequest(instance, {
    command: 'GET_AWAITING'
  });
});

export const addTrackingAsyncThunk = createAsyncThunk('addTrackingAsyncThunk', async ({ tracking, country }) => {
  return await sendPostRequest(instance, {
    command: 'ADD_TRACKING',
    tracking,
    country
  });
});
