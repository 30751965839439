import React, { useState, useRef, useEffect, useContext } from 'react';
import '../../scss/Declaration.scss';
import apiRequest from '../ApiRequest';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import NonChangablePackageDetail from '../NonChangablePackageDetail';
import { TrackingIcon, GlobeIcon, InboxIcon } from '../../assets/icons/index';
import Toggle from '../toggle/Toggle';
import ChangablePackageDetails from '../ChangablePackageDetails';
import Button from '../Button';
import SideBar from '../sidebar/SideBar';
import Shippers from '../Shippers';
import AddProduct from './AddProduct';
import AddNew from './AddNew';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../GlobalContext';
import useImage from '../hooks/useImage';
import Modal from '../modal/Modal';
import { getAwaitingAsyncThunk } from '../../store/thunks/packages.thunk';
import { useDispatch } from 'react-redux';
import { getAmountWithCurrency } from '../../utils/utils';
import { getCustomerInfoAsyncThunk } from '../../store/thunks/customer.thunk';
import { useNavigate } from 'react-router';
import { customerSliceActions } from '../../store/slices/customer.slice';

export default function BranchLockerRequestWeb({ setOpenSidebar }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, setAlert, setToken, setShowLoader } = useContext(GlobalContext);

  useEffect(() => {
    setShowLoader(true);
    const messageHandler = (event) => {
      try {
        const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

        if (data.type === 'EXPIRED') {
          localStorage.removeItem('token');
          queryClient.clear();
          setToken(null);
          navigate('/');

          dispatch(customerSliceActions.logout());
        } else if (data.type === 'SUCCESS') {
          setAlert(['success', data.data.message]);
        } else if (data.type === 'SUCCESS_AND_CLOSE') {
          setAlert(['success', data.data.message]);
          setOpenSidebar(false);
        } else if (data.type === 'ERROR') {
          setAlert(['error', data.data.message]);
        } else if (data.type === 'LOADING') {
          console.log(data);
          setShowLoader(data.data);
        }
      } catch (error) {}
    };

    window.addEventListener('message', messageHandler);
    return () => window.removeEventListener('message', messageHandler);
  }, []);

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries('userInfo');
      queryClient.invalidateQueries('getCustomerInfo');
      queryClient.invalidateQueries('getArrived');
    };
  }, []);

  const url = 'https://www.usa2georgia.com/locker_request/index.php?session_id=' + localStorage.getItem('token') + '&dev=1981&mode=web';

  return (
    <div className='Declaration sideBarChildComponent'>
      <h3 className='SectionTitle sidebarTitle' style={{ textTransform: 'uppercase' }}>
        {t('_LOCKER_REQUEST_BUTTON_TITLE')}
      </h3>
      <div
        style={{ height: '100%', width: '100%' }}
        dangerouslySetInnerHTML={{
          __html: '<iframe height="100%;" style="width: 100%;" scrolling="no" src="' + url + '" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'
        }}
      />

      {/* <iframe style={{ width: '100%', height: '100%', border: 'none' }} src={url} title='fx.' ref={iframeRef} onLoad={iframeLoaded} /> */}
    </div>
  );
}
