// hooks
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

// components
import apiRequest from '../../../components/ApiRequest';
import Flight from '../../../components/Trackings/Flight';
import Dashboard from '../Dashboard';
import GlobalContext from '../../../components/GlobalContext';

// assets
import {
  FlightProgressIcon
} from '../../../assets/icons/index';
import NoPackage from '../../../components/NoPackage';

const Pending = () => {
  const { setShowLoader } = useContext(GlobalContext);
  const {
    data: getPending,
    isLoading,
    isSuccess
  } = useQuery('getPending', () =>
    apiRequest({
      command: 'get_pending',
      sessionId: localStorage.getItem('token')
    })
  );

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  const pending = isSuccess && getPending.data.result?.packages;

  // console.log(pending);

  return (
    <Dashboard>
      {pending && pending.length > 0 ? (
        <div className="pt-4">
          {pending.map((item, index) => (
            <Flight
              packageStatus="sent"
              key={index}
              packageDetail={item?.trackings}
              item={item}
              icon={<FlightProgressIcon />}
            />
          ))}
        </div>
      ) : (
        !isLoading ? <NoPackage /> : null
      )}
    </Dashboard>
  );
};

export default Pending;
