// hooks
import React from 'react';
import { useTranslation } from 'react-i18next';

// components;
import PriorityStatus from '../PriorityStatus';
import TicketStatus from '../TicketStatus';

const TicketsList = ({ ticketsData, stepToSingleTicket }) => {
  const { t } = useTranslation();

  return (
    <div>
      {ticketsData &&
        ticketsData.map((item, index) => (
          <div
            key={index}
            onClick={() => stepToSingleTicket(item)}
            className="ticket-item cursor-pointer"
          >
            <div>
              <h4>{`#${item.id} • ${item.subject}`}</h4>
              <p>{`${t('_UPDATE')}: ${item.updated_at}`}</p>
              <div className="d-flex mt-2 align-items-center">
                <PriorityStatus status={item.priority} />
                <TicketStatus status={item.status} />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default TicketsList;
