import { useEffect, useState } from 'react';

const useProductIcon = (productCode) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [productCodeImage, setProductCodeImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      setProductCodeImage(null);
      try {
        setLoading(true);
        const response = await import(`../../assets/productsIcons/${productCode}.png`); // change relative path to suit your needs
        if (response) {
          setProductCodeImage(response.default);
          setError(null);
          setLoading(false);
        } else {
          setError('Image not found');
        }
      } catch (err) {
        setError(err);
        setProductCodeImage(null);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
    return fetchImage();
  }, [productCode]);

  return {
    loading,
    error,
    productCodeImage
  };
};

export default useProductIcon;
