import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import apiRequest from '../components/ApiRequest';
import Button from '../components/Button';
import { getCustomerInfoAsyncThunk } from '../store/thunks/customer.thunk';

function NotificationsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData, isSuccess } = useQuery('userInfo', () =>
    apiRequest({
      command: 'get_customer_info',
      sessionId: localStorage.getItem('token')
    })
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const notification = userData?.data?.result?.profile?.notifications;

  const { mutateAsync: confirmHandler } = useMutation((name) => {
    apiRequest(
      {
        command: 'confirm_notification',
        sessionId: localStorage.getItem('token')
      },
      {
        notificationId: notification?.id,
        button: name
      }
    ).then((res) => {
      if (res?.data?.status === 'OK') {
        dispatch(getCustomerInfoAsyncThunk());
        navigate('/');
      }
    });
  });

  if (!isSuccess) {
    return <></>;
  }

  return (
    <div className='pop-notifications-container shadow'>
      <div style={{ fontFamily: 'HelveticaBoldCaps', marginBottom: 20, textAlign: 'center' }}>
        {notification?.title}
      </div>
      <div dangerouslySetInnerHTML={{ __html: notification?.body }}></div>
      <div style={{ marginTop: 20, textAlign: 'center' }}>
        {notification?.buttons?.map((button) => {
          return (
            <div key={button?.name}>
              <Button
                handleClick={() => {
                  confirmHandler(button?.name);
                }}
                btnStyle='bg-orange logout-btn'
                text={button?.title}
                color='color-white'
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default NotificationsPage;
