/* eslint-disable no-useless-escape */
import { t } from 'i18next';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { GlobeIcon, InboxIcon, SearchIconNew } from '../../assets/icons';
import apiRequest from '../ApiRequest';
import Button from '../Button';
import ChangablePackageDetails from '../ChangablePackageDetails';
import GlobalContext from '../GlobalContext';
import Toggle from '../toggle/Toggle';
import Dropdown from '../DropDown';

export default function AddProduct({
  priceValue,
  quantityValue,
  onChange,
  onQuantityChange,
  onClick,
  buttonClick,
  dropdownDisabled,
  selectedCountryData,
  setSelectedCountryData,
  isAwaiting = false,
  noDropDown = false,
  showCurrencyOnly = false
}) {
  const { data: getPackageDescriptions } = useQuery('getPackageDescriptions', () =>
    apiRequest({
      command: 'get_package_descriptions',
      sessionId: localStorage.getItem('token')
    })
  );

  const { data: userData } = useQuery('userInfo', () =>
    apiRequest({
      command: 'get_customer_info',
      sessionId: localStorage.getItem('token')
    })
  );

  useEffect(() => {
    // console.log('AddProduct.js - get_customer_info');
  }, []);

  const getProducts = getPackageDescriptions?.data?.result;
  const isOrganization = userData?.data?.result?.profile?.is_organization;
  const [search, setSearch] = useState('');
  const [searchedData, setSearchedData] = useState(getProducts);
  const [index, setIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState();
  const [codeInput, setCodeInput] = useState(false);
  const [codeInputValue, setCodeInputValue] = useState('');
  const [errorInput, setErrorInput] = useState(false);
  const [errorPrice, setErrorPrice] = useState(false);
  const [errorQuantity, setErrorQuantity] = useState(false);
  const [codeErrorMessage, setCodeErrorMessage] = useState('');
  const { setAlert } = useContext(GlobalContext);
  const priceInput = useRef(null);
  const quantityInput = useRef(null);

  // const [selectedCurrency, setSelectedCurrency] = useState('US');

  useEffect(() => {
    if (quantityValue === undefined) {
      quantityValue = 1;
    }
    const timer = setTimeout(() => priceInput.current?.focus(), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (search.length > 0) {
      if (getProducts) {
        const filteredCategoriesKa = getProducts.filter((item) => {
          return item.desc_ge.toLowerCase().includes(search.toLowerCase());
        });
        const filteredCategoriesEn = getProducts.filter((item) => {
          return item.desc_en.toLowerCase().includes(search.toLowerCase());
        });
        setSearchedData([...filteredCategoriesKa, ...filteredCategoriesEn]);
      }
      //* * ჭედავს და ამიტომ შევამცირე სია */
    } else {
      setSearchedData(getProducts);
    }
  }, [search, getProducts]);

  useEffect(() => {
    const filteredWithCode = getProducts?.filter((item) => item.code === codeInputValue);
    if (codeInput) {
      if (filteredWithCode?.length > 0) {
        setSelectedCategory(filteredWithCode[0]);
      } else {
        setSelectedCategory();
      }
      setIndex();
    } else {
      setErrorInput(false);
      setCodeInputValue('');
      setSelectedCategory();
    }
  }, [codeInputValue, codeInput]);

  //* Check Product Code */
  const checkForProductCode = useMutation(
    (codeInputValue) => {
      return apiRequest(
        {
          command: 'check_for_product_code',
          sessionId: localStorage.getItem('token')
        },
        {
          code: codeInputValue
        }
      );
    },
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setCodeErrorMessage('');
          setErrorInput(false);
          setSelectedCategory(response.data.result);
        } else {
          setCodeErrorMessage(t(response.data.message));
          setErrorInput(true);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );

  useEffect(() => {
    codeInputValue?.length > 0 && checkForProductCode.mutate(codeInputValue);
  }, [codeInputValue]);

  function addProduct() {
    if (priceValue) {
      if (selectedCategory) {
        buttonClick();
        onClick(selectedCategory, parseFloat(priceValue), parseInt(quantityValue));
      } else {
        setAlert(['error', t('_key_category_required')]);
      }
      setErrorPrice(false);
    } else {
      setErrorPrice(true);
    }
  }

  const getOptions = () => {
    const countries = Object.entries(JSON?.parse(localStorage.getItem('countries')));
    const currencies = JSON?.parse(localStorage.getItem('currencies'));
    return countries?.map(([key, value], index) => {
      return { label: currencies[key], name: key, value: value, icon: `${key}.svg` };
    });
  };

  const showDropDown = () => {
    if (noDropDown) {
      return (
        <div style={{ display: 'flex' }}>
          <img src={require('../../assets/images/flags/' + selectedCountryData?.icon)} style={{ width: 30, marginRight: '7px' }} alt={selectedCountryData?.label} />
          {selectedCountryData?.label}
        </div>
      );
    }
    if (isAwaiting) {
      if (showCurrencyOnly) {
        return (
          <div style={{ display: 'flex' }}>
            <img src={require('../../assets/images/flags/' + selectedCountryData?.icon)} style={{ width: 30, marginRight: '7px' }} alt={selectedCountryData?.label} />
            {selectedCountryData?.label}
          </div>
        );
      } else {
        return (
          <Dropdown
            value={selectedCountryData}
            options={getOptions()}
            onChange={(data) => {
              setSelectedCountryData(data);
            }}
            disabled={dropdownDisabled}
          />
        );
      }
    } else {
      return (
        <div style={{ display: 'flex' }}>
          <img src={require('../../assets/images/flags/' + selectedCountryData?.icon)} style={{ width: 30, marginRight: '7px' }} alt={selectedCountryData?.label} />
          {selectedCountryData?.label}
        </div>
      );
    }
  };

  return (
    <div className='AddProductSidebar'>
      <h3 className='SectionTitle sidebarTitle'>{t('_key_add_product')}</h3>
      <div className='sidebar-child-scroll'>
        <div className={`${isOrganization ? 'd-flex justify-content-between' : ''}`}>
          <div>
            <div
              className={`ProductPriceInput d-flex align-items-center justify-content-between ${errorPrice ? 'errorBorder' : ''} ${
                isOrganization ? 'width-95percent' : 'width-100percent'
              }`}
            >
              <input
                ref={priceInput}
                type='number'
                pattern='[0-9]*'
                value={priceValue}
                onChange={(e) => {
                  onChange(e);
                  isNaN(e.target.value) || e.target.value.trim().length === 0 ? setErrorPrice(true) : setErrorPrice(false);
                  parseFloat(e.target.value) <= 0 && setErrorPrice(true);
                }}
                placeholder={t('_PRICE')}
              />
              <span className='currency'>{showDropDown()}</span>
            </div>
          </div>

          {isOrganization && (
            <div>
              <div
                className={`ProductPriceInput d-flex align-items-center justify-content-between ${errorQuantity ? 'errorBorder' : ''} 
          ${isOrganization ? 'width-95percent' : 'width-100percent'}`}
              >
                <input
                  ref={quantityInput}
                  type='number'
                  value={quantityValue}
                  onKeyDown={(event) => {
                    if (event.code === 'Period') {
                      event.preventDefault();
                      event.stopPropagation();
                      return false;
                    }
                  }}
                  onChange={(e) => {
                    onQuantityChange(e);
                    isNaN(e.target.value) || e.target.value.trim().length === 0 ? setErrorQuantity(true) : setErrorQuantity(false);
                    parseInt(e.target.value) <= 0 && setErrorQuantity(true);
                  }}
                  placeholder={t('_QUANTITY')}
                />
              </div>
            </div>
          )}
        </div>
        {(errorPrice || errorQuantity) && (
          <span className='color-red position-absolute font-size-12'>
            {errorPrice && isNaN(priceValue) ? t('_WRONG_PRICE_FORMAT') : priceValue?.trim().length === 0 ? t('_INVALID_PRICE') : ''}
            {!errorPrice ? (quantityValue !== undefined && isNaN(quantityValue) ? t('_WRONG_QUANTITY_FORMAT') : quantityValue.length === 0 ? t('_INVALID_QUANTITY') : '') : ''}
          </span>
        )}
        <div className='additionalOption d-flex align-items-center justify-content-between' style={{ marginTop: 20 }}>
          <h5 className='SectionOption'>{t('_key_add_with_code')}</h5>
          <Toggle onChange={() => setCodeInput(!codeInput)} />
        </div>
        {codeInput ? (
          <div className='codeInputWrapper'>
            <div className={`searchInput ${errorInput ? 'errorBorder' : ''}`}>
              {!codeInput && <SearchIconNew className='searchIcon' />}
              <input
                type='search'
                value={codeInputValue}
                placeholder={t('_TYPE_CODE')}
                onChange={(e) => {
                  setCodeInputValue(e.target.value);
                }}
              />
            </div>
            {errorInput && <span className='color-red position-absolute font-size-12'>{codeErrorMessage}</span>}
          </div>
        ) : (
          <div>
            <h4 className='SectionSubTitle'>{t('_key_choose_category')}</h4>
            <div className='searchInput'>
              <SearchIconNew className='searchIcon' />
              <input
                type='search'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            <div className='productCategories'>
              {getProducts &&
                (searchedData?.length > 0 ? (
                  searchedData.map((option) => {
                    return (
                      <div key={option.id} className='productCategory'>
                        <ChangablePackageDetails
                          icon={<InboxIcon />}
                          onClickPassed={() => {
                            setIndex(option.id);
                            setSelectedCategory(option);
                          }}
                          title={option[t('_key_desc')]}
                          productCode={option.code}
                          selectable={true}
                          selectedProduct={index === option.id ? 'selectedDiv' : ''}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className='productCategory'>
                    <p className='notFound'>{t('_CATEGORY_NOT_FOUND')}</p>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
      <div className='sidebarButtonDiv'>
        <Button
          isLoading={checkForProductCode?.isLoading}
          text={t('_COMPLETE')}
          handleClick={() => {
            if (!isOrganization && !errorPrice) {
              addProduct();
            } else if (isOrganization && !errorPrice && !errorQuantity) {
              addProduct();
            }
          }}
          btnStyle='button-primary declarationBtn d-flex justify-content-center align-items-center'
        />
      </div>
    </div>
  );
}
