// hooks
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// components
import Input from '../../../../../components/input/Input';

const CompanyInfo = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  validationError,
  executeScroll,
  activeStep
}) => {
  const { t } = useTranslation();
  const validationRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    if (activeStep === 0) {
      const timer = setTimeout(() => inputRef.current?.focus(), 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [activeStep]);

  useEffect(() => {
    if (validationError) {
      executeScroll(validationRef);
    }
  }, [validationError]);

  return (
    <div>
      <p
        ref={validationRef}
        className="font-size-16 mb-4 color-red bold text-center"
      >
        {validationError}
      </p>
      <p className="font-size-16 mb-4 bold text-center">
        {t('_COMPANY_INFORMATION')}
      </p>
      <Input
        ref={inputRef}
        label={t('_COMPANY_NAME_GEO')}
        value={values.organizationName}
        name="organizationName"
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          touched.organizationName && errors.organizationName
            ? t(errors.organizationName)
            : ''
        }
      />
      <Input
        lang="ka"
        label={t('_COMPANY_IDENTNO')}
        value={values.identification}
        name="identification"
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          touched.identification && errors.identification
            ? t(errors.identification)
            : ''
        }
      />
    </div>
  );
};

export default CompanyInfo;
