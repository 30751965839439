// hooks
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import i18n from 'i18next';

// assets
import { BarcodeIcon } from '../../assets/icons';

// components
import apiRequest from '../../components/ApiRequest';
import Button from '../../components/Button';
import Input from '../../components/input/Input';
import OrangeLine from '../../components/OrangeLine';
import Wrapper from '../../components/Wrapper';

const TrackPackage = () => {
  const { lang, term } = useParams();
  const { t } = useTranslation();
  const [tracking, setTracking] = useState(term?.length > 0 ? term : '');
  const [result, setResult] = useState('');

  useEffect(() => {
    if (['ka', 'en'].includes(lang)) {
      i18n.changeLanguage(lang);
    }
  }, []);

  useEffect(() => {
    if (term?.length > 0) {
      checkTracking.mutateAsync();
    }
  }, [term]);

  const checkTracking = useMutation(
    () =>
      apiRequest(
        {
          command: 'check_tracking'
        },
        {
          tracking
        }
      ),
    {
      onSuccess: (response) => {
        setResult(response.data);
      },
      onError: (response) => {
        console.log(response);
      }
    }
  );

  return (
    <Wrapper>
      <div className='find-package-container'>
        <div className='branches-title-text mb-5'>
          <div className='pt-5'>
            <OrangeLine />
          </div>
          <h1> {t('_TRACK_PACKAGE')}</h1>
        </div>
        <div className='d-flex justify-content-start find-div-container align-items-center w-full'>
          <div className='d-flex justify-content-between find-package-div align-items-center'>
            <Input
              icon={<BarcodeIcon />}
              placeholder={t('_TRACKING')}
              customClass='mb-0 package-input'
              onChange={(e) => setTracking(e.target.value)}
              value={tracking}
              onKeyUp={(e) => {
                if (e.keyCode === 13 && tracking.length > 0) {
                  checkTracking.mutateAsync();
                }
              }}
            />
            <Button
              disabled={tracking.length < 1}
              handleClick={checkTracking.mutateAsync}
              btnStyle='bg-orange package-button ml-5'
              text={t('_TRACK_PACKAGE')}
              color='color-white'
            />
          </div>
        </div>
        {result.status === 'OK' && result.status !== 'FAIL' ? (
          <div className='mt-5'>
            <p className='font-size-32 color-green font-weight-normal'>{t('_RECEIVED_PACKAGE')}</p>
            <p className='color-black font-size-20 font-weight-normal'>{result.result}</p>
            <p className='color-black font-size-20 font-weight-normal'>{result.country}</p>
          </div>
        ) : result.status === 'FAIL' ? (
          <div className='mt-5'>
            <p className='font-size-32 color-red font-weight-normal'>{t('_NOT_RECEIVED')}</p>
            <p className='color-black font-size-20 font-weight-normal'>{`${result.tracking}: ${result.message}`}</p>
          </div>
        ) : null}
      </div>
    </Wrapper>
  );
};

export default TrackPackage;
