import React from 'react';
import { PlusIcon } from '../../assets/icons';

export default function AddNew({
  onClick,
  text,
  icon,
  disabled,
  upload,
  accept,
  ...props
}) {
  return (
    <div
      className={`addNewProduct d-flex justify-content-between align-items-center ${
        disabled || ''
      }`}
      onClick={onClick}
    >
      {upload ? (
        <input
          type="file"
          className="uploadInput"
          accept={accept}
          multiple={false}
          {...props}
        />
      ) : (
        <></>
      )}
      <p>{text}</p>
      <div className="iconDiv">{icon || <PlusIcon />}</div>
    </div>
  );
}
