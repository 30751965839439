// hooks
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { DoneIcon } from '../../../../../assets/icons';

const TicketStatus = ({ status }) => {
  const { t } = useTranslation();
  const switchStatus = () => {
    switch (status) {
      case 2:
        return '_TICKET_OPEN';
      case 3:
        return '_TICKET_PENDING';
      case 4:
        return '_TICKET_RESOLVED';
      case 5:
        return '_TICKET_CLOSED';
    }
  };

  return (
    <div className="ticket-status">
      <DoneIcon />
      <p>{t(switchStatus())}</p>
    </div>
  );
};

export default TicketStatus;
