// hooks
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

// components
import Flight from '../../../components/Trackings/Flight';
import apiRequest from '../../../components/ApiRequest';
import Dashboard from '../Dashboard';
import GlobalContext from '../../../components/GlobalContext';
import NoPackage from '../../../components/NoPackage';
import { Pagination } from 'react-bootstrap';

// assets
import { Arrow } from '../../../assets/icons';

const PER_PAGE = 20;

const Received = () => {
  const [_data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setIsLoading(true);
    let from = null;
    if (currentPage === 1) {
      from = 0;
    } else {
      from = (currentPage - 1) * PER_PAGE;
    }

    fetch('https://www.usa2georgia.com/private_usa_ws/rest.php', {
      method: 'POST',
      body: JSON.stringify({
        command: 'get_received_partial',
        secret: 'EFDCF20F32-B233EA-45DF75-B01E-8098A066',
        sessionId: localStorage.getItem('token'),
        from: from,
        count: PER_PAGE
      })
    }).then(response => {
      response.json().then(res => {
        setIsLoading(false);
        setData(res?.result);
      });
    });
  }, [currentPage]);

  const { setShowLoader, getCustomerInfo } = useContext(GlobalContext);
  const [pages, setPages] = useState();


  const myRef = useRef(null);

  function onGoToNext() {
    if (currentPage === totalPages) {
      return;
    }
    setCurrentPage(currentPage + 1);
  }

  function onGoToPrev() {
    if (currentPage === 1) {
      return;
    }
    setCurrentPage(currentPage - 1);
  }

  const onGoToFirst = () => {
    setCurrentPage(1);
  };

  const onGoToLast = () => {
    if (currentPage === totalPages) {
      return;
    }
    setCurrentPage(totalPages);
  };

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    myRef?.current?.scrollIntoView();
  }, [currentPage]);

  // const received = getReceived?.data?.result || [];

  const totalPages = Math.ceil(getCustomerInfo?.counts?.received_partial_count / PER_PAGE);

  const rangeWithDots = (index, count) => {
    let range = [];
    let end = count;
    let start = 1;
    if (count > 5) {
      if (index >= 5) {
        range = [
          <Pagination.First onClick={onGoToFirst} key={'first'}>1</Pagination.First>,
          <Pagination.Ellipsis key={'...1'} />
        ];

        if (index <= count - 4) {
          start = index - 2;
          end = index + 2;
        } else {
          range = [
            <Pagination.First onClick={onGoToFirst} key={'first'}>1</Pagination.First>,
            <Pagination.Ellipsis key={'...2'} />
          ];
          start = count - 4;
        }
      } else {
        end = 5;
      }
    }
    for (let i = start; i <= end; i++) {
      range.push(
        <Pagination.Item
          key={i}
          active={currentPage === i}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Pagination.Item>
      );
      if (i === end && end < count) {
        range.push(<Pagination.Ellipsis key={'...3'} />);
        range.push(
          <Pagination.First onClick={onGoToLast} key={count}>{count}</Pagination.First>
        );
      }
    }
    return range;
  };

  useEffect(() => {
    if (totalPages && totalPages > 1) {
      const nextPages = rangeWithDots(currentPage, totalPages);
      setPages(nextPages);
    } else {
      const pages = [];
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <Pagination.Item
            key={i}
            active={currentPage === i}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
      setPages(pages);
    }
  }, [currentPage, totalPages]);

  // const items = received; // received.slice(
  //   (currentPage - 1) * PER_PAGE,
  //   currentPage * PER_PAGE
  // );

  return (
    <Dashboard>
      {!isLoading ? (
        _data.length === 0 ? (
          <NoPackage />
        ) : (
          <>
            <div ref={myRef} className="pt-4">
              {_data.map((item, index) => (
                <Flight
                  packageStatus="received"
                  key={index}
                  packageDetail={item?.trackings}
                  item={item}
                />
              ))}
            </div>
            <div className="pagination-container">
              <Pagination>
                <Pagination.First onClick={onGoToFirst}>
                  <div className="d-flex last-page-div justify-content-center">
                    <div className="arrow-1">
                      <Arrow style={{ transform: 'rotate(-180deg)' }} />
                    </div>
                    <div className="arrow-2">
                      <Arrow style={{ transform: 'rotate(-180deg)' }} />
                    </div>
                  </div>
                </Pagination.First>
                <Pagination.Prev onClick={onGoToPrev}>
                  <Arrow style={{ transform: 'rotate(-180deg)' }} />
                </Pagination.Prev>
                {pages}
                <Pagination.Next onClick={onGoToNext}>
                  <Arrow />
                </Pagination.Next>
                <Pagination.Last onClick={onGoToLast}>
                  <div className="d-flex last-page-div justify-content-center">
                    <div className="arrow-1">
                      <Arrow />
                    </div>
                    <div className="arrow-2">
                      <Arrow />
                    </div>
                  </div>
                </Pagination.Last>
              </Pagination>
            </div>
          </>
        )
      ) : null}
    </Dashboard>
  );
};

export default Received;
