import { useEffect, useState } from 'react';

const useImage = (fileName) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      setImage(null);
      try {
        setLoading(true);
        const response = await import(`../../assets/websites/${fileName}.png`); // change relative path to suit your needs
        if (response) {
          setImage(response.default);
          setError(null);
          setLoading(false);
        } else {
          setError('Image not found');
        }
      } catch (err) {
        setError(err);
        setImage(null);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
    return fetchImage();
  }, [fileName]);

  return {
    loading,
    error,
    image
  };
};

export default useImage;
