// hooks
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { CheckedIcon, MasterCardIcon, VisaIcon } from '../../../assets/icons';

// components
import Button from '../../../components/Button';

const IpaySuccess = ({ rememberCard, ipayResult }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (ipayResult?.is_saved) {
      localStorage.removeItem('order_id');
    }
  }, [ipayResult]);

  return (
    <div className="ipay-item position-relative">
      <h1 className="font-size-20 mt-3 color-orange text-center">
        {t('_IPAY_SUCCESS_PAYMENT')}
      </h1>
      <p className="font-size-14">{t('_PAYMENT_STATUS_CARD_TITLE')}</p>
      <div className="ipay-border">
        <div className="card-details-container">
          <div className="transaction-card-icon">
            {
              ipayResult?.card_type === 'MC' ? (
                <MasterCardIcon />
              ) : (
                <VisaIcon />
              )
            }
          </div>
          <p className="ms-3 font-size-12">{ipayResult?.pan}</p>
        </div>
      </div>
      {
        !ipayResult?.is_saved && <Button
          handleClick={rememberCard.mutate}
          btnStyle="save-card-button"
          color="font-size-14 color-orange text-center"
          text={t('_CARD_SAVE')}
        />
      }
      <div className="transaction-success">
        <div className="d-flex h-100 justify-content-center align-items-center">
          <CheckedIcon />
        </div>
      </div>
    </div>
  );
};

export default IpaySuccess;
