import React from 'react';

const AttachFile = ({ src, icon, AttachIcon, accept, label, ...props }) => {
  return (
    <label className="d-flex justify-content-between flex-grow-1 align-items-center cursor-pointer w-full">
      <div className="d-flex align-items-center">
        {src ? (
          <img className="me-4" src={src} alt={src} />
        ) : (
          <div className="why-usa2-icon flight-arrived d-flex align-items-center justify-content-center ms-2 me-4" />
        )}
        <p className="font-size-14">{label}</p>
      </div>
      <div>
        <input
          type="file"
          className="hidden"
          accept={accept}
          multiple={false}
          {...props}
        />
        {icon}
      </div>
    </label>
  );
};

export default AttachFile;
