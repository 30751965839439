// hooks
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

// libraries
import i18n from 'i18next';

// components
import Contact from '../../components/Contact';
import OrangeLine from '../../components/OrangeLine';
import Item from './Item';
import Search from '../../components/Search';
import apiRequest from '../../components/ApiRequest';
import GlobalContext from '../../components/GlobalContext';
import NotFound from '../../components/NotFound';
import Wrapper from '../../components/Wrapper';
import SideBar from '../../components/sidebar/SideBar';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SmallIcon from '../../components/SmallIcon';
import { ShareIcon } from '../../assets/icons';
import { useNavigate } from 'react-router';

const Faq = () => {
  const [faqData, setFaqData] = useState([]);
  const [search, setSearch] = useState('');
  const [pastedquestion, setPastedquestion] = useState(null);
  const [copyStatus, setCopyStatus] = useState(false);
  const [openPastedSidebar, setOpenPastedSidebar] = useState(null);
  const location = useLocation();
  const { setShowLoader } = useContext(GlobalContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLang = i18n.language || 'ka';
  const sidebarRef = useRef(null);
  const questionId =
    location.search.length > 0 && location.search.substring(10);

  const {
    data: getFaq,
    isLoading,
    isSuccess
  } = useQuery(['getFaq', currentLang], () =>
    apiRequest(
      {
        command: 'get_faq_all'
      },
      { language: localStorage.getItem('i18nextLng') } 
    )
  );

  useEffect(() => {
    if (openPastedSidebar === false) {
      navigate('/faq');
    }
  }, [openPastedSidebar]);

  useEffect(() => {
    if (getFaq) {
      setFaqData(getFaq.data.result);
    }
  }, [getFaq]);

  useEffect(() => {
    // console.log('FAQ', localStorage.getItem('selectedLanguage'));
  }, []);

  useEffect(() => {
    if (questionId && faqData) {
      const res = faqData.map(
        (item) => item.items.filter((e) => e.id === questionId)
      ).sort((a, b) => b.length - a.length)[0]?.[0];
      setPastedquestion(res);
    }
  }, [questionId, faqData]);

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (pastedquestion) {
      const timer = setTimeout(() => sidebarRef.current.open(), 0);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [pastedquestion]);

  useEffect(() => {
    if (search.length > 0) {
      const filtered = structuredClone(getFaq.data.result)
        .map((item) => {
          item.items = item.items.filter((i) => {
            return (
              i.answer.toLowerCase().includes(search.toLowerCase()) ||
              i.question.toLowerCase().includes(search.toLowerCase())
            );
          });
          return item;
        })
        .filter((item) => item.items.length > 0);
      setFaqData(filtered);
    } else {
      setFaqData(getFaq?.data?.result || []);
    }
  }, [search]);

  return (
    <Wrapper>
      <div className="faq-container">
        <div className="pt-5">
          <OrangeLine />
        </div>
        <h1 className="mt-1">{t('_FAQ_CHATBOT')}</h1>
        <p className="my-4 color-third-grey">
          {t('_CONTACT_US_FOR_MORE_INFORMATION')}
        </p>
      </div>
      <div className="mb-5 pb-3">
        <Contact />
      </div>
      <div className="faq-container">
        <div>
          <Search
            placeholder={t('_FIND_QUESTION')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {isSuccess && (
            <div className="mt-5 pt-3">
              {faqData.length > 0 ? (
                faqData.map((item, index) => (
                  <Item key={index} item={item} opened={!!search} />
                ))
              ) : (
                <NotFound />
              )}
            </div>
          )}
        </div>
      </div>
      <SideBar
        ref={sidebarRef}
        openSidebar={openPastedSidebar}
        setOpenSidebar={setOpenPastedSidebar}
      >
        <h1 className="font-size-16 color-black bold mb-5">
          {pastedquestion?.question}
        </h1>
        <div className="sidebar-child-scroll-faq faq-container">
          <div className="pr-60 cursor-pointer">
            <div
              className="color-third-grey font-size-14"
              dangerouslySetInnerHTML={{ __html: pastedquestion?.answer }}
            />
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={
                copyStatus ? (
                  <Tooltip id="tooltip-top">{t('_key_copied')}</Tooltip>
                ) : (
                  <div />
                )
              }
            >
              <div
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  setCopyStatus(true);
                }}
                className="share-answer-container"
              >
                <p className="font-size-14">{t('_SHARE')}</p>
                <span className="cursor-pointer position-relative">
                  <SmallIcon
                    bg="icon-light-bg share-icon cursor-pointer"
                    img={<ShareIcon />}
                  />
                </span>
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </SideBar>
    </Wrapper>
  );
};

export default Faq;
