// hooks
import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DocIcon, DownloadIcon } from '../../assets/icons';
import SmallIcon from '../../components/SmallIcon';

export function InvoiceLink({ item }) {
  const { t } = useTranslation();
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(`/invoice?number=${item.invoice}`);
    if (item.invoice.startsWith('CI')) {
      setUrl(`/courier_invoice?transaction=${item.transaction_id}`);
    }
  }, []);

  return (
    <Link
      target="_blank"
      to={url}
      className="d-flex mt-3 transaction-invoice justify-content-between align-items-center"
    >
      <div className="d-flex align-items-center">
        <span className="me-3">
          <SmallIcon
            bg="icon-light-bg"
            alt="doc"
            img={<DocIcon />}
          />
        </span>
        <span>{t('_INVOICE')}</span>
      </div>
      <div className="me-2 invoice-icon">
        <DownloadIcon />
      </div>
    </Link>
  );
}
