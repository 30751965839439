import React from 'react';
import { Link } from 'react-router-dom';
import SmallIcon from './SmallIcon';
import { AppStoreIcon, BlogIcon, FacebookIcon, InstagramIcon, PlayStoreIcon } from '../assets/icons';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const TermsAndSocial = () => {
  const { t } = useTranslation();
  const currentLang = i18n.language;

  const socialIcons = [
    {
      icon: <InstagramIcon />,
      href: 'https://www.instagram.com/usa2georgia.com_/'
    },
    {
      icon: <FacebookIcon />,
      href: 'https://www.facebook.com/USA2GEORGIACOM/'
    },
    {
      icon: <BlogIcon />,
      href: `https://news.usa2georgia.com/${currentLang === 'ka' ? '' : currentLang}`
    }
  ];

  return (
    <div className='footer-div responsive-hidden d-flex flex-column justify-content-between'>
      <div className='d-flex appstore-icons mb-4 justify-content-between'>
        <a href='https://apps.apple.com/us/app/usa2georgia/id1461461537' target='_blank' rel='noreferrer'>
          <AppStoreIcon />
        </a>
        <a href='https://play.google.com/store/apps/details?id=com.usa2georgia.usa2georgia' target='_blank' rel='noreferrer'>
          <PlayStoreIcon />
        </a>
      </div>
      <div className='d-flex justify-content-between flex-column w-100'>
        <div>
          <Link target='_blank' to={'/agreement/' + i18n.language}>
            <p className='login-footer-terms color-gray cursor-pointer font-size-14'>{t('_TERMS_AGGREMENT')}</p>
          </Link>
          <Link target='_blank' to='/user-policy'>
            <p className='login-footer-terms color-gray cursor-pointer font-size-14'>{t('_USER_POLICY')}</p>
          </Link>
          <p className='login-copyright'>{t('_COPYRIGHT')}</p>
        </div>
        <div className='mt-2 d-flex justify-content-center'>
          {socialIcons.map((item, index) => (
            <a className='mx-1' key={index} href={item.href} target='_blank' rel='noreferrer'>
              <SmallIcon imgStyle='social-img-sizes' img={item.icon} bg='icon-dark-bg' />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TermsAndSocial;
