// hooks
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';

// components
import Wrapper from '../../components/Wrapper';
import apiRequest from '../../components/ApiRequest';
import GlobalContext from '../../components/GlobalContext';
import IpaySuccess from './components/IpaySuccess';
import IpayError from './components/IpayError';

const IpayStatus = () => {
  const { setAlert, token, setShowLoader } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const rememberCard = useMutation(
    () =>
      apiRequest(
        {
          command: 'save_card',
          sessionId: token
        },
        {
          orderId: localStorage.getItem('order_id')
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          navigate('/');
          localStorage.removeItem('order_id');
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      }
    }
  );

  const { data: getIpayTransaction, isSuccess, isLoading } = useQuery(
    'getIpayTransaction',
    () =>
      apiRequest({
        command: 'get_ipay_transaction',
        sessionId: localStorage.getItem('token')
      }, {
        orderId: localStorage.getItem('order_id')
      }));

  const ipayResult = isSuccess && getIpayTransaction?.data?.result;

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  return (
    <Wrapper>
      <div className="ipay-container">
        {
          isSuccess && ipayResult
            ? <IpaySuccess ipayResult={ipayResult} rememberCard={rememberCard} />
            : isSuccess && !ipayResult ? <IpayError /> : null
        }
      </div>
    </Wrapper >
  );
};

export default IpayStatus;
