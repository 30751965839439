import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance, sendPostRequest } from '../../api/api';

export const getCustomerInfoAsyncThunk = createAsyncThunk(
  'getCustomerInfoAsyncThunk',
  async () => {
    return await sendPostRequest(instance, {
      command: 'GET_CUSTOMER_INFO'
    });
  }
);


