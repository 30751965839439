import React, { useEffect, useState } from 'react';
import { Eye, EyeHidden } from '../../assets/icons';
import './CustomInput.scss';
import classNames from 'classnames';

// eslint-disable-next-line react/display-name
const CustomInput = React.forwardRef(
  (
    {
      customStyle,
      customClass,
      icon,
      rightIcon,
      rightIconType,
      placeholder,
      type,
      onFocus,
      label,
      bg,
      error,
      value,
      disabled,
      validateKa,
      validateEn,
      containerStyle,
      containerClass,
      labelClass,
      ...props
    },
    ref
  ) => {
    const [isActive, setIsActive] = useState(false);
    const [inputType, setInputType] = useState(type);
    const [visibilityIcon, setVisibilityIcon] = useState(false);

    useEffect(() => {
      value ? setIsActive(true) : setIsActive(false);
    }, [value]);

    return (
      <div
        style={containerStyle}
        className={classNames(
          'profile-input-container',
          'position-relative',
          customClass,
          containerClass,
          { 'd-flex justify-content-center align-items-center': icon },
          { 'ci-float-label': !icon },
          { '': error } // profile-input-error
        )}
      >
        <>
          <label className={labelClass} dangerouslySetInnerHTML={{ __html: label }}></label>
          <input
            onFocus={onFocus}
            ref={ref}
            placeholder={placeholder}
            value={value || ''}
            disabled={disabled}
            className={`${customStyle}`}
            type={inputType}
            onChange={(e) =>
              icon && e.target.value.length > 0
                ? setIsActive(true)
                : setIsActive(false)
            }
            {...props}
          />
        </>

      </div>
    );
  }
);

export default CustomInput;
