import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../modal/Modal';
import { useTranslation } from 'react-i18next';
import { LoaderIcon, PlusIcon } from '../../assets/icons';
import Button from '../Button';
import UploadDocument from './UploadDocument';
import apiRequest from '../ApiRequest';
import GlobalContext from '../GlobalContext';
import { customerSliceActions } from '../../store/slices/customer.slice';
import Input from '../input/Input';

function DocumentRequest() {
  const { t } = useTranslation();
  const { token, setAlert } = useContext(GlobalContext);
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fiscal, setFiscal] = useState('');
  const nonGeorgianCitizen = useSelector(state => state.customer.flags.nonGeorgianCitizen);
  const [uploadData, setUploadData] = useState({
    front: false,
    back: false,
    fiscal: false
  });

  //   useEffect(() => {
  //     console.log(uploadData);
  //   }, [uploadData]);

  return (
    <Modal
      showModal={openModal}
      setShowModal={setOpenModal}
      modal={true}
    >
      <div style={{ position: 'relative' }}>
        {
          loading && <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'black', opacity: 0.6, borderRadius: 12 }}>
            <div className="loader">
              <LoaderIcon />
            </div>
          </div>
        }
        <p dangerouslySetInnerHTML={{ __html: nonGeorgianCitizen ? t('_UPLOAD_DOCUMENT_COMMENT_NON_RESIDENT') : t('_UPLOAD_DOCUMENT_COMMENT') }}></p>
        <p style={{ marginTop: 20 }}>{t('_SELECT_FILE_1')}</p>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 30 }}>
          <UploadDocument num='front' setLoading={setLoading} setUploadData={setUploadData} uploadData={uploadData} />
          {!nonGeorgianCitizen && <UploadDocument num='back' setLoading={setLoading} setUploadData={setUploadData} uploadData={uploadData} />}
        </div>
        {nonGeorgianCitizen &&
          <div style={{ marginTop: 20 }}>
            <Input
              type="number"
              label={t('_FISCAL_CODE')}
              name="currentPassword"
              value={fiscal}
              onChange={(e) => {
                setUploadData({ ...uploadData, fiscal: e.target.value.trim().length > 0 });
                setFiscal(e.target.value);
              }}
              onBlur={() => {

              }}
            />
          </div>
        }
        <div style={{ marginTop: 35 }}>
          <Button
            handleClick={() => {
              if (!uploadData.front && !uploadData.back) {
                setAlert(['error', t('_SELECT_FILE_1')]);
                return;
              }

              if (nonGeorgianCitizen) {
                if (fiscal.length < 9) {
                  setAlert(['error', t('_WRONG_FISCAL_CODE')]);
                  return;
                }
              }

              setLoading(true);
              apiRequest(
                {
                  command: 'complete_customer_document_upload',
                  sessionId: token,
                  method: 'POST'
                },
                {
                  fiscalCode: nonGeorgianCitizen ? fiscal : ''
                }
              ).then(({ data }) => {
                setLoading(false);
                if (data.status === 'OK') {
                  dispatch(customerSliceActions.setNeedsUploadIdentification(false));
                  setAlert(['success', t(data.message)]);
                } else {
                  setAlert(['error', t(data.message)]);
                }
              });
            }}

            type="button"
            btnStyle="bg-orange fill-balance-btn"
            text={t('_COMPLETE')}
            color="color-white"
          />
        </div>
      </div>
    </Modal>
  );
}

export default DocumentRequest;
