import React from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadIcon } from '../assets/icons';
import Button from './Button';

export default function NonChangablePackageDetail({
  icon,
  info,
  title,
  invoice,
  customFunction,
  link
}) {
  const { t } = useTranslation();
  
  return (
    <div
      className={'nonChangableInfo d-flex'}
    >
      <div className="sidebarPackageIcon rounded-circle d-flex align-items-center justify-content-center">
        {icon}
      </div>
      <div className="infoDiv">
        <h6 className="infoTitle">{title}</h6>
        <span className="info">{info}</span>
      </div>
      {invoice &&
        (invoice.paid === '1' ? (
          link ? (
            <a target="_blank" href={link} className='sidebarPackageIcon rounded-circle d-flex align-items-center justify-content-center invoiceDownload ' rel="noreferrer">
                <DownloadIcon />
            </a>
          ) : (
            <div className="sidebarPackageIcon rounded-circle d-flex align-items-center justify-content-center invoiceDownload">
              <DownloadIcon />
            </div>
          )
        ) : invoice.paid === '0' ? (
          <div className='payBtn d-flex justify-content-between align-items-center'>
            <Button btnStyle='invoicePayment' text={t('_INVOICE_PAYMENT') } handleClick={customFunction}/>
            <a target="_blank" href={link} style={{ marginLeft: 10 }} className='sidebarPackageIcon rounded-circle d-flex align-items-center justify-content-center invoiceDownload invoice-link' rel="noreferrer">
                <DownloadIcon />
            </a>
          </div>
        ) : null)}
    </div>
  );
}
