import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import appSlice from './slices/app.slice';
import chatbotSlice from './slices/chatbot.slice';
import customerSlice from './slices/customer.slice';
import packagesSlice from './slices/packages.slice';

const rootReducer = combineReducers({
  app: appSlice,
  customer: customerSlice,
  packages: packagesSlice,
  chatbot: chatbotSlice
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer
  });
};
