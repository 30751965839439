import React from 'react';
import { FileErrorIcon, PDFIcon, PhotoIcon } from '../../assets/icons';
import { formatBytes } from '../../utils/utils';

export const FileMessage = ({ data }) => {
  console.log(data);
  return (
    // data.fileType
    <div style={{ display: 'flex', backgroundColor: '#eee', padding: 15, alignItems: 'center', gap: 10, borderRadius: 8, userSelect: 'none' }}>
      <div style={{ backgroundColor: '#f68220', padding: 15, borderRadius: '100%', width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {data.fileType.includes('image') ? (
          <PhotoIcon fill='white' width={32} />
        ) : data.fileType.includes('application/pdf') ? (
          <PDFIcon fill='white' width={40} />
        ) : (
          <FileErrorIcon fill='white' width={40} />
        )}
      </div>
      <div>
        <div style={{ fontSize: 13, maxWidth: 180, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{data.fileName}</div>
        <div style={{ fontSize: 11, color: '#999' }}>{formatBytes(data.fileSize)}</div>
      </div>
    </div>
  );
};
