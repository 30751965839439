// hooks
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

// assets
import {
  ArrivedPackageIcon,
  PackagesIcon,
  ReceivedPackagesIcon,
  SentPackagesIcon,
  WareHouseIcon
} from '../../assets/icons';

// components
import apiRequest from '../../components/ApiRequest';
import Wrapper from '../../components/Wrapper';
import agreementFunction from '../../components/agreementFunction';

const Dashboard = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log('Dashboard.js - get_customer_info');
  }, []);

  const { data: userInfo, isSuccess } = useQuery('userInfo', () =>
    apiRequest({
      command: 'get_customer_info',
      sessionId: localStorage.getItem('token')
    }), {
    onSuccess: (response) => {
      localStorage.setItem('country', response?.data?.result?.profile?.country || {});
      localStorage.setItem('countries', JSON.stringify(response?.data?.result?.profile?.countries || {}));
      localStorage.setItem('currencies', JSON.stringify(response?.data?.result?.profile?.currencies || {}));
      localStorage.setItem('declaration_threshold', JSON.stringify(response?.data?.result?.profile?.declaration_threshold || {}));
      agreementFunction(response, navigate);
    }
  }
  );

  const dashboardInfo = isSuccess && userInfo.data.result?.counts;
  const categoriesList = [
    {
      title: '',
      translate: '_AWAITING',
      icon: <PackagesIcon />,
      itemName: dashboardInfo?.awaiting,
      path: '/'
    },
    {
      title: 'warehouse',
      translate: '_WAREHOUSE',
      icon: <WareHouseIcon />,
      itemName: dashboardInfo?.usa_warehouse,
      path: '/warehouse'
    },
    {
      title: 'pending',
      translate: '_PENDING',
      icon: <SentPackagesIcon />,
      itemName: dashboardInfo?.pending,
      path: '/pending'
    },
    {
      title: 'arrived',
      translate: '_ARRIVED',
      icon: <ArrivedPackageIcon />,
      itemName: dashboardInfo?.arrived,
      path: '/arrived'
    },
    {
      title: 'received',
      translate: '_RECEIVED',
      icon: <ReceivedPackagesIcon />,
      itemName: dashboardInfo?.received,
      path: '/received'
    }
  ];
  const activeTab = location.pathname.substring(1);

  return (
    <Wrapper>
      <div>
        <div
          className={`d-flex w-full mt-5 responsive-hidden justify-content-between flex-row dashboardRow ${dashboardInfo?.awaiting > 999 ||
            dashboardInfo?.usa_warehouse > 999 ||
            dashboardInfo?.pending > 999 ||
            dashboardInfo?.arrived > 999 ||
            dashboardInfo?.received > 999
            ? 'largeNumber'
            : ''
            }`}
        >
          {categoriesList.map((item, index) => (
            <div
              key={index}
              className={`bg-white d-flex flex-grow-1 border-rad-12 dashboardCategories ${index !== 4 ? 'me-2' : ''
                }`}
            >
              <Link
                className={`d-flex position-relative ${activeTab === item.title
                  ? 'dashboard-categories-item categories-active'
                  : 'dashboard-categories-item'
                  } border-rad-12 pb-4 align-items-center flex-column`}
                to={item.path}
              >
                <div>
                  <div className='categories-numbers-absolute'>
                    <div>
                      <p>{item.itemName > 999 ? '999+' : item.itemName}</p>
                    </div>
                  </div>
                  <div className='mt-25'>
                    <div className='d-flex justify-content-center tab-icon'>
                      {item.icon}
                    </div>
                    <p className='mt-2 text-center font-size-dashboard color-gray'>
                      {t(item.translate)}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className='tab-container pb-4'>{children}</div>
      </div>
    </Wrapper>
  );
};

export default Dashboard;
