import React from 'react';
import './Checkbox.scss';
import { CheckedIcon } from '../../assets/icons';

const Checkbox = ({ checked, setChecked, ...props }) => {
  const toggleCheck = () => {
    setChecked(checked === 0 ? 1 : 0);
  };

  return (
    <span className="position-relative" onClick={toggleCheck}>
      <input
        className="checkbox"
        checked={checked}
        type="checkbox"
        {...props}
      />
      <span />
      <div className={`checkbox-checked ${checked === 1 && true}`}>
        <CheckedIcon />
      </div>
    </span>
  );
};

export default Checkbox;
