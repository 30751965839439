// hooks
import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import axios from 'axios';
import i18n from 'i18next';

// config
import IndividualRegistrationSchema from '../config/validations/IndividualRegistrationSchema';

// components
import 'swiper/swiper-bundle.min.css';
import PersonalInformation from './slider-steps/PersonalInformation';
import EmailAndPhone from './slider-steps/EmailAndPhone';
import CheckOtp from './slider-steps/CheckOtp';
import CreatePassword from './slider-steps/CreatePassword';
import apiRequest from '../../../../components/ApiRequest';
import Button from '../../../../components/Button';
import GlobalContext from '../../../../components/GlobalContext';

SwiperCore.use([Pagination]);

export default function IndividualCustomer({
  setActiveForm,
  userType,
  closeForm,
  setOpenSidebar,
  sliderStep,
  setSliderStep,
  setActiveStep
}) {
  const { t } = useTranslation();
  const [OTP, setOTP] = useState('');
  const [message, setMessage] = useState('');
  const [swiper, setSwiper] = useState(null);
  const [acceptAgreement, setAcceptAgreement] = useState(0);
  const [validationError, setValidationError] = useState(null);
  const { setAlert } = useContext(GlobalContext);
  const [captchaValue, setCaptchaValue] = useState(null);
  const currentValidationSchema = IndividualRegistrationSchema[sliderStep];
  const currentLang = i18n.languages[0];

  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    validateForm,
    setTouched,
    setFieldValue,
    setErrors
  } = useFormik({
    initialValues: {
      firstName: '',
      firstNameGe: '',
      lastName: '',
      lastNameGe: '',
      identification: '',
      email: '',
      mobile: '',
      customerType: '0',
      organizationName: '',
      password: '',
      retryPassword: '',
      nonGeorgianCitizen: userType === 'foreigner' ? 1 : 0,
      language: currentLang,
      defaultBranch: '',
      captcha: '',
      ipAddress: ''
    },
    validationSchema: currentValidationSchema
  });

  useEffect(() => {
    const getIpAddress = async() => {
      const res = await axios.get('https://geolocation-db.com/json/');
      setFieldValue('ipAddress', res.data.IPv4);
    };
    getIpAddress();
    console.log(sliderStep);
  }, []);

  useEffect(() => {
    if (captchaValue && captchaValue.length > 0) {
      setFieldValue('captcha', captchaValue);
    }
  }, [captchaValue]);

  const executeScroll = (ref) => ref.current.scrollIntoView();
  const increaseStep = () => setSliderStep(sliderStep + 1);
  const decreaseStep = () =>
    sliderStep > 0 ? setSliderStep(sliderStep - 1) : setSliderStep(0);

  const sendOtp = useMutation(() =>
    apiRequest(
      {
        command: 'send_otp'
      },
      { language: values.language, mobile: values.mobile }
    )
  );

  const checkOtp = useMutation(
    () =>
      apiRequest(
        {
          command: 'check_otp'
        },
        { otp: OTP, mobile: values.mobile }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          increaseStep();
        } else if (response.data.message === '_INVALID_OTP') {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        console.log(response);
      }
    }
  );

  const validateRegistration = useMutation(
    ({ customerInfo, step }) =>
      apiRequest(
        {
          command: 'validate_registration_info'
        },
        { customerInfo, step: step }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          if (sliderStep === 1) {
            sendOtp.mutate();
            increaseStep();
            setValidationError(null);
          }
          if (sliderStep === 3) {
            registerCustomer.mutate();
          } else {
            increaseStep();
          }
        } else {
          setAlert([
            'error',
            t(
              response.data.message.identification ||
              response.data.message.mobile ||
              response.data.message.defaultBranch ||
              response.data.message.email ||
              response.data.message
            )
          ]);
        }
      }
    }
  );

  useEffect(() => {
    if (swiper) swiper.slideTo(sliderStep);
  }, [sliderStep]);

  useEffect(() => {
    if (OTP.length > 0) {
      setMessage('');
    }
  }, [OTP]);

  const backToRegistrationPage = () => {
    setActiveForm('registration');
    closeForm();
  };

  const step1 = () => {
    const paramsKa = {
      firstNameGe: true,
      firstName: true,
      lastName: true,
      lastNameGe: true,
      identification: true
    };
    const currentStepValuesKa = {
      firstName: values.firstName,
      firstNameGe: values.firstNameGe,
      lastName: values.lastName,
      lastNameGe: values.lastNameGe,
      identification: values.identification,
      nonGeorgianCitizen: values.nonGeorgianCitizen
    };
    if (Object.keys(errors).length > 0 || !paramsKa) {
      setTouched(paramsKa);
    } else {
      validateRegistration.mutate({
        customerInfo: currentStepValuesKa,
        step: '1'
      });
    }
  };

  const step2 = () => {
    const params = {
      mobile: true,
      email: true,
      defaultBranch: true
    };
    const currentStepValues = {
      mobile: values.mobile,
      email: values.email,
      defaultBranch: values.defaultBranch
    };
    if (Object.keys(errors).length > 0 || !params) {
      setTouched(params);
    } else {
      validateRegistration.mutate({
        customerInfo: currentStepValues,
        step: '2'
      });
    }
  };

  const step4 = () => {
    const params = {
      password: true,
      retryPassword: true
    };
    const currentStepValues = {
      password: values.password,
      retryPassword: values.retryPassword
    };
    if (values.password !== values.retryPassword) {
      setAlert(['error', t('_ERR_PASSCODE')]);
    } else if (Object.keys(errors).length > 0 || !params) {
      setTouched(params);
    } else if (acceptAgreement !== 1) {
      setAlert(['error', t('_ACCEPT_TERMS')]);
    } else {
      validateRegistration.mutate({
        customerInfo: currentStepValues,
        step: '4'
      });
    }
  };

  const handleNextStep = () => {
    switch (sliderStep) {
      case 0:
        return step1();
      case 1:
        return step2();
      case 2:
        return checkOtp.mutate();
      case 3:
        return step4();
      default:
        return null;
    }
  };

  const registerCustomer = useMutation(
    () =>
      apiRequest(
        {
          command: 'register'
        },
        { customerInfo: values }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          setOpenSidebar(false);
          setActiveForm('registration');
          setActiveStep(0);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );

  return (
    <div className="height-100">
      <form className="d-flex flex-column height-100 justify-content-between">
        <div className="sidebar-child-scroll">
          <div className="pr-60 registration-child">
            <Swiper
              onSwiper={setSwiper}
              allowTouchMove={false}
              slidesPerView={1}
              spaceBetween={20}
            >
              <SwiperSlide>
                {sliderStep === 0 && (
                  <PersonalInformation
                    setTouched={setTouched}
                    setErrors={setErrors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    validateForm={validateForm}
                    closeForm={closeForm}
                    userType={userType}
                    validationError={validationError}
                    executeScroll={executeScroll}
                    activeStep={sliderStep}
                  />
                )}
              </SwiperSlide>
              <SwiperSlide>
                {sliderStep === 1 && (
                  <EmailAndPhone
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    activeStep={sliderStep}
                    validationError={validationError}
                    executeScroll={executeScroll}
                    setFieldValue={setFieldValue}
                  />
                )}
              </SwiperSlide>
              <SwiperSlide>
                {sliderStep === 2 && (
                  <CheckOtp
                    OTP={OTP}
                    setOTP={setOTP}
                    message={message}
                    setMessage={setMessage}
                    mobile={values.mobile}
                    sendOtp={sendOtp}
                  />
                )}
              </SwiperSlide>
              <SwiperSlide>
                {sliderStep === 3 && (
                  <CreatePassword
                    acceptAgreement={acceptAgreement}
                    setAcceptAgreement={setAcceptAgreement}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    activeStep={sliderStep}
                    setCaptchaValue={setCaptchaValue}
                  />
                )}
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="sidebarButtonDiv">
          <Button
            isLoading={
              validateRegistration.isLoading ||
              checkOtp.isLoading ||
              registerCustomer.isLoading
            }
            handleClick={handleNextStep}
            type="button"
            btnStyle="primary-btn-w-h w-100 bg-orange"
            text={sliderStep === 3 ? t('_COMPLETE') : t('_REGISTRATION_NEXT')}
            color="color-white"
            disabled={sliderStep === 3 && !captchaValue}
          />
          <Button
            handleClick={() =>
              sliderStep === 0 ? backToRegistrationPage() : decreaseStep()
            }
            btnStyle="primary-btn-w-h mt-3 btn-gray-bg"
            text={t('_RETURN_BACK')}
            color="color-orange"
          />
        </div>
      </form>
    </div>
  );
}
