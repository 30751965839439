// hooks
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

// components
import apiRequest from '../../ApiRequest';

// assets
import {
  FlightArrivedIcon,
  PackagesIcon,
  ReceivedPackagesIcon,
  SentPackagesIcon,
  WareHouseIcon
} from '../../../assets/icons';

const ResponsiveCategories = () => {
  const activeTab = location.pathname.substring(1);
  const { data: userInfo, isSuccess } = useQuery('userInfo', () =>
    apiRequest({
      command: 'get_customer_info',
      sessionId: localStorage.getItem('token')
    })
  );
  
  useEffect(() => {
    // console.log('ResponsiveCategories.js - get_customer_info');
  }, []);

  const dashboardInfo = isSuccess && userInfo.data.result?.counts;
  const categoriesList = [
    {
      title: '',
      translate: '_AWAITING',
      icon: <PackagesIcon />,
      itemName: dashboardInfo?.awaiting,
      path: '/'
    },
    {
      title: 'warehouse',
      translate: '_WAREHOUSE',
      icon: <WareHouseIcon />,
      itemName: dashboardInfo?.usa_warehouse,
      path: '/warehouse'
    },
    {
      title: 'pending',
      translate: '_PENDING',
      icon: <SentPackagesIcon />,
      itemName: dashboardInfo?.pending,
      path: '/pending'
    },
    {
      title: 'arrived',
      translate: '_ARRIVED',
      icon: <FlightArrivedIcon />,
      itemName: dashboardInfo?.arrived,
      path: '/arrived'
    },
    {
      title: 'received',
      translate: '_RECEIVED',
      icon: <ReceivedPackagesIcon />,
      itemName: dashboardInfo?.received,
      path: '/received'
    }
  ];

  const [showCategories, setShowCategories] = useState(window.innerWidth);

  const reportWindowSize = useCallback(() => {
    setShowCategories(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, [window.innerWidth]);

  return (
    <div>
      {showCategories < 1200 && (
        <div className="d-flex w-full mt-5 justify-content-between flex-row dashboardRow">
          {categoriesList.map((item, index) => (
            <div
              key={index}
              className="bg-white d-flex flex-grow-1 me-2 border-rad-12 dashboardCategories"
            >
              <Link
                className={`d-flex position-relative ${
                  activeTab === item.title
                    ? 'dashboard-categories-item categories-active'
                    : 'dashboard-categories-item'
                } border-rad-12 pb-4 align-items-center flex-column`}
                to={item.path}
              >
                <div>
                  <div className="categories-numbers-absolute">
                    <div>
                      <p>{item.itemName}</p>
                    </div>
                  </div>
                  <div className=" pt-3">
                    <div className="d-flex responsive-category-icon align-items-center justify-content-center">
                      {item.icon}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ResponsiveCategories;
