import { t } from 'i18next';
import React, { useState, useEffect, useContext } from 'react';
import { GlobeIcon } from '../assets/icons';
import ChangablePackageDetails from './ChangablePackageDetails';
import Search from './Search';
import Resolution from '../components/hooks/resolution';
import GlobalContext from './GlobalContext';

export default function Shippers({ chooseShipper, shipperSearch, shippersList, shippersLoading, chooseShipperItem }) {
  const [search, setSearch] = useState('');
  const [searchedData, setSearchedData] = useState([]);
  const { token, setAlert, setShowLoader } = useContext(GlobalContext);
  const resolution = Resolution();
  useEffect(() => {
    setSearch(shipperSearch);
  }, [shipperSearch]);

  useEffect(() => {
    setShowLoader(shippersLoading);
  }, [shippersLoading]);

  useEffect(() => {
    if (search.length > 0) {
      if (shippersList) {
        const filteredShippers = shippersList.filter((item) => {
          return item.name.toLowerCase().includes(search.toLowerCase());
        });
        if (filteredShippers?.length > 0) {
          setSearchedData(filteredShippers.slice(0, 100));
        } else {
          setSearchedData([{ name: t('_WEBSITE_NOT_FOUND') }]);
        }
      }
      //* * ჭედავს და ამიტომ შევამცირე სია */
    } else {
      setSearchedData(undefined);
    }
  }, [search]);

  return (
    <div className='ShippersSidebar'>
      <h3 className='SectionTitle sidebarTitle'>{t('_key_choose_website')}</h3>
      <div className='shippersMainDiv sidebar-child-scroll'>
        <div className='searchDiv'>
          <Search value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('_key_search_website')} />
          {searchedData ? (
            <ul className='searchedItems'>
              {searchedData.map((shipper) => {
                return (
                  <li
                    key={shipper.id}
                    className='shippersListItem'
                    onClick={(e) => {
                      if (shipper.name !== t('_WEBSITE_NOT_FOUND')) {
                        chooseShipperItem(e, shipper);
                        setSearch('');
                      }
                    }}
                  >
                    {shipper.name}
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
        <div className='my-4 shippersListDiv'>
          {
            // resolution > 1500 ? 6 : resolution > 1200 ? 5 : 4
            shippersList
              ? shippersList.slice(0, 5).map((shipper) => {
                  return (
                    <div className='my-2' key={shipper.id}>
                      <ChangablePackageDetails icon={<GlobeIcon />} src title={shipper.name} viewable={true} onClickPassed={(e) => chooseShipper(shipper)} />
                    </div>
                  );
                })
              : null
          }
        </div>
      </div>
    </div>
  );
}
