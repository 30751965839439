// hooks
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import axios from 'axios';

// assets
import { LocationIcon } from '../../../../../assets/icons';

// components
import apiRequest from '../../../../../components/ApiRequest';
import Button from '../../../../../components/Button';
import ChangablePackageDetails from '../../../../../components/ChangablePackageDetails';
import GlobalContext from '../../../../../components/GlobalContext';
import NotFound from '../../../../../components/NotFound';
import SideBar from '../../../../../components/sidebar/SideBar';
import Search from '../../../../../components/Search';
import Selector from '../../../../../components/Selector';
import { lockers } from '../../../../../data/lockers';

const LockerSettings = ({
  locker,
  selectedLocker,
  setSelectedLocker
}) => {
  const [openLockers, setOpenLockers] = useState(false);
  const [selectedLockerInfo, setSelectedLockerInfo] = useState([]);
  const [lockersData, setLockersData] = useState(undefined);
  const initialLockersData = useRef([]);
  const lockersListRef = useRef(null);
  const { token, setShowLoader } = useContext(GlobalContext);
  const lockerId = locker && locker.default_locker;

  const { t } = useTranslation();

  useEffect(() => {
    setLockersData(lockers);
    initialLockersData.current = lockers;
  }, []);

  useEffect(() => {
    setSelectedLocker(lockerId);
  }, [lockerId]);

  useEffect(() => {
    if (lockersData && lockersData.length > 0) {
      const filter = lockersData.filter(
        (item) => parseInt(item.id) === selectedLocker
      );
      setSelectedLockerInfo(filter[0]);
    } if (selectedLocker !== locker?.default_locker) {
      lockersListRef.current.close();
    }
  }, [selectedLocker, lockersData, locker]);

  const handleOpenSecondStep = () => {
    lockersListRef.current.open();
  };

  const handleCloseSecondStep = () => {
    lockersListRef.current.close();
  };

  const [search, setSearch] = useState('');
  const [districtsData, setDistrictsData] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);

  const handleClick = (id) => {
    setSelectedLocker(parseInt(id));
    handleCloseSecondStep();
  };

  useEffect(() => {
    return axios({
      method: 'GET',
      url: 'https://locker.ge/public/ws/do.php',
      params: {
        secret: '03C9D9230C-1DB6BB-4CDFB1-823E-645346E1',
        command: 'get_districts'
      }
    })
      .then((res) => setDistrictsData(res?.data.result))
      .catch((e) => console.log(e, e?.response?.data, e?.toJSON?.()));
  }, []);

  useEffect(() => {
    if (search.length > 0) {
      const filteredLocker = lockersData
        .map((item) => {
          item._search = t(item.address);
          item._title = t(item.title);
          return item;
        })
        .filter((item) => {
          let matches = false;
          for (const key in item) {
            if (
              String(item[key]).toLowerCase().indexOf(search.toLowerCase()) > -1
            ) {
              matches = true;
              break;
            }
          }
          return matches;
        });
      setLockersData(filteredLocker);
    } else {
      setLockersData(initialLockersData.current);
    }
  }, [search]);

  return (
    <div className='locker-settings w-full'>
      <div className="d-flex flex-column justify-content-between height-calculated">
        <div className="pr-60">
          <div onClick={handleOpenSecondStep} className="lockerFirstStep">
            {!selectedLocker ? (
              <ChangablePackageDetails
                containerStyle="cursor-pointer w-full"
                editable
                icon={<LocationIcon />}
                title={t('_CHOOSE_LOCKER')}
              />
            ) : (
              <ChangablePackageDetails
                containerStyle="cursor-pointer w-full"
                src={selectedLockerInfo?.logo}
                viewable
                title={t(selectedLockerInfo?.title)}
                content={t(selectedLockerInfo?.address)}
              />
            )}
          </div>
        </div>
      </div>
      <SideBar
        openSidebar={openLockers}
        setOpenSidebar={setOpenLockers}
        ref={lockersListRef}
      >
        <div className="d-flex flex-column justify-content-between height-100">
          <h1 className="font-size-16 mb-5 bold">{t('_LOCKER_SETTINGS')}</h1>
          <div className="overflow-scroll">
            <div className="pr-60 height-100">
              <div className="mb-4">
                <Search
                  placeholder={t('_SEARCH_LOCKER')}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Selector
                  placeholder={t('_key_select_locker')}
                  defaultValue={selectedDistrict}
                  onChange={(item) => setSelectedDistrict(item.id)}
                  options={districtsData}
                  getOptionValue={(item) => item.id}
                  getOptionLabel={(item) => t(item.title)}
                />
              </div>
              <div style={{ minHeight: '600px' }}>
                {lockersData && !selectedDistrict && lockersData.length > 0 ? (
                  lockersData.map((item, index) => (
                    <div
                      onClick={() => handleClick(item.id)}
                      className="mb-2"
                      key={index}
                    >
                      <ChangablePackageDetails
                        containerStyle="cursor-pointer"
                        src={item.logo}
                        selected={parseInt(item.id) === selectedLocker}
                        iconStyle="black-icon"
                        title={t(`${item.title}`).slice(0, 20)}
                        content={t(`${item.address}`)
                          .replace('_ADDRESS', '')
                          .slice(0, 60)}
                        viewable
                      />
                    </div>
                  ))
                ) : selectedDistrict && lockersData ? (
                  lockersData
                    .filter(
                      (item) => parseInt(item.district_id) === selectedDistrict
                    )
                    .map((locker, index) => (
                      <div
                        onClick={() => handleClick(locker.id)}
                        className="mb-2"
                        key={index}
                      >
                        <ChangablePackageDetails
                          key={index}
                          containerStyle="cursor-pointer"
                          src={locker.logo}
                          selected={parseInt(locker.id) === selectedLocker}
                          iconStyle="black-icon"
                          title={t(`${locker.title}`).slice(0, 20)}
                          content={t(`${locker.address}`)
                            .replace('_ADDRESS', '')
                            .slice(0, 20)}
                          viewable
                        />
                      </div>
                    ))
                ) : (
                  <NotFound />
                )}
              </div>
            </div>
          </div>
          <div className="sidebarButtonDiv">
            <Button
              handleClick={handleCloseSecondStep}
              type="submit"
              btnStyle="bg-orange primary-btn-w-h"
              text={t('_RETURN_BACK')}
              color="color-white"
            />
          </div>
        </div>
      </SideBar>
    </div>
  );
};

export default LockerSettings;
