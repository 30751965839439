import React from 'react';
import usa2georgiaLogoBlack from '../assets/images/logo-black.svg';

export const SiteLoader = ({ device }) => {
  if (device === 'mobile') {
    return (
      <div
        style={{
          position: 'fixed',
          left: 0,
          width: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#161619'
        }}
      ></div>
    );
  }

  return (
    <div
      style={{
        position: 'fixed',
        left: 0,
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#fff',
        top: -50
      }}
    >
      <img style={{ width: '250px' }} className='w-96' src={usa2georgiaLogoBlack} alt='logo' />
    </div>
  );
};
