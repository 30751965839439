// hooks
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// components
import Input from '../../../../../components/input/Input';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import ReCAPTCHA from 'react-google-recaptcha';

const CreatePassword = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  acceptAgreement,
  setAcceptAgreement,
  activeStep,
  setCaptchaValue
}) => {
  const { t } = useTranslation();
  const currentLang = i18n.language;
  const inputRef = useRef();

  function onChangeCaptcha(value) {
    setCaptchaValue(value);
  }

  useEffect(() => {
    if (activeStep === 3) {
      const timer = setTimeout(() => inputRef.current?.focus(), 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [activeStep]);

  return (
    <div className="mt-4 passwordStep">
      <div>
        <Input
          ref={inputRef}
          type="password"
          label={t('_NEW_PASSWORD')}
          value={values.password}
          name="password"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && errors.password ? t(errors.password) : ''}
        />
        <Input
          type="password"
          label={t('_CONFIRM_NEW_PASSWORD')}
          value={values.retryPassword}
          name="retryPassword"
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched.retryPassword && errors.retryPassword
              ? t(errors.retryPassword)
              : ''
          }
        />
      </div>
      <div className="mt-264 d-flex justify-content-start flex-column">
        <div className="d-flex mb-4 align-items-center">
          <Checkbox
            onChange={() =>
              setAcceptAgreement(parseInt(acceptAgreement) === 1 ? 0 : 1)
            }
            checked={acceptAgreement}
            setChecked={setAcceptAgreement}
          />
          <p className="ms-3">
            {t('_AGREE')} &nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href={
                currentLang === 'ka'
                  ? '/shipping/agreement_ka.html'
                  : '/shipping/agreement_en.html'
              }
            >
              <span className="color-orange text-decoration-underline">
                {t('_TERMS')}
              </span>
            </a>
          </p>
        </div>
      </div>
      <ReCAPTCHA
        sitekey="6LcbGCsUAAAAAFZDGV0ZdLG-oxIcTXjPrNAH9Rpp"
        onChange={onChangeCaptcha}
      />
    </div>
  );
};

export default CreatePassword;
