export const testCustomerIds = [427191, 422811, 15, 7858, 54, 4143];

export const getAmountWithCurrency = (amount, currency) => {
  if (currency === '$') {
    return `${currency} ${amount}`;
  } else {
    return `${amount} ${currency}`;
  }
};

function paddy(num, padlen = 2, padchar = 0) {
  const padChar = typeof padchar !== 'undefined' ? padchar : '0';
  const pad = new Array(1 + padlen).join(padChar);
  return (pad + num).slice(-pad.length);
}

export const monthGeorgian = ['იანვარი', 'თებერვალი', 'მარტი', 'აპრილი', 'მაისი', 'ივნისი', 'ივლისი', 'აგვისტო', 'სექტემბერი', 'ოქტომბერი', 'ნოემბერი', 'დეკემბერი'];
export const monthEnglish = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const getGeorgianDateTime = ($lang = 'ka') => {
  const year = new Date().getFullYear();
  const month = $lang === 'ka' ? monthGeorgian[new Date().getMonth()] : monthEnglish[new Date().getMonth()];

  return `${new Date().getDate()} ${month}, ${year} ${paddy(new Date().getHours())}:${paddy(new Date().getMinutes(), 2)}`;
};

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
