import React from 'react';
import { useTranslation } from 'react-i18next';

const PriorityStatus = ({ status }) => {
  const { t } = useTranslation();
  const switchTitle = () => {
    switch (status) {
      case 4:
        return '_URGENT';
      case 3:
        return '_HIGH';
      case 2:
        return '_MEDIUM';
      case 1:
        return '_LOW';
    }
  };

  return (
    <div className={`priority-status priority-${status}`}>
      <div />
      <p>{t(switchTitle())}</p>
    </div>
  );
};

export default PriorityStatus;
