import React, { useState, useEffect } from 'react';
import i18n from 'i18next';

import SFHeader from './SFHeader';
import SFForm from './SFForm';
import SFResult from './SFResult';

import './SubmitForm.css';

const SubmitForm = () => {
  const [success, setSuccess] = useState(false);

  // useEffect(() => {
  //   i18n.changeLanguage('ka');
  // }, []);

  return <div style={{ textAlign: 'center', marginTop: 40 }}>მოთხოვნის მიღება დასრულებულია</div>;

  // if (document.referrer && (document.referrer === 'https://news.usa2georgia.com/' || document.referrer === 'http://news.usa2georgia.com/')) {
  //   return (
  //     <div className='sf'>
  //       <SFHeader />
  //       <div className='p-4 pt-2'>{success ? <SFResult /> : <SFForm setSuccess={setSuccess} />}</div>
  //     </div>
  //   );
  // } else {
  //   window.location.href = 'https://news.usa2georgia.com/2023/01/usa2georgia/';
  // }
};

export default SubmitForm;
