import React from 'react';
import { useTranslation } from 'react-i18next';
import { FailIcon } from '../../../assets/icons';

const IpayError = () => {
  const { t } = useTranslation();

  return (
    <div className="ipay-item position-relative">
      <h1 className="font-size-20 mt-3 color-orange text-center">
        {t('_RESULT_PAGE_FAIL')}
      </h1>
      <div className="transaction-error">
        <div className="d-flex h-100 justify-content-center align-items-center">
          <FailIcon />
        </div>
      </div>
    </div>
  );
};

export default IpayError;
