// hooks
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// components
import Input from '../../../../../../components/input/Input';
import AttachFile from '../../../../../../components/attachFile/AttachFile';
import SmallIcon from '../../../../../../components/SmallIcon';

// assets
import { AttachFileIcon, CrossIcon } from '../../../../../../assets/icons';

const ReplyTicket = ({
  replyText,
  setReplyText,
  attachments,
  setAttachments,
  activeStep
}) => {
  const { t } = useTranslation();

  const inputRef = useRef();

  useEffect(() => {
    if (activeStep === 3) {
      const timer = setTimeout(() => inputRef.current?.focus(), 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [activeStep]);

  const handleImageChange = (event) => {
    setAttachments((items) => [...items, event.target?.files?.[0] ?? null]);
  };

  const handleDeleteFile = (name) => {
    setAttachments(attachments.filter((item) => item.name !== name));
  };

  return (
    <div className="reply-ticket">
      <p className="bold">{t('_reply')}</p>
      <Input
        ref={inputRef}
        onChange={(e) => setReplyText(e.target.value)}
        name="replyText"
        value={replyText}
        customStyle="ticket-input mt-4"
        placeholder={t('_TEXT')}
        multiline
      />
      {attachments.length > 0 &&
        attachments.map((item, index) => (
          <div
            onClick={() => handleDeleteFile(item.name)}
            key={index}
            className="ticket-file d-flex justify-content-between align-items-center"
          >
            <p className="font-size-14 color-third-grey">{item.name}</p>
            <SmallIcon
              containerStyle="icon-light-bg ticket-delete-item cursor-pointer"
              img={<CrossIcon />}
            />
          </div>
        ))}
      <div className="ticket-file d-flex justify-content-between align-items-center">
        <p className="font-size-14 color-third-grey">{t('_attachement')}</p>
        <AttachFile
          accept=".jpg, .jpeg, .png"
          onChange={handleImageChange}
          icon={
            <SmallIcon
              containerStyle="icon-light-bg cursor-pointer"
              img={<AttachFileIcon />}
            />
          }
        />
      </div>
    </div>
  );
};

export default ReplyTicket;
