import React, { useContext, useEffect, useRef } from 'react';
import Input from '../../../components/input/Input';
import Button from '../../../components/Button';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import apiRequest from '../../../components/ApiRequest';
import snakecaseKeys from 'snakecase-keys';
import { ChangePasswordSchema } from '../../../components/validations/ChangePasswordSchema';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../../components/GlobalContext';
import { Eye } from '../../../assets/icons';

const ChangePassword = ({ setOpenSettings }) => {
  const { setAlert } = useContext(GlobalContext);
  const { t } = useTranslation();
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    setTouched,
    errors,
    isValid
  } = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      retryNewPassword: ''
    },
    enableReinitialize: true,
    validationSchema: ChangePasswordSchema
  });

  const inputRef = useRef(null);

  const changePasswordSettings = useMutation(
    () =>
      apiRequest(
        {
          command: 'save_customer_settings',
          sessionId: localStorage.getItem('token')
        },
        {
          data: {
            password_change: snakecaseKeys(values)
          }
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          setOpenSettings(false);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      }
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      changePasswordSettings.mutate();
    }
  };

  useEffect(() => {
    if (touched.currentPassword && errors.currentPassword) {
      setTouched({
        currentPassword: true,
        newPassword: true,
        retryNewPassword: true
      });
    }
  }, [touched.currentPassword, errors.currentPassword]);

  useEffect(() => {
    const timer = setTimeout(() => inputRef.current?.focus(), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="d-flex flex-column height-100 justify-content-between sideBarChildComponent">
      <h1 className="font-size-16 mb-5 bold sidebarTitle">
        {t('_CHANGE_PASSWORD')}
      </h1>
      <form
        className="d-flex flex-column height-100 justify-content-between form"
        onSubmit={handleSubmit}
      >
        <div className="sidebar-child-scroll">
          <div className="pr-60">
            <div>
              <Input
                ref={inputRef}
                type="password"
                rightIcon={<Eye />}
                rightIconType="passwordVisibility"
                label={t('_CURRENT_PASSWORD')}
                name="currentPassword"
                value={values.currentPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.currentPassword && errors.currentPassword
                    ? t(errors.currentPassword)
                    : ''
                }
              />
              <Input
                type="password"
                rightIcon={<Eye />}
                rightIconType="passwordVisibility"
                label={t('_NEW_PASSWORD')}
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.newPassword && errors.newPassword
                    ? t(errors.newPassword)
                    : ''
                }
              />
              <Input
                type="password"
                rightIcon={<Eye />}
                rightIconType="passwordVisibility"
                label={t('_CONFIRM_NEW_PASSWORD')}
                name="retryNewPassword"
                value={values.retryNewPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.retryNewPassword && errors.retryNewPassword
                    ? t(errors.retryNewPassword)
                    : ''
                }
              />
            </div>
          </div>
        </div>
        <div className="sidebarButtonDiv">
          <Button
            isLoading={changePasswordSettings.isLoading}
            type="submit"
            btnStyle="bg-orange primary-btn-w-h"
            text={t('_SAVE')}
            color="color-white"
          />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
