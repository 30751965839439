import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  device: 'web'
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDevice: (state, action) => {
      state.device = action.payload;
    }
  }
});

export const appSliceActions = appSlice.actions;
export default appSlice.reducer;
