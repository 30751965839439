import React, { useContext, useState } from 'react';
import GlobalContext from '../../components/GlobalContext';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button';
import './Popup.css';
import { useMutation } from 'react-query';
import apiRequest from '../ApiRequest';
import { getCustomerInfoAsyncThunk } from '../../store/thunks/customer.thunk';
import { LoaderIcon } from '../../assets/icons';

function Popup() {
  const dispatch = useDispatch();
  const { setShowLoader, token } = useContext(GlobalContext);
  const confirmation = useSelector((state) => state.customer.confirmation);
  const [isLoading, setIsLoading] = useState(false);

  const confirmPopup = useMutation(
    (name) =>
      apiRequest(
        {
          command: 'confirm_popup',
          sessionId: token
        },
        {
          confirmationId: confirmation?.id,
          button: name
        }
      ),
    {
      onSuccess: (response) => {
        setShowLoader(false);
        if (response.data.status === 'OK') {
          dispatch(getCustomerInfoAsyncThunk());
        }
      },
      onError: (response) => {
        console.log(response);
      }
    }
  );

  const clickHandler = (name) => {
    setShowLoader(true);
    confirmPopup.mutate(name);
  };

  if (confirmation === null || confirmation?.id === '11') {
    return <></>;
  }
  return (
    <>
    <div 
    style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 60 }}
    >
      <div style={{ zIndex: 61, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 12 }} className='popup-width'>   
          <div style={{ overflow: 'auto', maxHeight: 500 }}>
            <div dangerouslySetInnerHTML={{ __html: confirmation?.message }}></div>
          </div>
          <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
            {!isLoading ? confirmation?.buttons?.map(button => {
              return (
                <div key={button?.name}>
                <Button
                  handleClick={() => { clickHandler(button?.name); }}
                  btnStyle="bg-orange logout-btn"
                  text={button?.title}
                  color="color-white"
                  btnColor={button?.color}
                />
                </div>
              );
            }) : <div className="loader">
              <LoaderIcon />
            </div>}
          </div>
        </div>
      </div>
    </div>
    
    </>
  );
}

export default Popup;
