import React, { useEffect, useState } from 'react';
import { Eye, EyeHidden } from '../../assets/icons';
import './Input.scss';
import classNames from 'classnames';

// eslint-disable-next-line react/display-name
const Input = React.forwardRef(
  (
    {
      customStyle,
      customClass,
      icon,
      rightIcon,
      rightIconType,
      placeholder,
      type,
      label,
      bg,
      error,
      value,
      disabled,
      multiline,
      validateKa,
      validateEn,
      ...props
    },
    ref
  ) => {
    const [isActive, setIsActive] = useState(false);
    const [inputType, setInputType] = useState(type);
    const [visibilityIcon, setVisibilityIcon] = useState(false);

    useEffect(() => {
      value ? setIsActive(true) : setIsActive(false);
    }, [value]);

    return (
      <div
        className={classNames(
          'profile-input-container',
          'position-relative',
          customClass,
          { 'd-flex justify-content-center align-items-center': icon },
          { 'float-label': !icon },
          { '': error } // profile-input-error
        )}
      >
        <span className="input-icon">{icon}</span>
        {!multiline ? (
          <>
            <input
              ref={ref}
              placeholder={placeholder}
              value={value || ''}
              disabled={disabled}
              className={`${customStyle} ${
                icon
                  ? `login-input primary-btn-w-h ${bg}`
                  : error
                  ? 'red-border'
                  : disabled && 'cursor-disabled'
              } ${rightIcon ? 'inputWithRightIcon' : ''}`}
              type={inputType}
              onChange={(e) =>
                icon && e.target.value.length > 0
                  ? setIsActive(true)
                  : setIsActive(false)
              }
              {...props}
            />
            {rightIcon && (
              <span
                className="input-icon-right"
                onClick={() => {
                  if (rightIconType === 'passwordVisibility') {
                    if (inputType === 'password') {
                      setInputType('text');
                    } else if (inputType === 'text') {
                      setInputType('password');
                    }
                    setVisibilityIcon(!visibilityIcon);
                  }
                }}
              >
                {visibilityIcon ? <Eye /> : <EyeHidden />}
              </span>
            )}
          </>
        ) : (
          <textarea
            ref={ref}
            placeholder={placeholder}
            value={value || ''}
            disabled={disabled}
            className={`${customStyle} ${
              icon
                ? `login-input primary-btn-w-h ${bg}`
                : error
                ? 'red-border'
                : disabled && 'cursor-disabled'
            }`}
            type={inputType}
            onChange={(e) =>
              icon && e.target.value.length > 0
                ? setIsActive(true)
                : setIsActive(false)
            }
            {...props}
          />
        )}
        {error && (
          <div className="color-red validation-error-text">
            <span style={{ fontSize: '11px' }}>{error}</span>
          </div>
        )}
        <label className={isActive ? 'Active' : ''}>{label}</label>
      </div>
    );
  }
);

export default Input;
