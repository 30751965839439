// hooks
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { LocationIcon } from '../../../../../assets/icons';

// components
import Input from '../../../../../components/input/Input';
import ChangablePackageDetails from '../../../../../components/ChangablePackageDetails';
import { branches } from '../../../../../data/branches';

export const EmailAndPhone = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  activeStep,
  validationError,
  executeScroll,
  setFieldValue
}) => {
  const [showBranches, setShowBranches] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(values.defaultBranch);
  const { t } = useTranslation();
  const inputRef = useRef();
  const scrollRef = useRef();
  useEffect(() => {
    if (activeStep === 1) {
      const timer = setTimeout(() => inputRef.current?.focus(), 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [activeStep]);

  useEffect(() => {
    if (validationError) {
      executeScroll(scrollRef);
    }
  }, [validationError]);

  const branchesData = branches;

  const chooseDefaultBranch = (item) => {
    setFieldValue('defaultBranch', item.id);
    setSelectedBranch(item.id);
    setShowBranches(false);
  };

  return (
    <div className="mt-4">
      <div ref={scrollRef}>
        <Input
          ref={inputRef}
          label={t('_LABEL_MOBILE')}
          value={values.mobile}
          name="mobile"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.mobile && errors.mobile ? t(errors.mobile) : ''}
        />
        <Input
          label={t('_EMAIL')}
          value={values.email}
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          error={(touched.email && errors.email) ? t(errors.email) : ''}
        />
      </div>
      <ChangablePackageDetails
        selected={selectedBranch}
        onClickPassed={() => setShowBranches(!showBranches)}
        containerStyle="cursor-pointer mb-2"
        iconStyle="orange-icon"
        icon={<LocationIcon />}
        title={
          selectedBranch ? t(branchesData.filter(branch => branch.id === selectedBranch).map(item => item.name)[0]) : t('_key_select_branch')
        }
        content={
          selectedBranch ? t(branchesData.filter(branch => branch.id === selectedBranch).map(item => item.address)[0]) : t('_key_select_branch')
        }
      />
      <div>
        {branchesData &&
          showBranches &&
          branchesData
            .filter((item) => item.id !== selectedBranch?.id)
            .map((item, index) => (
              <ChangablePackageDetails
                key={index}
                onClickPassed={() => chooseDefaultBranch(item)}
                containerStyle="cursor-pointer mb-2"
                icon={<LocationIcon />}
                iconStyle="orange-icon"
                title={t(`${item.name}`)}
                content={t(`${item.address}`)}
                viewable
              />
            ))}
      </div>
    </div>
  );
};
export default EmailAndPhone;
