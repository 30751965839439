import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function HowUsa2Works() {
  const { t } = useTranslation();

  return (
    <div className='how-usa2-works'>
      <Container fluid className='p-0'>
        <Row>
          <Col lg={6}>
            <div className='how-usa2-left-sticky mt-2'>
              <h2 className='how-usa2-works-title color-white'>
                {t('_HOW_DOES_IT_WORK')} <br /> USA2GEORGIA
              </h2>
              <ul className='how-it-works-bullets-container'>
                <li>
                  {' '}
                  <div className='how-it-works-bullet-div'>
                    <div className='how-it-works-bullet'>
                      <p className='color-gray bullet-num'>01</p>
                    </div>
                    <p className='color-gray how-usa2-works-p ms-4'>{t('_HOWDOESITWORKA_HEAD')}</p>
                  </div>
                </li>
                <li>
                  <div className='how-it-works-bullet-div'>
                    <div className='how-it-works-bullet'>
                      <p className='color-gray bullet-num'>02</p>
                    </div>
                    <p className='color-gray how-usa2-works-p ms-4'>{t('_HOWDOESITWORKB_HEAD')}</p>
                  </div>
                </li>
                <li>
                  <div className='how-it-works-bullet-div'>
                    <div className='how-it-works-bullet'>
                      <p className='color-gray bullet-num'>03</p>
                    </div>
                    <p className='color-gray how-usa2-works-p ms-4'>{t('_HOWDOESITWORKC_HEAD')}</p>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={6}>
            <div className='how-usa2-works-right-div'>
              <h3 className='how-usa2-right-h3'>{t('_HOWDOESITWORKA_HEAD')}</h3>
              <p className='how-usa2-right-p font-size-14' dangerouslySetInnerHTML={{ __html: t('_HOWDOESITWORKA_BODY') }}></p>
            </div>
            <div className='how-usa2-works-right-div'>
              <h3 className='how-usa2-right-h3'>{t('_HOWDOESITWORKB_HEAD')}</h3>
              <p className='how-usa2-right-p font-size-14' dangerouslySetInnerHTML={{ __html: t('_HOWDOESITWORKB_BODY') }}></p>
            </div>
            <div className='how-usa2-works-right-div'>
              <h3 className='how-usa2-right-h3'>{t('_HOWDOESITWORKC_HEAD')}</h3>
              <p className='how-usa2-right-p font-size-14' dangerouslySetInnerHTML={{ __html: t('_HOWDOESITWORKC_BODY') }}></p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
