import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import {
  OfferSlider1Icon,
  OfferSlider2Icon,
  OfferSlider3Icon
} from '../../assets/icons';
import OrangeLine from '../../components/OrangeLine';

SwiperCore.use([Pagination]);

export default function WhatWeOffer() {
  const { t } = useTranslation();

  const slides = [
    {
      id: 1,
      heading: t('_HOWDOESITWORKA_HEAD'),
      paragraph: t('_WHATWEOFFERA_BODY'),
      src: <OfferSlider1Icon />
    },
    {
      id: 2,
      heading: t('_WHATWEOFFERB_HEAD'),
      paragraph: t('_WHATWEOFFERB_BODY'),
      src: <OfferSlider2Icon />
    },
    {
      id: 3,
      heading: t('_WHATWEOFFERD_HEAD'),
      paragraph: t('_WHATWEOFFERD_BODY'),
      src: <OfferSlider3Icon />
    }
  ];

  return (
    <div className="what-we-offer-container">
      <Row>
        <div>
          <OrangeLine />
        </div>
        <h2 className="about-us-title mt-2">
          {t('_WHAT_WE_OFFER')}
        </h2>
        <Swiper
          loop={true}
          grabCursor={true}
          freeMode={true}
          breakpoints={{
            768: {
              slidesPerView: 3
            },
            200: {
              slidesPerView: 1
            }
          }}
          slidesPerView={2}
          spaceBetween={30}
          className="mySwiper"
        >
          <div>
            {slides.map((slide) => (
              <SwiperSlide key={slide.id}>
                <div className="offer-slider-container">
                  <span>{slide.src}</span>
                  <h4 className="mb-4 mt-4 font-weight-bold  font-size-16">
                    {slide.heading}
                  </h4>
                  <p className="offer-p-slider-color font-size-14">
                    {slide.paragraph}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </Row>
    </div>
  );
}
