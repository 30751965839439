// hooks
import React, { useRef } from 'react';

// scss
import './Modal.scss';

// assets
import { LoaderIcon } from '../../assets/icons';

// components
import outsideClickDetector from '../outside-click-detector/outsideClickDetector';

const Modal = ({ showModal, setShowModal, children, modal }) => {
  const modalRef = useRef(null);

  const closeModal = () => {
    setShowModal(false);
  };

  if (!modal) {
    outsideClickDetector(modalRef, closeModal);
  }

  if (!showModal) {
    return null;
  }

  return (
    <div
      className={'modal-container modal-container-' + showModal}
      onClick={(e) => e.stopPropagation()}
    >
      <div ref={modalRef} className={'opacity opacity-' + showModal} />
      <div className={'modal-bg modal-bg-' + showModal}>
        <div className="modal">
          <LoaderIcon />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
