import React from 'react';
import { Arrow } from '../../../../assets/icons';

function MenuItem({ item, setParentId, addToBreadcrumbs }) {
  return (
    <div
      className='hc-menu-item'
      onClick={() => {
        setParentId(item.id);
        addToBreadcrumbs(item);
      }}
    >
      <div>{item.title}</div>
      <div>
        <Arrow />
      </div>
    </div>
  );
}

export default MenuItem;
