import React, { useContext, useEffect } from 'react';
import './Alert.scss';
import GlobalContext from '../GlobalContext';

const Alert = () => {
  const { alert, setAlert } = useContext(GlobalContext);
  const status = alert && alert[0];
  const text = alert && alert[1];

  useEffect(() => {
    if (alert.length > 0) {
      const timer = setTimeout(() => setAlert([]), 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [alert]);

  return (
    <div
      onClick={() => { setAlert([]); }}
      className={`alert-container cursor-pointer ${alert.length > 0} ${
        status === 'success'
          ? 'alert-success'
          : status === 'error'
          ? 'alert-error'
          : ''
      }`}
    >
      <div className="container-div">
        <p className="ms-3">{text}</p>
      </div>
    </div>
  );
};

export default Alert;
