import axios from 'axios';

export const freshDeskRequest = ({ method = 'GET', command, params, data }) => {
  return axios({
    method,
    url: `https://usa2georgia.freshdesk.com/api/v2/${command}`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    auth: {
      username: 'pjSIC2jI3eiaA9mf90qK',
      password: 'x'
    },
    params,
    data
  })
    .then((res) => res.data)
    .catch((e) => console.log(e, e?.response?.data, e?.toJSON?.()));
};
