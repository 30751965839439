import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import OrangeLine from '../../components/OrangeLine';
import {
  AirplaneIcon,
  CourierIcon,
  InboxIcon,
  LocationIcon,
  MoneyIcon,
  PersonIcon,
  TargetIcon,
  TruckIcon
} from '../../assets/icons';
import { useTranslation } from 'react-i18next';

export default function WhyUsa2() {
  const { t } = useTranslation();

  const leftList = [
    {
      id: 1,
      name: t('_FREE_REGISTRATION'),
      img: <PersonIcon />
    },
    {
      id: 2,
      name: t('_DAILY_SHIPMENTS_FROM_USA'),
      img: <AirplaneIcon />
    },
    {
      id: 3,
      name: t('_TRACKING_SYSTEM'),
      img: <TargetIcon />
    },
    {
      id: 4,
      name: t('_COURIER_SERVICE_ACROSS_GEORGIA'),
      img: <TruckIcon />
    }
  ];

  const rightList = [
    {
      id: 5,
      name: t('_US_MAILING_ADDRESS'),
      img: <LocationIcon />
    },
    {
      id: 6,
      name: t('_PACKAGE_CONTROL_PANEL'),
      img: <InboxIcon />
    },
    {
      id: 7,
      name: t('_VARIOUS_PAYMENT_METHODS'),
      img: <MoneyIcon />
    },
    {
      id: 8,
      name: t('_FREE_COURIER_DELIVERY_WITHIN_TBILISI'),
      img: <CourierIcon />
    }
  ];

  return (
    <div className="why-usa2-container d-flex justify-content-between">
      <Container fluid className="p-0">
        <Row className="p-0">
          <Col lg={6} md={12} xs={12}>
            <div className="orange-line-margin-for-whyusa2">
              <OrangeLine />
            </div>
            <h3 className="about-us-title mt-2 why-usa2-title-padding-bottom">
              {t('_WHY_USAGEORGIA')}
            </h3>
            <div>
              {leftList.map((item) => (
                <div key={item.id} className="why-usa2-div">
                  <div className="why-usa2-icon d-flex align-items-center justify-content-center me-4">
                    {item.img}
                  </div>
                  <p className="font-size-14">{item.name}</p>
                </div>
              ))}
            </div>
          </Col>
          <Col lg={6} md={12} xs={12}>
            <div className="whyusa-margin">
              {rightList.map((item) => (
                <div
                  key={item.id}
                  className={
                    item.id === 1
                      ? 'why-usa2-div usa-address-margin'
                      : 'why-usa2-div'
                  }
                >
                  <div className="why-usa2-icon d-flex  align-items-center justify-content-center me-4">
                    {item.img}
                  </div>
                  <p className="font-size-14">{item.name}</p>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
