import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { getAwaitingAsyncThunk } from '../../../store/thunks/packages.thunk';

import GlobalContext from '../../../components/GlobalContext';
import NoPackage from '../../../components/NoPackage';
import AddTrackingNew from '../../../components/Trackings/AddTrackingNew';
import Flight from '../../../components/Trackings/Flight';
import Dashboard from '../Dashboard';

const Awaiting = () => {
  // Hooks
  const dispatch = useDispatch();
  const data = useSelector((state) => state.packages.awaiting.data);
  const dataLoading = useSelector((state) => state.packages.awaiting.loading);
  const navigate = useNavigate();
  const { setShowLoader, setToken } = useContext(GlobalContext);

  useEffect(() => {
    dispatch(getAwaitingAsyncThunk()).then(({ payload }) => {
      if (payload?.code === 102) {
        navigate('/');
        setToken(null);
      }
    });
  }, []);

  useEffect(() => {
    setShowLoader(dataLoading);
  }, [dataLoading]);

  return (
    <Dashboard>
      <>
        <div>
          <div>
            <div className='w-full pt-4'>{<AddTrackingNew />}</div>
          </div>
        </div>
        <div>
          {data && data.length > 0 ? (
            <div>
              {data.map((item, index) => (
                <Flight packageStatus='awaiting' key={index} item={item} isAwaiting={true} />
                // <PackageBlock packageItem={item} key={index} />
              ))}
            </div>
          ) : !dataLoading ? (
            <NoPackage />
          ) : null}
        </div>
      </>
    </Dashboard>
  );
};

export default Awaiting;
