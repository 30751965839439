// hooks
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

// assets
import { MasterCardIcon, VisaIcon } from '../../../assets/icons';

// components
import GlobalContext from '../../../components/GlobalContext';
import ChangablePackageDetails from '../../../components/ChangablePackageDetails';
import apiRequest from '../../../components/ApiRequest';
import Button from '../../../components/Button';
import Modal from '../../../components/modal/Modal';

const CustomerCards = ({ setOpenSettings, cards }) => {
  const queryClient = useQueryClient();
  const { setAlert, token } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [deleteCardId, setDeleteCardId] = useState(null);
  const [defaultCard, setDefaultCard] = useState(null);

  const removeCard = useMutation(
    () =>
      apiRequest(
        {
          command: 'remove_card',
          sessionId: token
        },
        {
          cardId: deleteCardId
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          setOpenSettings(false);
          setShowModal(false);
          setDeleteCardId(null);
        } else {
          setAlert(['error', t(response.data.message)]);
          setShowModal(false);
        }
      }
    }
  );

  const changeDefaultCard = useMutation(
    () =>
      apiRequest(
        {
          command: 'change_default_card',
          sessionId: token
        },
        {
          cardId: Number(defaultCard)
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          setOpenSettings(false);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      }
    }
  );

  const closeModal = () => {
    setShowModal(false);
    setDeleteCardId(null);
  };

  const setRemoveCardId = (id) => {
    setShowModal(true);
    setDeleteCardId(parseInt(id));
  };

  const handleRemoveCard = async() => {
    try {
      await removeCard.mutateAsync();
      queryClient.invalidateQueries('getCards');
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleChangeCard = async() => {
    try {
      await changeDefaultCard.mutateAsync();
      queryClient.invalidateQueries('getCards');
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (cards) {
      const result = cards
        .filter((item) => item.default === '1')
        .map((card) => card.id)[0];
      setDefaultCard(result);
    }
  }, [cards]);

  return (
    <>
      <div className="d-flex height-100 customer-cards justify-content-center">
        <div className="w-full">
          <h1 className="font-size-16 pb-5 bold">{t('_SAVED_CARDS')}</h1>
          <div className="sidebar-child-scroll">
            <div className="pr-60">
              {cards && cards.length > 0
                ? cards.map((item, index) => (
                  <ChangablePackageDetails
                    icon={
                      item.card_type === 'MC' ? (
                        <MasterCardIcon />
                      ) : (
                        <VisaIcon />
                      )
                    }
                    onClickPassed={() => setDefaultCard(item.id)}
                    containerStyle="mb-4"
                    iconStyle="transaction-card-icon"
                    key={index}
                    title={item.card_number}
                    deletable
                    selectable={true}
                    selectedProduct={
                      item.id === defaultCard ? 'selectedDiv' : ''
                    }
                    deleteFunction={() => setRemoveCardId(item.id)}
                  />
                )) : <div className='text-center'>{t('_CARDS_NOT_FOUND')}</div>}
            </div>
          </div>
          {cards && cards.length > 0 && <div className="sidebarButtonDiv">
            <Button
              handleClick={handleChangeCard}
              isLoading={changeDefaultCard.isLoading}
              type="submit"
              btnStyle="bg-orange primary-btn-w-h"
              text={t('_SAVE')}
              color="color-white"
            />
          </div>}
        </div>
      </div>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <div className="d-flex justify-content-center flex-column align-items-center">
          <p className="font-size-14 text-center color-third-grey">
            {t('_DELETE_CARD_WARNING_MESSAGE')}
          </p>
          <div className="d-flex justify-content-center mt-5 align-items-center">
            <Button
              handleClick={handleRemoveCard}
              type="button"
              btnStyle="bg-orange me-2 fill-balance-btn"
              text={t('_YES')}
              color="color-white"
            />
            <Button
              handleClick={closeModal}
              type="button"
              btnStyle="bg-orange ms-2 fill-balance-btn"
              text={t('_NO')}
              color="color-white"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CustomerCards;
