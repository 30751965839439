// hooks
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

// components
import apiRequest from '../../components/ApiRequest';
import { Item } from './Item';
import GlobalContext from '../../components/GlobalContext';
import Wrapper from '../../components/Wrapper';

const Transactions = () => {
  const { t } = useTranslation();
  const { setShowLoader } = useContext(GlobalContext);

  const {
    data: getTransactions,
    isLoading,
    isSuccess
  } = useQuery('transactions', () =>
    apiRequest({
      command: 'get_transactions',
      sessionId: localStorage.getItem('token')
    })
  );

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  const transactions = isSuccess && getTransactions.data.result;

  return (
    <Wrapper>
      <div className="transactions-container">
        <h1 className="pt-5">{t('_TRANSACTIONS')}</h1>
        <div className="mt-5">
          {!isLoading ? (
            transactions.length > 0 ? (
              transactions.map((item, index) => (
                <Item key={index} item={item} />
              ))
            ) : (
              <div className="bg-white d-flex flex-column align-items-center pt-5 pb-5 border-rad-12">
                <p className="font-size-16 fw-bolder">
                  {t('_TRANSACTION_NOT_FOUND')}
                </p>
              </div>
            )
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
};

export default Transactions;
