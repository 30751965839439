import React from 'react';
import { FileErrorIcon, PDFIcon, PhotoIcon } from '../../assets/icons';
import { formatBytes } from '../../utils/utils';

export const FilesWithMessage = ({ data, message }) => {
  function getFileTypeImageURL(type) {
    let filename = '';
    if (type === 'image/png') {
      filename = 'png';
    } else if (type === 'image/gif') {
      filename = 'gif';
    } else if (type === 'image/jpeg') {
      filename = 'jpeg';
    } else if (type === 'image/webp') {
      filename = 'jpeg';
    } else if (type === 'application/pdf') {
      filename = 'pdf';
    }

    return `https://www.usa2georgia.com/static/images/file_types/${filename}.svg`;
  }

  console.log(data);
  return (
    <>
      <div style={{ marginBottom: 10 }}>
        {data.length > 0 && (
          <div className='ai-message-upload-panel'>
            {data.map((element, index) => {
              return (
                <div className='ai-chat-upload-file-item' key={index}>
                  <img style={{ width: 30, userSelect: 'none' }} src={getFileTypeImageURL(element.type)} alt='' title={element.name} />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div
        className='u2g-message-message-body'
        style={{ backgroundColor: '#eee', padding: 10, borderRadius: 8, fontSize: 14, color: '#444' }}
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </>
  );
};
