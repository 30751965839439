import React from 'react';
import { NotFoundIcon } from '../assets/icons';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex w-full justify-content-center">
      <div className="not-found-container">
        <div className="d-flex justify-content-center align-items-center">
          <NotFoundIcon />
        </div>
        <p className="text-center">{t('_key_data_not_found')}</p>
      </div>
    </div>
  );
};

export default NotFound;
