// hooks
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

// components
import Flight from '../../../components/Trackings/Flight';
import apiRequest from '../../../components/ApiRequest';
import Dashboard from '../Dashboard';
import GlobalContext from '../../../components/GlobalContext';

// assets
import { PackagesSecondaryIcon } from '../../../assets/icons';
import NoPackage from '../../../components/NoPackage';

const WareHouse = () => {
  const { setShowLoader } = useContext(GlobalContext);
  const { t } = useTranslation();
  const {
    data: getUsaWarehouse,
    isLoading,
    isSuccess
  } = useQuery('getUsaWarehouse', () =>
    apiRequest({
      command: 'get_usa_warehouse',
      sessionId: localStorage.getItem('token')
    })
  );

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  const wareHouse = isSuccess && getUsaWarehouse.data.result?.packages;

  return (
    <Dashboard>
      {wareHouse && wareHouse.length > 0 ? (
        <div className="pt-4">
          {wareHouse.map((item, index) => (
            <Flight packageStatus="inProgress" key={index} item={item} />
          ))}
        </div>
      ) : (
        !isLoading ? <NoPackage /> : null
      )}
    </Dashboard>
  );
};

export default WareHouse;
