import axios from 'axios';

export default function apiRequest({ command, sessionId, method }, params) {
  const axiosParams = {
    method: method || 'GET',
    url: 'https://www.usa2georgia.com/private_usa_ws/rest.php'
  };
  if (method === 'POST') {
    axiosParams.data = {
      secret: 'EFDCF20F32-B233EA-45DF75-B01E-8098A066',
      sessionId,
      command,
      ...params
    };
  } else {
    axiosParams.params = {
      secret: 'EFDCF20F32-B233EA-45DF75-B01E-8098A066',
      sessionId,
      command,
      ...params
    };
  }
  return axios(axiosParams).then((response) => {
    if (response.data.message === '_INVALID_SESSION') {
      localStorage.removeItem('token');
    }
    return response;
  });
}
