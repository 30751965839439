// hooks
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

// components
import apiRequest from '../../components/ApiRequest';
import Item from './Item';

// libraries
import GlobalContext from '../../components/GlobalContext';
import Wrapper from '../../components/Wrapper';
import ListButtons from './listButtons';
import ListButtonsNew from './ListButtonsNew';

const Address = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const { setShowLoader } = useContext(GlobalContext);
  const {
    data: getAdresses,
    isLoading,
    isSuccess
  } = useQuery(
    'usa2Adress',
    () =>
      apiRequest({
        command: 'get_addresses',
        sessionId: localStorage.getItem('token')
      }),
    {
      onSuccess: (response) => {
        setActiveTab(Object?.keys(response?.data?.result)?.[0]);
      }
    }
  );

  const {
    data: videoInstructionLink,
    isLoading: loadingVideoInstructionLink,
    isSuccess: successVideoInstructionLink
  } = useQuery(
    'fetchVideoInstructionLink',
    () =>
      apiRequest({
        command: 'get_video_instruction_link'
      }),
    {
      onSuccess: (response) => {}
    }
  );

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  const addresses = isSuccess && getAdresses.data.result;
  // console.log(addresses);

  return (
    <Wrapper>
      <div className='usa2-address-container'>
        {isSuccess && addresses !== undefined && addresses !== false && Object.keys(addresses).length > 1 && (
          <ListButtonsNew countryList={Object?.keys(addresses)} activeTab={activeTab} setActiveTab={setActiveTab} />
        )}
        {isSuccess && addresses && (
          <div className='mt-2'>
            <div>
              {addresses?.[activeTab]?.map((item, index) => (
                <Item key={index} item={item} />
              ))}
            </div>
            {activeTab === 'TURKEY' && (
              <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center', gap: 5 }}>
                {/* <div className="blob orange"></div> */}
                <a href={videoInstructionLink.data.result} style={{ color: '#f68424', fontSize: 14 }} target='_blank' rel='noreferrer'>
                  {t('_LINK_TO_INSTRUCTIONS_TITLE')}
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default Address;
