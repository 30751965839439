import { useState, useCallback, useEffect } from 'react';


export default function Resolution() {
  const [resolution, setResolution] = useState(window.innerWidth);

  const reportWindowSize = useCallback(() => {
    setResolution(window.innerWidth);
  }, []);
  
  useEffect(() => {
    window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, [window.innerWidth]);

  return resolution;
}
