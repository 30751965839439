// hooks
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { serialize } from 'object-to-formdata';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// components;
import TicketsList from './components/slider-steps/TicketsList';
import SingleTicket from './components/slider-steps/SingleTicket';
import CreateTicket from './components/slider-steps/CreateTicket';
import ReplyTicket from './components/slider-steps/ReplyTicket';
import { freshDeskRequest } from '../../../../components/freshDeskRequest';
import GlobalContext from '../../../../components/GlobalContext';

import './components/Ticket.scss';
import Button from '../../../../components/Button';
import apiRequest from '../../../../components/ApiRequest';

SwiperCore.use([Pagination]);

const Tickets = ({ setOpenSettings }) => {
  const { t } = useTranslation();
  const { setAlert, token, setShowLoader } = useContext(GlobalContext);
  const [activeStep, setActiveStep] = useState(0);
  const [singleTicket, setSingleTicket] = useState([]);
  const [slideTitle, setSlideTitle] = useState('_title_tickets');
  const [swiper, setSwiper] = useState(null);
  const [replyText, setReplyText] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [ticketList, setTicketList] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState({
    label: '_LOW',
    value: 1
  });

  const { data: ticketsData, isLoading } = useQuery('getTickets', () =>
    freshDeskRequest({
      command: 'tickets',
      params: {
        email: userData && userData.data.result.profile.email
      }
    })
  );

  useEffect(() => {
    // console.log('Tickets.js - get_customer_info');
  }, []);

  useEffect(() => {
    if (ticketsData && ticketsData.length > 0) {
      setTicketList(ticketsData.sort(function (a, b) { return new Date(b.updated_at) - new Date(a.updated_at); }));
    }
    console.log(ticketsData);
  }, [ticketsData]);
  const { data: userData } = useQuery('userInfo', () =>
    apiRequest({
      command: 'get_customer_info',
      sessionId: token
    })
  );

  const createTicket = useMutation(
    () =>
      freshDeskRequest({
        method: 'POST',
        command: 'tickets',
        data: serialize({
          email: userData && userData.data.result.profile.email,
          subject,
          description,
          priority: selectedPriority.value,
          status: 2,
          attachments
        })
      }),
    {
      onSuccess: () => {
        setAlert(['success', t('_ticketAdded')]);
        setOpenSettings(false);
        setAttachments([]);
      },
      onError: () => {
        setAlert(['error', t('_key_error_occured')]);
      }
    }
  );

  const replyTicket = useMutation(
    () =>
      freshDeskRequest({
        method: 'POST',
        command: `tickets/${singleTicket.id}/reply`,
        data: serialize({
          body: replyText,
          user_id: singleTicket.requester_id,
          attachments
        })
      }),
    {
      onSuccess: () => {
        setAlert(['success', t('_reply_added')]);
        decreaseStep();
        setAttachments([]);
      },
      onError: () => {
        setAlert(['error', t('_key_error_occured')]);
      }
    }
  );

  const stepToSingleTicket = (ticket) => {
    setSingleTicket(ticket);
    setSlideTitle(ticket.subject);
    setActiveStep(1);
  };

  useEffect(() => {
    if (activeStep === 0) {
      setSlideTitle('_title_tickets');
    }
  }, [activeStep]);

  useEffect(() => {
    if (swiper) swiper.slideTo(activeStep);
  }, [activeStep]);

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  const decreaseStep = () => {
    if (activeStep === 2) {
      setActiveStep(0);
    } else if (activeStep === 3) {
      setActiveStep(1);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handleNextStep = () => {
    switch (activeStep) {
      case 0:
        setActiveStep(2);
        break;
      case 1:
        setActiveStep(3);
        break;
      case 3:
        replyText.length > 0 && replyTicket.mutate();
        break;
      case 2:
        subject.length > 0 && description.length > 0 && createTicket.mutate();
        break;
      default:
    }
  };

  const handleBackStep = () => {
    switch (activeStep) {
      case 0:
        setOpenSettings(false);
        break;
      case 2:
        setActiveStep(0);
        break;
      case 3:
        setActiveStep(1);
        break;
      case 1:
        setActiveStep(0);
        break;
      default:
    }
  };

  return (
    <div className="ticket-container d-flex flex-column justify-content-between sideBarChildComponent">
      <div className="d-flex flex-column justify-content-between height-calculated">
        <h1 className="font-size-16 mb-5 bold">{t(slideTitle)}</h1>
        <div
          style={{ height: '80%', marginBottom: 'auto' }}
          className="d-flex flex-column justify-content-between"
        >
          <div className="overflow-scroll">
            <div className="pr-60 height-100">
              <Swiper
                onSwiper={setSwiper}
                allowTouchMove={false}
                slidesPerView={1}
                spaceBetween={20}
                className="mySwiper"
              >
                <SwiperSlide>
                  {activeStep === 0 && (
                    <TicketsList
                      ticketsData={ticketsData}
                      stepToSingleTicket={stepToSingleTicket}
                    />
                  )}
                </SwiperSlide>
                {activeStep === 1 && (
                  <SwiperSlide>
                    <SingleTicket singleTicket={singleTicket} />
                  </SwiperSlide>
                )}
                <SwiperSlide>
                  <CreateTicket
                    subject={subject}
                    setSubject={setSubject}
                    description={description}
                    setDescription={setDescription}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    selectedPriority={selectedPriority}
                    setSelectedPriority={setSelectedPriority}
                  />
                </SwiperSlide>
                {activeStep === 3 && (
                  <SwiperSlide>
                    <ReplyTicket
                      replyText={replyText}
                      setReplyText={setReplyText}
                      singleTicket={singleTicket}
                      attachments={attachments}
                      setAttachments={setAttachments}
                    />
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebarButtonDiv">
        <Button
          isLoading={replyTicket.isLoading || createTicket.isLoading}
          handleClick={handleNextStep}
          type="button"
          btnStyle="primary-btn-w-h bg-orange"
          text={
            activeStep === 0 || activeStep === 2
              ? t('_create_ticket')
              : t('_reply')
          }
          color="color-white"
        />
        <Button
          handleClick={handleBackStep}
          btnStyle="primary-btn-w-h mt-3 w-100 btn-gray-bg"
          text={t('_RETURN_BACK')}
          color="color-orange"
        />
      </div>
    </div>
  );
};

export default Tickets;
