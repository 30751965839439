import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DocIcon, PlusIcon } from '../../assets/icons';
import apiRequest from '../ApiRequest';
import GlobalContext from '../GlobalContext';

function UploadDocument({ num, setLoading, uploadData, setUploadData }) {
  const [uploaded, setUploaded] = useState(false);
  const { token, setAlert } = useContext(GlobalContext);
  const { t } = useTranslation();

  const getBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  return (
    <div
      style={{ border: '1px solid #e0dedd', borderRadius: 8, width: 100, height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
      onClick={() => {
        !uploaded && document.getElementById(`upload_file_${num}`).click();
      }}
    >
        <div
          className='iconDiv'
        >
          {uploaded ? <DocIcon /> : <PlusIcon />}
        </div>
      <input
        type='file'
        id={`upload_file_${num}`}
        className='uploadInput  d-none'
        accept='.jpg, .jpeg, .png, .pdf'
        onChange={(e) => {
          setLoading(true);
          // console.log(e.target.files);
          getBase64(e.target.files[0], (base64) => {
            apiRequest(
              {
                command: 'upload_customer_documents',
                sessionId: token,
                method: 'POST'
              },
              {
                base64: base64?.split(',')?.pop(),
                extention: '',
                fileName: e.target?.files[0]?.name
              }
            ).then(({ data }) => {
              setLoading(false);
              if (data.status === 'OK') {
                setUploaded(true);
                num === 'front' ? setUploadData({ ...uploadData, front: true }) : setUploadData({ ...uploadData, back: true });
              } else {
                setAlert(['error', t(data.message)]);
              }
            });
          });
        }}
      />
    </div>
  );
}

export default UploadDocument;
