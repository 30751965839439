import * as Yup from 'yup';

export default [
  Yup.object().shape({
    organizationName: Yup.string().required('_PARAMETER_IS_REQUEIRED'),
    identification: Yup.string()
      .required('_PARAMETER_IS_REQUEIRED')
      .matches(/^[0-9]+$/, 'დასაშვებია მხოლოდ რიცხვები')
      .min(9, '_ERR_INVLID_IDENT_NO')
      .max(9, '_ERR_INVLID_IDENT_NO')
  }),
  Yup.object().shape({
    firstNameGe: Yup.string()
      .min(2, '_ERR_FIRSTNAMEGE_MIN_LENGTH')
      .required('_PARAMETER_IS_REQUEIRED')
      .matches(/^[ა-ჰ]+$/, '_ERR_INVLID_FIRSTNAMEGE'),
    firstName: Yup.string()
      .required('_PARAMETER_IS_REQUEIRED')
      .min(2, '_ERR_FIRSTNAMEGE_MIN_LENGTH')
      .matches(/^[A-Za-z]+$/, '_ERR_INVLID_FIRSTNAME'),
    lastNameGe: Yup.string()
      .min(2, '_ERR_LASTNAMEGE_MIN_LENGTH')
      .required('_PARAMETER_IS_REQUEIRED')
      .matches(/^[ა-ჰ]+$/, '_ERR_INVLID_LASTNAMEGE'),
    lastName: Yup.string()
      .required('_PARAMETER_IS_REQUEIRED')
      .min(2, '_ERR_LASTNAMEGE_MIN_LENGTH')
      .matches(/^[A-Za-z]+$/, '_ERR_INVLID_LASTNAME')
  }),
  Yup.object().shape({
    email: Yup.string()
      .email('_ERR_EMAIL_REQUIRED email')
      .required('_PARAMETER_IS_REQUEIRED'),
    mobile: Yup.string()
      .min(9, '_ERR_PHONE_LENGTH')
      .max(9, '_ERR_PHONE_LENGTH')
      .required('_PARAMETER_IS_REQUEIRED')
      .matches(/^[0-9]+$/, 'დასაშვებია მხოლოდ რიცხვები')
  }),
  Yup.object().shape({
    otp: Yup.string()
  }),
  Yup.object().shape({
    password: Yup.string().required('_PARAMETER_IS_REQUEIRED'),
    retryPassword: Yup.string().required('_PARAMETER_IS_REQUEIRED')
  })
];
