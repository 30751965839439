// hooks
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// assets
import { CopyIcon } from '../../assets/icons';

// components
import SmallIcon from '../../components/SmallIcon';

const Item = ({ item }) => {
  const [copyStatus, setCopyStatus] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => setCopyStatus(false), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [copyStatus]);

  return (
    <div className='bg-white mb-1 d-flex justify-content-between align-items-center usa2-address-div'>
      <div>
        <p className='font-size-14 color-secondary-grey'>{item.key}</p>
        <p className='font-size-14 color-third-grey' dangerouslySetInnerHTML={{ __html: item.value }}></p>
      </div>
      <OverlayTrigger trigger='click' placement='top' overlay={copyStatus ? <Tooltip id='tooltip-top'>{t('_key_copied')}</Tooltip> : <div />}>
        <span
          onClick={() => {
            navigator.clipboard.writeText(item.copy);
            setCopyStatus(true);
          }}
          className='cursor-pointer position-relative'
        >
          <SmallIcon img={<CopyIcon />} bg='icon-gray-bg' />
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default Item;
