import React from 'react';
import './Toggle.scss';

const Toggle = ({
  customClass,
  text,
  fontStyle,
  checked,
  value,
  name,
  ...props
}) => {
  return (
    <div
      className={`d-flex py-3 justify-content-between align-items-center flex-row ${
        customClass ? `${customClass} customColumnToggle` : ''
      }`}
    >
      <p className={`font-size-14 ${fontStyle}`}>{text}</p>
      <label
        className={`toggle-switch ${
          customClass === 'flex-column' && 'align-self-end me-2'
        }`}
      >
        <input
          type="checkbox"
          value={value}
          name={name}
          checked={checked}
          {...props}
        />
        <span className="switch" />
      </label>
    </div>
  );
};

export default Toggle;
