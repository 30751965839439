// hooks
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import OtpInput from 'react-otp-input';
import Button from '../../../../../components/Button';

const CheckOtp = ({ sendOtp, mobile, OTP, setOTP }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-4">
      <p className="text-center">{t('_OTP_SENT')}</p>
      <p className="bold text-center">{mobile}</p>
      <div className="mt-3">
        <OtpInput
          inputStyle="otp-input"
          value={OTP}
          containerStyle="d-flex justify-content-between mt-3 mb-4"
          onChange={setOTP}
          OTPLength={4}
          otpType="number"
        />
      </div>
      <Button
        disabled={sendOtp.isLoading}
        text={t('_OTP_RESEND')}
        handleClick={sendOtp.mutate}
        btnStyle="d-flex cursor-pointer otp-send justify-content-center font-size-12"
      />
    </div>
  );
};

export default CheckOtp;
