import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Arrow, HelpIcon, LoaderIcon } from '../../../../assets/icons';
import apiRequest from '../../../../components/ApiRequest';
import GlobalContext from '../../../../components/GlobalContext';
import MenuItem from './MenuItem';
import './Helper.css';
import Button from '../../../../components/Button';

const Helper = () => {
  const { t } = useTranslation();
  const { helperTracking } = useContext(GlobalContext);
  const [menuItems, setMenuItems] = useState(null);
  const [parentId, setParentId] = useState(1);
  const [article, setArticle] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const addToBreadcrumbs = (breadcrumb) => {
    if (breadcrumbs === null) {
      setBreadcrumbs([breadcrumb]);
    } else {
      setBreadcrumbs([...breadcrumbs, breadcrumb]);
    }
  };

  const refreshBreadcrumbs = (breadcrumb) => {
    const currentIndex = breadcrumbs.findIndex((element) => element.id === breadcrumb.id);
    setBreadcrumbs(
      breadcrumbs.filter((element, index) => {
        return index <= currentIndex;
      })
    );
  };

  useEffect(() => {
    setMenuItems(null);
    apiRequest(
      {
        command: 'hc_items',
        sessionId: localStorage.getItem('token')
      },
      {
        parentItems: parentId,
        tracking: helperTracking
      }
    ).then((response) => {
      setMenuItems(response.data.result?.items);
      setArticle(response.data.result?.answer);
    });

    console.log(parentId, helperTracking);
    console.log(breadcrumbs);

    return () => {};
  }, [parentId, helperTracking]);

  return (
    <div className='hc-container d-flex flex-column'>
      <div className='mb-4 d-flex justify-content-start align-items-center' style={{ flex: '0 0 30px' }}>
        <HelpIcon /> <span style={{ marginLeft: 10 }}>{helperTracking}</span>
      </div>
      {parentId !== 1 && (
        <div className='d-flex mb-3 align-items-center flex-wrap' style={{ flex: '0 0 50px' }}>
          <div
            className='hc-breadcrumb'
            key={0}
            onClick={() => {
              setBreadcrumbs(null);
              setMenuItems(null);
              setParentId(1);
            }}
          >
            {t('_HOME')}
          </div>
          {breadcrumbs?.map((item, index) => {
            return (
              <div
                style={index === breadcrumbs.length - 1 ? { color: '#f68424', cursor: 'default' } : {}}
                className='hc-breadcrumb d-flex align-items-center'
                key={index}
                onClick={() => {
                  setParentId(item.id);
                  refreshBreadcrumbs(item);
                }}
              >
                {<Arrow />}
                {item.title}
              </div>
            );
          })}
        </div>
      )}
      {menuItems?.map((menuItem, index) => {
        return <MenuItem key={index} item={menuItem} setParentId={setParentId} addToBreadcrumbs={addToBreadcrumbs} />;
      })}
      {article && (
        <>
          <div style={{ overflow: 'auto', height: 'calc(100vh - 250px)' }} dangerouslySetInnerHTML={{ __html: article }}></div>
          <div className='d-flex justify-content-center align-items-center' style={{ flex: '0 0 70px' }}>
            <Button btnStyle='bg-orange px-3 py-2 min-width' text={'ვერ მიიღე პასუხი?'} color='color-white' btnColor={'#F68220'} />
          </div>
        </>
      )}
    </div>
  );
};

export default Helper;
