// hooks
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';

// assets
import { MailIcon } from '../../../assets/icons';

// config
import { ResetPasswordSchema } from '../../../components/validations/ResetPasswordSchema';

// components
import Button from '../../../components/Button';
import Input from '../../../components/input/Input';
import apiRequest from '../../../components/ApiRequest';
import GlobalContext from '../../../components/GlobalContext';

import './ForgotPassword.css';

export default function ForgotPassword({ setOpenSidebar }) {
  const { t } = useTranslation();
  const { token, setAlert } = useContext(GlobalContext);
  const inputRef = useRef(null);

  const { values, handleChange, handleBlur, isValid, touched, errors } = useFormik({
    initialValues: {
      email: ''
    },
    enableReinitialize: true,
    validationSchema: ResetPasswordSchema
  });

  const resetPassword = useMutation(
    () =>
      apiRequest(
        {
          command: 'reset_password',
          sessionId: token
        },
        {
          email: values.email
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          setOpenSidebar(false);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      }
    }
  );

  useEffect(() => {
    const timer = setTimeout(() => inputRef.current?.focus(), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword.mutate();
  };

  return (
    <div className='d-flex flex-column height-100 justify-content-between sideBarChildComponent'>
      <h5 className='font-size-18 mb-4'>{t('_key_reset_password')}</h5>
      <div className='sidebar-child-scroll'>
        <div className='pr-60'>
          <div className='mt-5 w-full pt-5'>
            <p className='font-size-14 mb-4'>{t('_TYPE_YOUR_EMAIL')}</p>
            <Input
              ref={inputRef}
              type='email'
              customStyle='gray-border'
              label={t('_EMAIL')}
              // icon={<MailIcon />}
              name='email'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && errors.email ? t(errors.email) : ''}
            />
          </div>
        </div>
      </div>
      <div className='sidebarButtonDiv'>
        <Button
          isLoading={resetPassword.isLoading}
          handleClick={isValid && handleSubmit}
          btnStyle='bg-orange primary-btn-w-h'
          text={t('_key_reset_password')}
          color='color-white'
        />
      </div>
    </div>

    // <div style={{ backgroundColor: '#666', color: 'white', height: '100%', display: 'block', position: 'fixed', top: 0, left: 0, bottom: 0, right: 0 }}>
    //   <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'space-between', height: '100%', position: 'relative' }}>
    //     <div>1111111111111111111111</div>
    //     <div>222222222222222222222222222</div>
    //   </div>
    // </div>
  );
}
