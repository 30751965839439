// hooks
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';

// styles
import './Sidebar.scss';

// assets
import { CloseModalIcon, WhiteArrowIcon } from '../../assets/icons';
import GlobalContext from '../GlobalContext';

function SideBar({ activeStep, openSidebar, setOpenSidebar, children, activeForm, setActiveForm, setActiveStep, backIcon, style }, ref) {
  useImperativeHandle(ref, () => ({
    open: () => {
      setOpenSidebar(true);
    },
    close: () => {
      setOpenSidebar(false);
    }
  }));
  const [close, setClose] = useState(false);

  // const { setIsSidebarOpened } = useContext(GlobalContext);

  // useEffect(() => {
  //   if (openSidebar) {
  //     setIsSidebarOpened(true);
  //   } else {
  //     setIsSidebarOpened(false);
  //   }
  // }, [openSidebar]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        closeSidebar();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (openSidebar) {
      setClose(false);
    }
  }, [openSidebar]);

  function closeSidebar() {
    if (activeForm && activeForm !== 'registration') {
      setActiveForm('registration');
      setActiveStep(0);
      setOpenSidebar(false);
    } else if (activeStep && activeStep > 0) {
      setOpenSidebar(false);
      setActiveStep(0);
    } else {
      setClose(true);
    }
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      if (close) {
        setOpenSidebar(false);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [close]);

  if (!openSidebar) {
    return null;
  }

  return (
    <div className='overlay'>
      <div onClick={closeSidebar} className={`empty-area responsive-hidden empty-area-${close ? 'false' : openSidebar}`} />
      <div style={style} className={`sidebar sidebar-${close ? 'false' : openSidebar}`}>
        <div onClick={closeSidebar} style={{ zIndex: 11 }} className='sidebar-close-btn-responsive close-modal-btn cursor-pointer d-flex justify-content-center align-items-center'>
          {backIcon ? <WhiteArrowIcon className='rotate180' style={{ marginRight: '3px' }} /> : <CloseModalIcon className='closeIcn' />}
        </div>
        <div className='SidebarChildDiv'>
          <div className='test'>
            <div
              style={{
                height: '100%',
                display: 'block'
              }}
            >
              {openSidebar && children}
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div style={{ backgroundColor: 'white', height: '100vh', width: '100%', position: 'fixed', top: 0, left: 0, bottom: 0, zIndex: 1000, border: '10px solid green' }}>{openSidebar && children}</div>
  );
}

export default forwardRef(SideBar);
