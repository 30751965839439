import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { SuccessGreenIcon } from '../../assets/icons';
import apiRequest from '../../components/ApiRequest';
import GlobalContext from '../../components/GlobalContext';
import Loader from '../../components/loader/Loader';

export const UnsubscribePage = () => {
  const { t } = useTranslation();
  const { hash } = useParams();
  const [loading, setLoading] = useState(true);
  const { setShowLoader } = useContext(GlobalContext);

  useEffect(() => {
    setShowLoader(true);
    apiRequest(
      {
        command: 'unsubscribe'
      },
      { hash: hash }
    ).then((response) => {
      setShowLoader(false);
      setLoading(false);
      console.log(response);
    });
  }, []);

  return (
    <>
      <Loader />
      {!loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 50
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: 700,
              gap: 20,
              textAlign: 'center',
              paddingLeft: 20,
              paddingRight: 20
            }}
          >
            <SuccessGreenIcon width={70} height={70} />
            {t('_UNSUBSCRIBE_SUCCESSFUL_TEXT')}
          </div>
        </div>
      )}
    </>
  );
};
