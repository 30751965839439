import React from 'react';
import { LoaderIcon } from '../assets/icons';

export default function Button({
  icon,
  icon2,
  color,
  btnStyle,
  btnColor,
  text,
  type,
  handleClick,
  isLoading,
  disabled
}) {
  return (
    <button
      disabled={disabled || isLoading}
      onClick={handleClick}
      type={!type ? 'button' : type}
      className={`button ${btnStyle} ${disabled ? 'btn-disabled' : ''}`}
      style={{ backgroundColor: btnColor }}
    >
      {isLoading ? (
        <LoaderIcon className="SpinnerLoading" />
      ) : icon && icon2 ? (
        <div className="d-flex px-3 justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            {icon}
            <p className={`auth-p font-size-14 ${color}`}>{text}</p>
          </div>
          <div>{icon2}</div>
        </div>
      ) : icon ? (
        <div className="d-flex align-items-center justify-content-center">
          {icon}
          <p className={`auth-p font-size-14 ms-2 ${color}`}>{text}</p>
        </div>
      ) : (
        <p className={`auth-p font-size-14 ${color}`}>{text}</p>
      )}
    </button>
  );
}
