// hooks
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// assets
import { Arrow, BranchesLocationIcon } from '../../assets/icons';
import csoonKa from '../../assets/images/csoon_ka.jpg';
import csoonEn from '../../assets/images/csoon_en.jpg';

// components
import OrangeLine from '../../components/OrangeLine';
import Contact from '../../components/Contact';
import Button from '../../components/Button';
import apiRequest from '../../components/ApiRequest';
import Search from '../../components/Search';
import GlobalContext from '../../components/GlobalContext';
import NotFound from '../../components/NotFound';
import Wrapper from '../../components/Wrapper';
import { Col } from 'react-bootstrap';
import { branches } from '../../data/branches';

const Branches = () => {
  const { lang } = useParams();
  const { t } = useTranslation();
  const { setShowLoader } = useContext(GlobalContext);
  const [branchesData, setBranchesData] = useState(branches);
  const initialBranches = useRef([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (['ka', 'en'].includes(lang)) {
      i18n.changeLanguage(lang);
    } else {
      // const notValidLanguage = window.location.href.split('/').pop();
      // window.history.replaceState(null, '', window.location.href.replace('/' + notValidLanguage, ''));
    }
    setBranchesData(branches);
    initialBranches.current = branches;
  }, []);

  // console.log(lang);

  useEffect(() => {
    if (search.length > 0) {
      const filtered = branchesData
        .map((item) => {
          item._search = t(item.address);
          return item;
        })
        .filter((item) => {
          let matches = false;
          for (const key in item) {
            if (String(item[key]).toLowerCase().indexOf(search.toLowerCase()) > -1) {
              matches = true;
              break;
            }
          }
          return matches;
        });
      setBranchesData(filtered);
    } else {
      setBranchesData(initialBranches.current);
    }
  }, [search]);

  return (
    <Wrapper>
      <div className='branches-title-text'>
        <div className='pt-5'>
          <OrangeLine />
        </div>
        <h1> {t('_BRANCH_SETTINGS')}</h1>
        <p className='my-4 color-gray'>{t('_CONTACT_US_FOR_MORE_INFORMATION')}</p>
      </div>
      <Contact />
      <div className='mt-5 pt-3'>
        {/* <div>
          <ul className='navbar-div branches-title-text'>
            <li className='navbar-li'>
              <p className='navbar-li-active branches cursor-pointer font-size-14'>{t('_BRANCH_SETTINGS')}</p>
              <OrangeLine />
            </li>
          </ul>
        </div> */}
        <div className='branches-page-container'>
          <div className='mb-4'>
            <Search placeholder={t('_key_search_street')} value={search} onChange={(e) => setSearch(e.target.value)} />
          </div>

          {branchesData && branchesData.length > 0 ? (
            <div className='branch-row'>
              {/* <Col key={1000} lg={12} xs={12} className=''>
                <div className='branches-slider mx-2 position-relative mb-4'>
                  <h4 className='font-size-16 mb-3 pb-1'>{t('_BAGEBI')}</h4>
                  <img style={{ width: '100%', height: 170, objectFit: 'scale-down' }} src={i18n.language === 'ka' ? csoonKa : csoonEn} alt='Comming soon' />
                </div>
              </Col> */}
              {branchesData.map((branch, index) => (
                <Col key={index} lg={12} xs={12}>
                  <div className='branches-slider mx-2 position-relative mb-4'>
                    {branch.isNew && (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          backgroundColor: '#f68424',
                          color: 'white',
                          padding: '4px 20px',
                          fontSize: '11px',
                          borderRadius: '0 6px 0 6px'
                        }}
                      >
                        {t('_NEW')}
                      </div>
                    )}
                    <h4 className='font-size-16 mb-4 pb-1'>{t(branch.address)}</h4>
                    {branch.id === 'OFFICE_100' && <>{t('_COMMING_SOON')}</>}
                    {branch.working_hour.ka.map((item, index) => (
                      <p key={index} className='font-size-14 offer-p-slider-color mb-2'>
                        <span className='font-bold'>{t(item.key)}: </span>
                        {item?.value}
                      </p>
                    ))}
                    <a className='w-100 position-absolute mt-4 link' target='_blank' href={branch.map_link} rel='noreferrer'>
                      <Button
                        color='auth-p ms-4 view-map-p'
                        text={t('_SHOW_ON_MAP')}
                        btnStyle='secondary-btn-w-h svg-white-fill-hover swiper-btns-bg'
                        icon={<BranchesLocationIcon />}
                        icon2={<Arrow />}
                      />
                    </a>
                  </div>
                </Col>
              ))}
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </Wrapper>
  );
};
export default Branches;
