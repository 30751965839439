import React from 'react';
import { useTranslation } from 'react-i18next';

function PriceCalculatorNewCountry({ item, index, country, setCountry, clearErrors }) {
  const { t } = useTranslation();
  console.log(item);
  return (
    <div
      style={{
        backgroundColor: item.country === country ? 'rgba(246, 132, 36, 0.8)' : '#fff',
        width: '100%',
        borderRadius: index === 0 ? '6px 0 0 0' : index === 2 ? '0 6px 0 0' : '0',
        cursor: item.country !== country ? 'pointer' : ''
      }}
      onClick={() => {
        setCountry(item.country);
        clearErrors();
      }}
    >
      <div style={{ padding: 10, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 7 }}>
        <img style={{ width: '40px', userSelect: 'none' }} src={item.icon} alt={item.country} />
        <div style={{ color: item.country === country ? '#fff' : '#5f5f5f', fontFamily: 'HelveticaBoldCaps' }}>{t('_' + item.country.toUpperCase() + '_UPPERCASE')}</div>
      </div>
    </div>
  );
}

export default PriceCalculatorNewCountry;
