// hooks
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { ShareIcon } from '../../assets/icons';

// components
import SideBar from '../../components/sidebar/SideBar';
import SmallIcon from '../../components/SmallIcon';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const SidebarItem = ({ i, showChild }) => {
  const { t } = useTranslation();
  const [openFAQ, setOpenFAQ] = useState(false);
  const [copyStatus, setCopyStatus] = useState(false);
  const faqRef = useRef();

  useEffect(() => {
    const timer = setTimeout(() => setCopyStatus(false), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [copyStatus]);

  return (
    <div className="w-full">
      <div
        onClick={() => faqRef.current.open()}
        className={`question-subdiv mt-3 mb-3 ${showChild ? 'show' : 'hidden'}`}
      >
        <div className="font-size-14" dangerouslySetInnerHTML={{ __html: i.question }} />
      </div>
      <SideBar openSidebar={openFAQ} setOpenSidebar={setOpenFAQ} ref={faqRef}>
        <h1 className="font-size-16 color-black bold mb-5">{i.question}</h1>
        <div className="sidebar-child-scroll-faq">
          <div className="pr-60 cursor-pointer">
            <div
              className="color-third-grey font-size-14"
              dangerouslySetInnerHTML={{ __html: i.answer }}
            />
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={
                copyStatus ? (
                  <Tooltip id="tooltip-top">{t('_key_copied')}</Tooltip>
                ) : (
                  <div />
                )
              }
            >
              <div
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.href}?question=${i.id}`
                  );
                  setCopyStatus(true);
                }}
                className="share-answer-container"
              >
                <p className="font-size-14">{t('_SHARE')}</p>
                <span className="cursor-pointer position-relative">
                  <SmallIcon
                    bg="icon-light-bg share-icon cursor-pointer"
                    img={<ShareIcon />}
                  />
                </span>
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </SideBar>
    </div>
  );
};

export default SidebarItem;
