// hooks
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

// components
import apiRequest from '../../components/ApiRequest';
import GlobalContext from '../../components/GlobalContext';
import Wrapper from '../../components/Wrapper';
import { freshDeskRequest } from '../../components/freshDeskRequest';
import { MailItem } from './MailItem';

const Mails = () => {
  const { t } = useTranslation();
  const { setAlert, token, setShowLoader } = useContext(GlobalContext);

  const { data: userData } = useQuery('userInfo', () =>
    apiRequest({
      command: 'get_customer_info',
      sessionId: token
    })
  );

  const { data: ticketsData, isLoading } = useQuery('getTickets', () =>
    freshDeskRequest({
      command: 'tickets',
      params: {
        email: userData && userData.data.result.profile.email
      }
    })
  );

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  return (
    <Wrapper>
      <div className="transactions-container">
        <h1 className="pt-5">{t('_title_tickets')}</h1>
        <div className="mt-5">
          {
            ticketsData?.map((item, index) => {
              return <div key={index}><MailItem item={item} /></div>;
            })
          }
        </div>
      </div>
    </Wrapper>
  );
};

export default Mails;
