import React from 'react';
import { useTranslation } from 'react-i18next';

const Conversation = ({ item, requesterId }) => {
  const { t } = useTranslation();

  return (
    <div className="ticket-details ticket-me">
      {item && (
        <div>
          <div className="ticket-child-div">
            <div className="d-flex justify-content-between">
              <p className="bold font-size-16 ">
                {requesterId !== item.user_id ? 'USA2GEORGIA' : t('you')}
              </p>
              <p className="color-third-grey font-size-14">{item.updated_at}</p>
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: item.body
            }}
          />
          {item.attachments.map((item, index) => (
            <img
              key={index}
              className="ticket-imgs"
              src={item.attachment_url}
              alt={item.name}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Conversation;
