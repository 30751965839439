// hooks
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// libraries
import * as dayjs from 'dayjs';

// assets
import { Arrow } from '../../assets/icons';
import TbcImg from '../../assets/images/tbc.svg';
import USA from '../../assets/images/trans_logo.png';

// components
import SideBar from '../../components/sidebar/SideBar';
import { Details } from './Details';
import i18n from 'i18next';

export function Item({ item }) {
  const { t } = useTranslation();
  const transactionRef = useRef();
  const currentLang = i18n.languages[0];
  const [openTransaction, setOpenTransaction] = useState(false);

  return (
    <div>
      <div
        onClick={() => transactionRef.current.open()}
        className="flights-container transactions-div"
      >
        <div className="d-flex justify-content-between w-full align-items-center">
          <div className="d-flex flex-row">
            <div className="d-flex align-items-center justify-content-center me-4">
              <div className="fill-balance-bank-icon d-flex align-items-center">
                <img
                  src={item.initiator === 'TBC_BANK' ? TbcImg : USA}
                  alt={item.initiator}
                />
              </div>
            </div>
            <div className="d-flex font-light flex-column">
              <p className="font-size-14 third-grey mb-2">
                {dayjs
                  .unix(item.date)
                  .locale(currentLang)
                  .format('DD MMMM YYYY')}
              </p>
              <p
                className={`font-size-14 ${item.type === 'INVOICE_PAYMENT'
                  ? 'color-red'
                  : 'color-green'
                  }`}
              >
                {t(`_${item.type}`)}
              </p>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <p className="color-secondary-grey font-size-14">
              {item.net.toFixed(2)} ₾
            </p>
            <span className="ms-4">
              <Arrow />
            </span>
          </div>
        </div>
      </div>
      <SideBar
        openSidebar={openTransaction}
        setOpenSidebar={setOpenTransaction}
        ref={transactionRef}
      >
        <Details item={item} />
      </SideBar>
    </div>
  );
}
