// hooks
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import Input from '../../../../../../components/input/Input';
import Radio from '../../../../../../components/radio/Radio';
import SmallIcon from '../../../../../../components/SmallIcon';
import AttachFile from '../../../../../../components/attachFile/AttachFile';
import { AttachFileIcon, CrossIcon } from '../../../../../../assets/icons';

const CreateTicket = ({
  subject,
  setSubject,
  description,
  setDescription,
  attachments,
  setAttachments,
  selectedPriority,
  setSelectedPriority
}) => {
  const { t } = useTranslation();

  const priorityList = [
    { label: '_LOW', value: 1 },
    { label: '_MEDIUM', value: 2 },
    { label: '_HIGH', value: 3 },
    { label: '_URGENT', value: 4 }
  ];

  const handleImageChange = (event) => {
    setAttachments((items) => [...items, event.target?.files?.[0] ?? null]);
  };

  const handleDeleteFile = (name) => {
    setAttachments(attachments.filter((item) => item.name !== name));
  };

  return (
    <div className="mt-4">
      <div>
        <Input
          label={t('_TEXT')}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <Input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          customStyle="ticket-input"
          placeholder={t('_TEXT')}
          multiline
        />
      </div>
      <div>
        <h4 className="font-size-16 mb-0 bold">{t('_perority')}</h4>
        <div className="d-flex cursor-pointer align-items-center flex-wrap">
          {priorityList.map((item, index) => (
            <div
              key={index}
              onClick={() => setSelectedPriority(item)}
              className={`new-ticket-priority new-ticket-priority-${
                item.label === selectedPriority.label
              }`}
            >
              <Radio
                value={selectedPriority.label}
                onChange={() => setSelectedPriority(item)}
                selected={selectedPriority.label === item.label}
              />
              <p className="font-size-14">{t(item.label)}</p>
            </div>
          ))}
        </div>
        {attachments.length > 0 &&
          attachments.map((item, index) => (
            <div
              onClick={() => handleDeleteFile(item.name)}
              key={index}
              className="ticket-file d-flex justify-content-between align-items-center"
            >
              <p className="font-size-14 color-third-grey">{item.name}</p>
              <SmallIcon
                containerStyle="icon-light-bg ticket-delete-item cursor-pointer"
                img={<CrossIcon />}
              />
            </div>
          ))}
        <div className="ticket-file d-flex justify-content-between align-items-center">
          <p className="font-size-14 color-third-grey">{t('_attachement')}</p>
          <AttachFile
            accept=".jpg, .jpeg, .png"
            onChange={handleImageChange}
            icon={
              <SmallIcon
                containerStyle="icon-light-bg cursor-pointer"
                img={<AttachFileIcon />}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CreateTicket;
