import React from 'react';
import * as dayjs from 'dayjs';
import { DocIcon, DownloadIcon } from '../../assets/icons';
import SmallIcon from '../../components/SmallIcon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { InvoiceLink } from './InvoiceLink';

export function Details({ item }) {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column height-100 justify-content-between sideBarChildComponent">
      {item && (
        <div>
          <h1 className="font-size-16 bold mb-12">
            {t('_key_transaction_details')}
          </h1>
          <div className="sidebar-child-scroll">
            <div className="pr-60">
              <div className="mt-5">
                <p className="color-third-grey font-size-14">{t('_DATE')}</p>
                <p className="mb-4 mt-1 color-secondary-grey font-size-14 bold">
                  {dayjs.unix(item.date).format('DD MMMM YYYY')}
                </p>
                <p className="color-third-grey font-size-14">{t('_TYPE')}</p>
                <p className="mb-4 mt-1 color-secondary-grey font-size-14 bold">
                  {t(`_${item.type}`)}
                </p>
                {(item.type === 'INVOICE_PAYMENT' || item.type === 'EXPRESS_COURIER_SERVICE_CHARGE') && (
                  <div>
                    <p className="color-third-grey font-size-14">
                      {t('_INVOICE')}
                    </p>
                    <InvoiceLink item={item} />
                  </div>
                )}

                <p className="mt-4 color-third-grey font-size-14">
                  {t('_AMOUNT')}
                </p>
                <p className="mb-4 mt-1 color-secondary-grey font-size-14 bold">
                  {item.net.toFixed(2)} ₾
                </p>
                <p className="color-third-grey font-size-14">
                  {t('_UNUSED_CREDITS')}
                </p>
                <p className="mb-4 mt-1 color-secondary-grey font-size-14 bold">
                  {item.balance.toFixed(2)} ₾
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
