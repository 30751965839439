import React from 'react';

export default function SmallIcon({
  containerStyle,
  bg,
  src,
  alt,
  imgStyle,
  img,
  handleClick
}) {
  return (
    <div
      onClick={handleClick}
      className={`small-icon-div d-flex justify-content-center align-items-center ${bg} ${containerStyle || ''
        }`}
    >
      {src ? (
        <img src={src} alt={alt} />
      ) : (
        <div
          className={` d-flex justify-content-center align-items-center ${imgStyle || ''
            }`}
        >
          {img}
        </div>
      )}
    </div>
  );
}
