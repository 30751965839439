// hooks
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

// components
import TicketStatus from '../TicketStatus';
import { freshDeskRequest } from '../../../../../../components/freshDeskRequest';
import Conversation from '../Conversation';
import GlobalContext from '../../../../../../components/GlobalContext';

const SingleTicket = ({ singleTicket }) => {
  const { setShowLoader } = useContext(GlobalContext);
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  const [_conversations, setConversations] = useState([]);

  const {
    data: details,
    isLoading: detailsLoading,
    isSuccess: detailsIsSuccess
  } = useQuery(`getDetails${singleTicket.id}`, () =>
    freshDeskRequest({
      command: `tickets/${singleTicket.id}`
    })
  );

  const {
    data: conversations,
    isSuccess: conversationsIsSuccess,
    isLoading: conversationsIsLoading
  } = useQuery(`getConversations${singleTicket.id}`, () =>
    freshDeskRequest({
      command: `tickets/${singleTicket.id}/conversations`
    })
  );

  useEffect(() => {
    setShowLoader(detailsLoading || conversationsIsLoading);
  }, [detailsLoading, conversationsIsLoading]);

  useEffect(() => {
    if (conversations && conversations.length > 0) {
      setConversations(conversations.reverse());
    }
  }, [conversations]);

  return (
    <div
      ref={scrollRef}
      className="d-flex flex-column height-100 justify-content-between sideBarChildComponent"
    >
      {conversationsIsSuccess &&
        _conversations &&
        _conversations.map((item, index) => (
          !item?.private &&
          <Conversation
            key={index}
            requesterId={details && details.requester_id}
            item={item}
          />
        ))}
      <div className="d-flex mb-4 align-items-center">
        <p className="me-2 bold">{`#${singleTicket.id}`}</p>
        <TicketStatus status={singleTicket.status} />
      </div>
      {detailsIsSuccess && (
        <div className="ticket-details ticket-me">
          <div className="ticket-child-div">
            <div className="d-flex justify-content-between">
              <p className="bold font-size-16 ">{t('_you')}</p>
              <p className="color-third-grey font-size-14">
                {details.created_at}
              </p>
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: details.description
            }}
          />
        </div>
      )}

      <div className="d-flex justify-content-center">
        {details &&
          details.attachments.map((item, index) => (
            <img
              className="ticket-imgs"
              key={index}
              src={item.attachment_url}
              alt={item.name}
            />
          ))}
      </div>
    </div>
  );
};

export default SingleTicket;
