// hooks
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

// assets
import TerminalImg from '../../assets/images/terminal.svg';
import BankImg from '../../assets/images/bank-icon.svg';
import BogImg from '../../assets/images/bog.svg';
import TbcImg from '../../assets/images/tbc.svg';
import { CreditCardIcon, MasterCardIcon, VisaIcon } from '../../assets/icons';

// components
import SmallIcon from '../../components/SmallIcon';
import Input from '../../components/input/Input';
import Button from '../../components/Button';
import ChangablePackageDetails from '../../components/ChangablePackageDetails';
import Radio from '../../components/radio/Radio';
import apiRequest from '../../components/ApiRequest';
import GlobalContext from '../../components/GlobalContext';

const FillBalance = ({ priceAmount }) => {
  const { token, setAlert } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [selectedBank, setSelectedBank] = useState('save-card');
  const [amount, setAmount] = useState(null);
  const [selectedCard, setSelectedCard] = useState('new');

  function getQueryVariable(variable, query) {
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  useEffect(() => {
    if (priceAmount) {
      setAmount(priceAmount);
    }
  }, []);

  const handleIpay = useMutation(
    () =>
      apiRequest(
        {
          command:
            selectedCard !== 'new'
              ? 'pay_with_saved_card'
              : 'pay_with_new_card',
          sessionId: token
        },
        { amount, cardId: Number(selectedCard) }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          const orderId = getQueryVariable('order_id', response.data.result.split('?')[1]);
          localStorage.setItem('order_id', orderId);
          window.location.href = response.data.result;
          // window.open(response.data.result);
        }
      },
      onError: () => {
        setAlert(t('_key_error_occured'));
      }
    }
  );

  function openPostPage(url, data) {
    const form = document.createElement('form');
    document.body.appendChild(form);
    form.method = 'post';
    form.action = url;
    for (const name in data) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.value = data[name];
      form.appendChild(input);
    }
    form.submit();
    document.body.removeChild(form);
  }

  const handleTbc = useMutation(
    () =>
      apiRequest(
        {
          command: 'generate_tbc_bank_transaction',
          sessionId: token
        },
        { amount: amount * 100 }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          openPostPage('https://ecommerce.ufc.ge/ecomm2/ClientHandler', {
            trans_id: response.data.result
          });
        }
      },
      onError: () => {
        setAlert(t('_key_error_occured'));
      }
    }
  );

  const handleSubmit = () => {
    if (selectedBank === 'save-card') {
      handleIpay.mutate();
    } else {
      handleTbc.mutate();
    }
  };

  useEffect(() => {
    console.log('FillBalance.js - get_customer_info');
  }, []);

  const { data: getCards } = useQuery(
    'getCards',
    () =>
      apiRequest({
        command: 'get_customer_cards',
        sessionId: token
      })
  );

  return (
    <div className="d-flex justify-content-center fill-balance-container">
      <div className="w-full pr-60">
        <h1 className="font-size-16 bold mb-5">{t('_ADD_FUNDS')}</h1>
        <div className="d-flex justify-content-between">
          <div
            onClick={() => setSelectedBank('save-card')}
            className={`fill-balance-div cursor-pointer me-1 ${selectedBank === 'save-card' && 'balance-active'
              }`}
          >
            <div className="d-flex align-items-center">
              <div className="fill-balance-bank-icon">
                <SmallIcon src={BogImg} />
              </div>
              <p className="ms-2 font-size-12">BOGPAY</p>
            </div>
            <Radio
              value="tbc"
              selected={selectedBank === 'save-card'}
              onChange={() => setSelectedBank('save-card')}
            />
          </div>
          <div
            onClick={() => setSelectedBank('tbc')}
            className={`fill-balance-div cursor-pointer ${selectedBank === 'tbc' && 'balance-active'
              }`}
          >
            <div className="d-flex align-items-center">
              <div className="fill-balance-bank-icon">
                <SmallIcon src={TbcImg} />
              </div>
              <p className="ms-2 font-size-12">TBC</p>
            </div>
            <Radio
              value="tbc"
              selected={selectedBank === 'tbc'}
              onChange={() => setSelectedBank('tbc')}
            />
          </div>
        </div>
        <div className="my-4">
          {selectedBank === 'save-card' ? (
            <div
              dangerouslySetInnerHTML={{ __html: t('_IPAY_COMMISION_FEE') }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: t('_TBC_COMMISION_FEE') }}
            />
          )}
        </div>
        <div className="w-full mt-4 pt-3">
          <Input
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            label={t('_key_enter_amount')}
          />
          {
            selectedBank !== 'tbc' &&
            <ChangablePackageDetails
              onClickPassed={() => setSelectedCard('new')}
              containerStyle={selectedCard === 'new' && 'selectedDiv'}
              selectedProduct={selectedCard === 'new' && 'selectedDiv'}
              title={t('_CARD_CHOOSER_OTHER_CARD_')}
              icon={<CreditCardIcon />}
            />
          }
          {getCards && selectedBank !== 'tbc' &&
            getCards.data.result.map((item, index) => (
              <ChangablePackageDetails
                onClickPassed={() => setSelectedCard(item.id)}
                selectedProduct={selectedCard === item.id && 'selectedDiv'}
                containerStyle="mt-2"
                iconStyle="transaction-card-icon"
                key={index}
                title={item.card_number}
                icon={
                  item.card_type === 'MC' ? <MasterCardIcon /> : <VisaIcon />
                }
              />
            ))}
          <div className="mt-4 mb-4 pb-3 d-flex align-items-center flex-column">
            <div className="w-100">
              <Button
                isLoading={handleIpay.isLoading || handleTbc.isLoading}
                handleClick={handleSubmit}
                disabled={!amount}
                type="submit"
                btnStyle="primary-btn-w-h bg-orange"
                text={t('_PAYMENT')}
                color="color-white"
              />
            </div>
          </div>
          <h1 className="font-size-16 bold">{t('_OTHER_PAYMENT_METHOD')}</h1>
          <div className="mb-2 mt-3">
            <ChangablePackageDetails
              imgStyle="fill-balance-img"
              src={TerminalImg}
              title={t('_TERMINAL')}
              content={`${t('_COMMISION_FEE')} 2%`}
            />
          </div>
          <ChangablePackageDetails
            src={BankImg}
            containerStyle="mb-5"
            imgStyle="fill-balance-img"
            title={t('_BANK_PAYMENT')}
            content={`${t('_COMMISION_FEE')} 0.5₾`}
          />
        </div>
      </div>
    </div>
  );
};

export default FillBalance;
