import { t } from 'i18next';
import React from 'react';
import { LocationIcon } from '../../../../../assets/icons';
import Button from '../../../../../components/Button';
import ChangablePackageDetails from '../../../../../components/ChangablePackageDetails';
import { branches } from '../../../../../data/branches';

const BranchListSettings = ({ selectedBranch, setSelectedBranch, handleCloseSecondStep, setWantToUseLocker }) => {
  const branchesData = branches;

  return (
    <div className='d-flex flex-column height-100 justify-content-between sideBarChildComponent'>
      <h1 className='font-size-16 mb-5 bold'>{t('_key_select_branch')}</h1>
      <div className='sidebar-child-scroll'>
        <div className='pr-60'>
          {branchesData &&
            branchesData.map((item, index) => (
              <div
                onClick={() => {
                  setSelectedBranch(item.id);
                  handleCloseSecondStep();
                }}
                className='mb-2'
                key={index}
              >
                <ChangablePackageDetails
                  containerStyle='cursor-pointer'
                  selected={item.id === selectedBranch}
                  icon={<LocationIcon />}
                  item={item}
                  showNew={true}
                  iconStyle='black-icon'
                  title={t(`${item.name}`)}
                  content={t(`${item.address}`)}
                />
              </div>
            ))}
        </div>
      </div>
      <div className='sidebarButtonDiv'>
        <Button handleClick={handleCloseSecondStep} type='button' btnStyle='bg-orange primary-btn-w-h' text={t('_RETURN_BACK')} color='color-white' />
      </div>
    </div>
  );
};

export default BranchListSettings;
