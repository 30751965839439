import React from 'react';
import { PackagesSecondaryIcon } from '../assets/icons';
import { useTranslation } from 'react-i18next';

const NoPackage = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white mt-4 ms-me-20 d-flex flex-column align-items-center pt-5 pb-5 border-rad-12">
      <div className="my-5 text-center">
        <PackagesSecondaryIcon />
        <p className="mt-3 font-size-16 font-bold-caps fw-bolder">{t('_EMPTY_RESULT')}</p>
      </div>
    </div>
  );
};

export default NoPackage;
