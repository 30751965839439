// hooks
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import csoonKa from '../../assets/images/csoon_ka.jpg';
import csoonEn from '../../assets/images/csoon_en.jpg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// libraries
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { Link } from 'react-router-dom';

// assets
import { Arrow, BranchesLocationIcon } from '../../assets/icons';

// components
import OrangeLine from '../../components/OrangeLine';
import Button from '../../components/Button';
import apiRequest from '../../components/ApiRequest';
import { branches } from '../../data/branches';

SwiperCore.use([Pagination]);

export default function BranchesSlider() {
  const { t } = useTranslation();

  return (
    <div className='branches-container mb-12'>
      <div className='d-flex mb-4 justify-content-between align-items-center'>
        <ul className='navbar-div branches-slider-div'>
          <li className='navbar-li'>
            <p className='navbar-li-active cursor-pointer font-size-14'>{t('_BRANCH_SETTINGS')}</p>
            <OrangeLine />
          </li>
        </ul>
        <Link to={'/branches'}>
          <p className='cursor-pointer font-size-14 mb-12 see-all offer-p-slider-color'>{t('_SEE_ALL')}</p>
        </Link>
      </div>
      <div className='branches-slider-container'>
        <Swiper
          loop={true}
          grabCursor={true}
          freeMode={true}
          breakpoints={{
            768: {
              slidesPerView: 3
            },
            200: {
              slidesPerView: 1
            }
          }}
          slidesPerView={1}
          spaceBetween={20}
        >
          {/* <SwiperSlide key={1000}>
            <div className='branches-slider position-relative mb-4'>
              <h4 className='font-size-16 mb-4 pb-1'>{t('_BAGEBI')}</h4>
              <img style={{ width: '100%', height: 170, objectFit: 'scale-down' }} src={i18n.language === 'ka' ? csoonKa : csoonEn} alt='Comming soon' />
            </div>
          </SwiperSlide> */}
          {branches &&
            branches.map((branch) => (
              <SwiperSlide key={branch.id}>
                <div className='branches-slider position-relative mb-4'>
                  <h4 className='font-size-16 mb-4 pb-1'>{t(branch.address)}</h4>
                  {branch.isNew && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        backgroundColor: '#f68424',
                        color: 'white',
                        padding: '4px 20px',
                        fontSize: '11px',
                        borderRadius: '0 6px 0 6px'
                      }}
                    >
                      {t('_NEW')}
                    </div>
                  )}
                  {branch.working_hour.ka.map((item, index) => (
                    <p key={index} className='font-size-14 offer-p-slider-color mb-2'>
                      <span className='font-bold'>{t(item.key)}: </span>
                      {item?.value}
                    </p>
                  ))}
                  <a className='w-100 position-absolute mt-4 link' target='_blank' href={branch.map_link} rel='noreferrer'>
                    <Button
                      color='auth-p ms-4 view-map-p'
                      text={t('_SHOW_ON_MAP')}
                      btnStyle='secondary-btn-w-h svg-white-fill-hover swiper-btns-bg'
                      icon={<BranchesLocationIcon />}
                      icon2={<Arrow />}
                    />
                  </a>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
}
