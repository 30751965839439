import * as Yup from 'yup';

export const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('_PARAMETER_IS_REQUEIRED'),
  newPassword: Yup.string().required('_PARAMETER_IS_REQUEIRED'),
  retryNewPassword: Yup.string().when('newPassword', {
    is: (value) => value && value.length > 0,
    then: Yup.string()
      .required('_PARAMETER_IS_REQUEIRED')
      .oneOf([Yup.ref('newPassword'), null], '_ERR_PASSWORD'),
    otherwise: Yup.string().required('_PARAMETER_IS_REQUEIRED')
  })
});
