import React, { useContext, useEffect, useRef, useState } from 'react';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import {
  Airplane2Icon,
  Box,
  CalendarIcon,
  CrossIcon,
  FlightArrivedIcon,
  GlobeIcon,
  ImageScale,
  InboxIcon,
  InvoiceIcon,
  LockIcon,
  PenIcon,
  PinIcon,
  RecycleBinIcon,
  ReturnedIcon,
  ShipIcon,
  TickIcon,
  TrackingIcon,
  Truck2Icon,
  TruckIcon,
  Weight
} from '../../assets/icons/index';
import { lockers } from '../../data/lockers';
import FillBalance from '../../pages/sidebar-pages/FillBalance';
import '../../scss/PackageDetails.scss';
import { getAwaitingAsyncThunk } from '../../store/thunks/packages.thunk';
import { getAmountWithCurrency } from '../../utils/utils';
import apiRequest from '../ApiRequest';
import Button from '../Button';
import ChangablePackageDetails from '../ChangablePackageDetails';
import GlobalContext from '../GlobalContext';
import NonChangablePackageDetail from '../NonChangablePackageDetail';
import useImage from '../hooks/useImage';
import Modal from '../modal/Modal';
import SideBar from '../sidebar/SideBar';
import AddNew from './AddNew';
import { CutdownedBlock } from './CutdownedBlock';
import Declaration from './Declaration';
import Radio from '../radio/Radio';
import { useNavigate } from 'react-router';
import DeclarationWeb from './DeclarationWeb';

export default function PackageDetails({ item, status, icon, eachTracking, setOpenSidebar, selectedBranch, defaultLocker, packageCountry }) {
  const dispatch = useDispatch();
  const currentLang = i18n.language;
  const packageRef = useRef([]);
  const balanceSidebarRef = useRef([]);
  const [openDeclaration, setOpenDeclaration] = useState(false);
  const [openBalanceSidebar, setOpenBalanceSidebar] = useState(false);
  const [comment, setComment] = useState(eachTracking?.client_comment);
  const [commentInputActive, setCommentInputActive] = useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [imageName, setImageName] = useState('');
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { token, setToken, setAlert, setShowLoader } = useContext(GlobalContext);
  const { error, loading, image } = useImage(eachTracking?.shipper || item?.shipper);
  const [showImage, setShowImage] = useState(false);
  const [goods, setGoods] = useState([]);
  const [fileName, setFileName] = useState('');
  const commentRef = useRef(null);
  const [showCutdownModal, setShowCutdownModal] = useState(false);
  const [cutdownSetting, setCutdownSetting] = useState(false);
  const [showRepeatCutdownModal, setShowRepeatCutdownModal] = useState(false);

  useEffect(() => {
    // console.log(eachTracking?.cut_down);
    setCutdownSetting(eachTracking?.cut_down === 1);
  }, []);

  const saveCutDown = useMutation(
    (cutDown) =>
      apiRequest(
        {
          command: 'save_package_cut_down_setting',
          sessionId: localStorage.getItem('token')
        },
        {
          packageId: item?.package_id,
          cutDown: cutDown ? 1 : 0
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setCutdownSetting(!cutdownSetting);
          setShowCutdownModal(false);
          setShowRepeatCutdownModal(false);
          queryClient.invalidateQueries('getAwaiting');
          dispatch(getAwaitingAsyncThunk());
          setAlert(['success', t(response.data.message)]);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
        setShowCutdownModal(false);
      }
    }
  );

  const cutDownAgree = async (e) => {
    if (!cutdownSetting) {
      try {
        await saveCutDown.mutateAsync(!cutdownSetting).then(() => {});
      } catch (error) {
        console.log('error', error);
      }
    } else {
      setShowRepeatCutdownModal(true);
    }
  };

  const cutDownCancel = async (e) => {
    if (!cutdownSetting) {
      setShowRepeatCutdownModal(true);
      setShowCutdownModal(false);
    } else {
      setShowRepeatCutdownModal(false);
      setShowCutdownModal(false);
    }
  };

  const repeatConfirm = async () => {
    if (cutdownSetting) {
      try {
        await saveCutDown.mutateAsync(!cutdownSetting).then(() => {});
      } catch (error) {
        console.log('error', error);
      }
    } else {
      setShowRepeatCutdownModal(false);
      setShowCutdownModal(false);
    }
  };

  const repeatCancel = async (e) => {
    if (!cutdownSetting) {
      setShowRepeatCutdownModal(false);
      setShowCutdownModal(true);
    } else {
      setShowRepeatCutdownModal(false);
      setShowCutdownModal(false);
    }
  };

  const { data: getDeclaration, isLoading: declarationDetailsLoading } = useQuery(['getDeclaration', eachTracking?.package_id], () => {
    return apiRequest(
      {
        command: 'get_declaration',
        sessionId: localStorage.getItem('token')
      },
      {
        packageId: eachTracking && parseInt(eachTracking?.package_id)
      }
    );
  });
  const getDeclarations = getDeclaration?.data?.result;
  useEffect(() => {
    setGoods(getDeclarations?.goods);
  }, [getDeclaration]);

  const { data: getInvoice } = useQuery(['getInvoice', item?.invoice], () =>
    apiRequest(
      {
        command: 'get_invoice',
        sessionId: localStorage.getItem('token')
      },
      {
        invoice: item?.invoice
      }
    )
  );
  const invoices = getInvoice?.data?.result;

  const lockersList = lockers;

  //* * Tracking Image */
  const { data: getImageFilesByTracking, isSuccess } = useQuery(['getImageFilesByTracking', eachTracking?.tracking], () =>
    apiRequest(
      {
        command: 'get_package_cut_down_impact',
        sessionId: token
      },
      {
        tracking: eachTracking?.tracking
      }
    )
  );

  const has2Images = isSuccess && getImageFilesByTracking?.data?.result?.images?.after;

  const trackingImageBase64 = isSuccess && getImageFilesByTracking?.data?.result?.images?.before;
  const trackingImage = `data:image/jpeg;base64,${trackingImageBase64}`;

  const trackingImageBase642 = isSuccess && has2Images && getImageFilesByTracking?.data?.result?.images?.after;
  const trackingImage2 = isSuccess && has2Images && `data:image/jpeg;base64,${trackingImageBase642}`;

  const chargeables = isSuccess && has2Images && getImageFilesByTracking?.data?.result?.chargeables;

  const addComment = useMutation(
    (comment) => {
      return apiRequest(
        {
          command: 'add_tracking_comment',
          sessionId: localStorage.getItem('token')
        },
        {
          packageId: eachTracking?.package_id,
          comment
        }
      );
    },
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );
  const refreshTracking = async () => {
    try {
      await addComment.mutateAsync(comment);
      queryClient.invalidateQueries('getAwaiting');
      dispatch(getAwaitingAsyncThunk());
      queryClient.invalidateQueries('getUsaWarehouse');
      queryClient.invalidateQueries('getPending');
      queryClient.invalidateQueries('getArrived');
      queryClient.invalidateQueries('getReceived');
    } catch (error) {
      console.log('error', error);
    }
  };

  //* ინვოისის ატვირთვა */

  const uploadInvoice = useMutation(
    (params) =>
      apiRequest(
        {
          command: 'upload_invoice',
          sessionId: token,
          method: 'POST'
        },
        {
          packageId: parseInt(eachTracking?.package_id),
          base64data: params.base64data,
          extention: params.extention
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );

  const refreshTrackings = async (baseURL, filename) => {
    try {
      await uploadInvoice.mutateAsync({
        base64data: baseURL.split(',')[1],
        extention: filename[filename.length - 1]
      });
      queryClient.invalidateQueries('getAwaiting');
      dispatch(getAwaitingAsyncThunk());
      queryClient.invalidateQueries('getUsaWarehouse');
      queryClient.invalidateQueries('getPending');
      queryClient.invalidateQueries('getArrived');
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];

    let baseURL = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      baseURL = reader.result;
      const filename = e.target.files[0].name.split('.');
      refreshTrackings(baseURL, filename);
    };
  };

  //* * ინვოისის ნახვა */
  const { data: getUploadedInvoice } = useQuery(
    ['getUploadedInvoice', fileName],
    () => {
      return apiRequest(
        {
          command: 'get_uploaded_invoice',
          sessionId: localStorage.getItem('token')
        },
        {
          packageId: eachTracking && parseInt(eachTracking?.package_id),
          filename: fileName
        }
      );
    },
    {
      enabled: !!fileName,
      onSuccess: (response) => {
        const base64 = response.data.result.data;
        const ext = response.data.result.extention;
        const a = document.createElement('a');
        const fileTypes = {
          pdf: 'application/pdf',
          png: 'image/png',
          jpg: 'image/jpg',
          jpeg: 'image/jpeg'
        };
        a.href = 'data:' + fileTypes[ext] + ';base64,' + base64;
        a.download = fileName;
        a.click();
      }
    }
  );

  //* * ინვოისის წაშლა */
  const removeInvoice = useMutation(
    (filename) =>
      apiRequest(
        {
          command: 'remove_invoice',
          sessionId: token
        },
        {
          packageId: eachTracking?.package_id,
          filename
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          refreshTrackings();
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );

  const refreshInvoice = async () => {
    try {
      await removeInvoice.mutateAsync(imageName);
      queryClient.invalidateQueries('getAwaiting');
      dispatch(getAwaitingAsyncThunk());
      queryClient.invalidateQueries('getUsaWarehouse');
      queryClient.invalidateQueries('getPending');
      queryClient.invalidateQueries('getArrived');
      queryClient.invalidateQueries('getReceived');
    } catch (error) {
      console.log('error', error);
    }
  };

  //* * ამანათის წაშლა */
  const removeTracking = useMutation(
    () => {
      return apiRequest(
        {
          command: 'remove_tracking',
          sessionId: token
        },
        { packageId: item.package_id }
      );
    },
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          setOpenSidebar(false);
        } else {
          setAlert(['error', t(response.data.message)]);
          setOpenSidebar(false);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );

  useEffect(() => {
    // console.log('PackageDetails.js - get_customer_info');
  }, []);

  const { data: userData } = useQuery(
    'userInfo',
    () =>
      apiRequest({
        command: 'get_customer_info',
        sessionId: token
      }),
    {
      enabled: !!token
    }
  );

  const [packageInfo, setPackageInfo] = useState({});

  useEffect(() => {
    setShowLoader(true);
    apiRequest(
      {
        command: 'get_package_info',
        sessionId: token
      },
      {
        packageId: item?.package_id === undefined ? eachTracking?.package_id : item?.package_id
      }
    ).then((response) => {
      setShowLoader(false);
      setPackageInfo(response?.data?.result);
      setTransportMethod(response?.data?.result?.transport_method?.value);
      setNewTransportMethod(response?.data?.result?.transport_method?.value);
    });
  }, [item]);

  const handleClick = async () => {
    try {
      await removeTracking.mutateAsync();
      dispatch(getAwaitingAsyncThunk());
      queryClient.invalidateQueries('userInfo');
    } catch (error) {
      console.log('error', error);
    }
  };

  const trackingInfo = (
    <>
      {eachTracking.tracking}
      {eachTracking?.alias_tracking?.length > 0 && <div className='aliasTracking'>({eachTracking?.alias_tracking})</div>}
    </>
  );

  //* პროდუქტების კატეგორია */
  const { data: getPackageDescriptions, isLoading: packageDetailsLoading } = useQuery('getPackageDescriptions', () =>
    apiRequest({
      command: 'get_package_descriptions',
      sessionId: localStorage.getItem('token')
    })
  );
  const getProductCategories = getPackageDescriptions?.data?.result;

  function getTitle(product) {
    let title;
    getProductCategories?.filter((category) => {
      if (category.code === product.code) {
        title = category[t('_key_desc')];
      } else {
        title = product[t('_key_desc')];
      }
      return title;
    });
    return title;
  }

  useEffect(() => {
    setShowLoader(uploadInvoice.isLoading);
  }, [uploadInvoice.isLoading]);
  useEffect(() => {
    setShowLoader(removeInvoice.isLoading);
  }, [removeInvoice.isLoading]);
  useEffect(() => {
    setShowLoader(addComment.isLoading);
  }, [addComment.isLoading]);
  useEffect(() => {
    setShowLoader(declarationDetailsLoading);
  }, [declarationDetailsLoading]);
  useEffect(() => {
    setShowLoader(packageDetailsLoading);
  }, [packageDetailsLoading]);

  const receivedPackageLockerLogo = lockersList && item?.locker_id && lockersList.filter((locker) => locker.id === item.locker_id).map((item) => item.logo);

  // console.log(receivedPackageLockerLogo);

  // console.log(eachTracking);
  // console.log(!(status === 'arrived' || status === 'received' || eachTracking?.locked === 1));

  const [showSelectTransportationMethodModal, setShowSelectTransportationMethodModal] = useState(false);
  const [switchToModalText, setSwitchToModalText] = useState('');

  const chinaTransportMethods = useSelector((state) => state.customer.chinaTransportMethods);

  const [newTransportMethod, setNewTransportMethod] = useState('');
  const [transportMethod, setTransportMethod] = useState('');

  const requestSwitchAgree = async () => {
    try {
      await saveSettings.mutateAsync().then(() => {
        setShowSelectTransportationMethodModal(false);
        setTransportMethod(newTransportMethod);
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const requestSwitchCancel = async () => {
    setShowSelectTransportationMethodModal(false);
  };

  const switchTransportMethod = async (method) => {
    setNewTransportMethod(method);

    if (method === 'PLANE') {
      setSwitchToModalText('_SWITCH_SETTING_TO_PLANE_PER_PACKAGE_CONFIRMATION_WEB');
    } else if (method === 'TRUCK') {
      setSwitchToModalText('_SWITCH_SETTING_TO_TRUCK_PER_PACKAGE_CONFIRMATION_WEB');
    }

    setShowSelectTransportationMethodModal(true);
  };

  const navigate = useNavigate();

  const saveSettings = useMutation(
    () =>
      apiRequest(
        {
          command: 'save_package_setting_transport_method',
          sessionId: token
        },
        {
          packageId: item?.package_id,
          transportMethod: newTransportMethod
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);

          // Refresh List
          dispatch(getAwaitingAsyncThunk()).then(({ payload }) => {
            if (payload?.code === 102) {
              navigate('/');
              setToken(null);
            }
          });

          queryClient.invalidateQueries('getUsaWarehouse');

          setTransportMethod(newTransportMethod);
          setShowSelectTransportationMethodModal(false);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      }
    }
  );

  // console.log(status);

  return (
    <div className={`${item.notice_status === 'RETURNED' || item.notice_status === 'PREPARING_TBILISI' ? 'returned' : status} PackageDetailsDiv sideBarChildComponent`}>
      <div className='d-flex justify-content-between align-items-center mb-4 sidebarTitle'>
        <h3 className='packageDetailsTitle'>{t('_key_package_details')}</h3>
        {status === 'awaiting' && (
          <Button handleClick={() => setOpenDeleteModal(true)} text={t('_KEY_DELETE_CARD')} btnStyle='btn-danger mybutton-danger packageDeleteBtn' icon={<RecycleBinIcon />} />
        )}
      </div>
      <div className='sidebar-child-scroll'>
        {!trackingImageBase642 && trackingImageBase64 && (
          <>
            <div className='PackageDetailsImageDiv' onClick={() => setShowImage(!showImage)}>
              <img src={trackingImage} alt='Package' />
              <div className='PackageImageHoverDiv d-flex justify-content-center align-items-center'>
                <ImageScale />
              </div>
            </div>
            {showImage && <Lightbox image={trackingImage} onClose={() => setShowImage(false)} keyboardInteraction />}
          </>
        )}
        {trackingImageBase642 && <CutdownedBlock trackingImage={trackingImage} trackingImage2={trackingImage2} chargeables={chargeables} />}
        {item.notice_status === 'RETURNED' ? (
          <div className='hawbComment d-flex justify-content-between align-items-stretch mb-5'>
            <p>{`${t('_key_package_returned')} ${item.hawb_comment ? `, ${item.hawb_comment}` : ''}`}</p>
            <div className='d-flex justify-content-center align-items-center'>
              <ReturnedIcon />
            </div>
          </div>
        ) : item.notice_status === 'PREPARING_TBILISI' ? (
          <div className='hawbComment d-flex justify-content-between align-items-stretch mb-5'>
            <p>{`${t('_key_prepare_for_currier')} ${item.hawb_comment ? `, ${item.hawb_comment}` : ''}`}</p>
            <div className='d-flex justify-content-center align-items-center'>
              <TruckIcon />
            </div>
          </div>
        ) : (
          item.hawb_comment_template &&
          !Array.isArray(item.hawb_comment_template) && (
            <div className='hawbComment d-flex justify-content-between align-items-stretch mb-5'>
              <p dangerouslySetInnerHTML={{ __html: item.hawb_comment_template[currentLang].replace('\n', '<br />') }}></p>
              <div className='d-flex justify-content-center align-items-center'>{icon}</div>
            </div>
          )
        )}
        {eachTracking &&
          (status === 'received' || status === 'arrived') &&
          (invoices ? (
            <NonChangablePackageDetail
              title={t('_INVOICE')}
              info={`${invoices.total.toFixed(2)} ₾`}
              icon={<InvoiceIcon />}
              invoice={invoices && invoices}
              link={`/invoice?number=${invoices?.invoice}`}
              customFunction={() => {
                if (invoices.paid === '0') {
                  setOpenBalanceSidebar(true);
                }
              }}
            />
          ) : null)}
        {status === 'received' && item.nc_na && (
          <NonChangablePackageDetail
            title={t('_CUSTOMS_DECLARATION')}
            icon={<InvoiceIcon />}
            invoice={{ paid: '1' }}
            link={`https://www.usa2georgia.com/view-declaration/${userData?.data?.result?.profile?.customer_id}/${item?.shipment_id}`}
          />
        )}

        {item.hawb && (
          <NonChangablePackageDetail title={t('_HAWB')} info={item.hawb} icon={item?.proxy_country === 'T' ? <Truck2Icon width={30} height={30} /> : <FlightArrivedIcon />} />
        )}

        {item.hawb_date_formated && <NonChangablePackageDetail title={t('_key_arrival_date')} info={item.hawb_date_formated} icon={<CalendarIcon />} />}
        {eachTracking && eachTracking.tracking && <NonChangablePackageDetail icon={<TrackingIcon />} title={t('_TRACKING_CODE')} info={trackingInfo} />}
        {eachTracking && eachTracking.tracking && status === 'awaiting' && item?.country !== 'TURKEY' && (
          <div className={'nonChangableInfo d-flex'} style={{ paddingTop: 16, paddingBottom: 16 }}>
            <div className='d-flex align-items-center justify-content-center' style={{ marginRight: 32 }}>
              <Switch
                checkedIcon={false}
                uncheckedIcon={false}
                width={50}
                height={24}
                handleDiameter={20}
                onColor='#30bf54'
                offColor='#e9e9eb'
                onChange={() => setShowCutdownModal(true)}
                checked={cutdownSetting}
                disabled={status !== 'awaiting'}
              />
            </div>
            <div className='infoDiv'>
              <h6 className='info' style={{ marginBottom: 3 }}>
                {t('_CUTDOWN_SETTINGS')}
              </h6>
            </div>
          </div>
        )}

        {eachTracking && eachTracking.tracking && packageInfo?.transport_method?.visible && (
          <>
            <>
              <div
                className={'nonChangableInfo d-flex'}
                style={{ paddingTop: 16, paddingBottom: 16, flexDirection: 'column', gap: 15, border: '1px solid #ccc', padding: 16, borderRadius: 12 }}
              >
                <h6 className='infoTitle' style={{ textAlign: 'center' }}>
                  {t(
                    !packageInfo?.transport_method?.enabled ? '_CHINA_TRUCK_TRANSPORTATION_PER_PACKAGE_DESCRIPTION_DISABLED' : '_CHINA_TRUCK_TRANSPORTATION_PER_PACKAGE_DESCRIPTION'
                  )}
                </h6>
                <div className={'d-flex align-items-center flex-row'} style={{ justifyContent: 'space-around', width: '100%' }}>
                  {chinaTransportMethods?.map((element) => {
                    let icon = null;
                    if (element === 'PLANE') {
                      icon = <Airplane2Icon />;
                    } else if (element === 'TRUCK') {
                      icon = <Truck2Icon />;
                    } else if (element === 'SHIP') {
                      icon = <ShipIcon />;
                    }
                    return (
                      <Radio
                        disabled={!packageInfo?.transport_method?.enabled}
                        key={element}
                        icon={icon}
                        value={element}
                        selected={transportMethod === element}
                        onChange={() => {
                          switchTransportMethod(element);
                        }}
                      />
                    );
                  })}
                </div>
                {!packageInfo?.transport_method?.enabled && (
                  <div style={{ color: 'red', fontSize: 14 }}>
                    <LockIcon className='lock-icon-fill' style={{ width: 30, height: 30 }} />
                    {t('_CHINA_TRUCK_TRANSPORTATION_PER_PACKAGE_NOTICE')}
                  </div>
                )}
              </div>
              <Modal showModal={showSelectTransportationMethodModal} setShowModal={setShowSelectTransportationMethodModal} modal={true}>
                <div className='d-flex flex-column'>
                  <p className='modalText' style={{ maxHeight: 500, overflow: 'auto', paddingRight: 15 }} dangerouslySetInnerHTML={{ __html: t(switchToModalText) }}></p>
                  <div className='d-flex align-items-center justify-content-center modal-btn-div mt-4'>
                    <Button handleClick={requestSwitchAgree} type='button' btnStyle='bg-orange me-4 fill-balance-btn' text={t('_AGREE')} color='color-white' isLoading={false} />
                    <Button handleClick={requestSwitchCancel} type='button' btnStyle='bg-orange fill-balance-btn secondary-btn-bg' text={t('_NOT_AGREE')} color='color-white' />
                  </div>
                </div>
              </Modal>
            </>
          </>
        )}

        {eachTracking && eachTracking.ch_weight_calculation === 'DIMENSIONAL' && (
          <NonChangablePackageDetail
            icon={<Box style={{ width: 30 }} />}
            title={t('_BOX_DIMS')}
            info={`${(eachTracking?.pkg_dims?.height / 10).toFixed(1)} x ${(eachTracking?.pkg_dims?.length / 10).toFixed(1)} x ${(eachTracking?.pkg_dims?.width / 10).toFixed(
              1
            )} ${t('_UNIT_CM')}`}
          />
        )}
        {eachTracking && eachTracking.weight_kg > 0 && (
          <NonChangablePackageDetail
            icon={<Weight />}
            title={eachTracking.ch_weight_calculation === 'DIMENSIONAL' ? t('_DIMENSIONAL_WEIGHT') : t('_GROSS_WEIGHT')}
            info={`${eachTracking.weight_kg} ${t('_KG')}`}
          />
        )}
        <div className='my-4'>
          {status === 'received' &&
            (item?.locker_id ? (
              <ChangablePackageDetails src={receivedPackageLockerLogo} title={t(item.locker_title)} />
            ) : (
              <ChangablePackageDetails icon={<PinIcon />} title={t(item.branch)} />
            ))}
          {status !== 'received' && selectedBranch && (
            <ChangablePackageDetails src={defaultLocker?.logo || false} icon={!defaultLocker?.logo && <PinIcon />} title={t(defaultLocker?.title) || t(selectedBranch.branch)} />
          )}
        </div>
        <div className='my-4'>
          {getDeclarations?.shipper === null || !getDeclarations?.shipper ? (
            eachTracking?.shipper === null || !eachTracking?.shipper ? (
              (item?.declared !== 1 || eachTracking?.declared !== 1) && (eachTracking?.locked === 1 || item?.locked === 1) ? (
                <></>
              ) : (
                <AddNew text={t('_key_declare_package')} onClick={() => setOpenDeclaration(!openDeclaration)} />
              )
            ) : error ? (
              <ChangablePackageDetails
                icon={<GlobeIcon />}
                title={eachTracking.shipper}
                editableFunction={() => setOpenDeclaration(!openDeclaration)}
                editable={!(status === 'arrived' || status === 'received' || eachTracking?.locked === 1)}
              />
            ) : loading ? (
              <ChangablePackageDetails
                icon={<GlobeIcon />}
                title={eachTracking.shipper}
                editableFunction={() => setOpenDeclaration(!openDeclaration)}
                editable={!(status === 'arrived' || status === 'received' || eachTracking?.locked === 1)}
              />
            ) : image ? (
              <ChangablePackageDetails
                src={image}
                title={eachTracking.shipper}
                editableFunction={() => setOpenDeclaration(!openDeclaration)}
                editable={!(status === 'arrived' || status === 'received' || eachTracking?.locked === 1)}
              />
            ) : (
              <ChangablePackageDetails
                icon={<GlobeIcon />}
                title={eachTracking.shipper}
                editableFunction={() => setOpenDeclaration(!openDeclaration)}
                editable={!(status === 'arrived' || status === 'received' || eachTracking?.locked === 1)}
              />
            )
          ) : error ? (
            <ChangablePackageDetails
              icon={<GlobeIcon />}
              title={getDeclarations?.shipper}
              editableFunction={() => setOpenDeclaration(!openDeclaration)}
              editable={!(status === 'arrived' || status === 'received' || eachTracking?.locked === 1)}
            />
          ) : loading ? (
            <ChangablePackageDetails
              icon={<GlobeIcon />}
              title={getDeclarations?.shipper}
              editableFunction={() => setOpenDeclaration(!openDeclaration)}
              editable={!(status === 'arrived' || status === 'received' || eachTracking?.locked === 1)}
            />
          ) : image ? (
            <ChangablePackageDetails
              src={image}
              title={getDeclarations?.shipper}
              editableFunction={() => setOpenDeclaration(!openDeclaration)}
              editable={!(status === 'arrived' || status === 'received' || eachTracking?.locked === 1)}
            />
          ) : (
            <ChangablePackageDetails
              icon={<GlobeIcon />}
              title={getDeclarations?.shipper}
              editableFunction={() => setOpenDeclaration(!openDeclaration)}
              editable={!(status === 'arrived' || status === 'received' || eachTracking?.locked === 1)}
            />
          )}
        </div>
        {goods?.length > 0 && (
          <div className='packageProductsDiv'>
            {getDeclarations && getDeclarations.goods.length > 0 && (
              <div className='d-flex justify-content-between align-items-center declare-summary'>
                <h3 className='packageProductDivTitle'>{t('_key_products')}</h3>
                {eachTracking && (
                  <div className={`${JSON.stringify(eachTracking.total_price).length >= 7 ? 'BigTotalPrice' : ''} d-flex align-items-center justify-content-between w-100`}>
                    <span className='packagePrice'>{t('_key_product_sum')}</span>
                    <p className='packageTotalPrice'>
                      {getAmountWithCurrency(eachTracking.total_price?.toFixed?.(2), JSON?.parse(localStorage.getItem('currencies'))?.[packageCountry])}
                    </p>
                  </div>
                )}
              </div>
            )}
            {getDeclarations && (
              <div className='mt-4 mb-2 productsContainer'>
                {getDeclarations.goods.map((product, index) => {
                  return (
                    <div key={index}>
                      <ChangablePackageDetails
                        productCode={product.code}
                        title={getTitle(product) || product.description}
                        icon={<InboxIcon />}
                        content={getAmountWithCurrency((product.unit_price * product.unit_count).toFixed?.(2), JSON?.parse(localStorage.getItem('currencies'))?.[packageCountry])}
                        editable={!(status === 'arrived' || status === 'received' || eachTracking?.locked === 1)}
                        editableFunction={() => setOpenDeclaration(!openDeclaration)}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {status === 'arrived' || status === 'received' || eachTracking?.locked === 1 ? <></> : <AddNew text={t('_key_add_product')} onClick={() => setOpenDeclaration(true)} />}
          </div>
        )}
        <div className='mb-2 mt-4'>
          {eachTracking &&
            eachTracking.invoices &&
            eachTracking.invoices.length > 0 &&
            eachTracking.invoices.map((invoice, index) => {
              return (
                <div className='my-2' key={index}>
                  <ChangablePackageDetails
                    key={index}
                    icon={<InvoiceIcon />}
                    item={invoice}
                    titleClick={() => setFileName(invoice)}
                    type='invoice'
                    deletable={true}
                    deleteFunction={() => {
                      setImageName(eachTracking.invoices[index]);
                      setOpenInvoiceModal(true);
                    }}
                  />
                </div>
              );
            })}
        </div>
        {/* {status === 'arrived' || status === 'received' ? (
          <></>
        ) : (
          eachTracking?.invoices?.length < 3 && (
            <div className='mb-5'>
              <AddNew text={t('_key_upload_invoice')} upload={true} accept='.jpg, .jpeg, .png, .pdf' onChange={(e) => handleFileInputChange(e)} />
            </div>
          )
        )} */}
        {eachTracking && (
          <div className='my-4 clientCommentDiv'>
            <h5 className='clientCommentTitle'>{t('_COMMENT')}</h5>
            <div className='d-flex justify-content-between align-items-end eachCommentDiv'>
              <textarea
                ref={commentRef}
                name='comment'
                id='parcelComment'
                value={comment}
                placeholder={t('_key_add_comment')}
                onChange={(e) => {
                  if (commentInputActive) {
                    setComment(e.target.value);
                  }
                }}
                className={`${commentInputActive === true ? 'activeCommentInput' : ''} CommentInput`}
                disabled={!commentInputActive}
              />
              <div className={`commentBtnDiv d-flex justify-content-center align-items-center clientCommentBtns ${commentInputActive ? 'visible' : 'hidden'}`}>
                <button
                  className='button-secondary'
                  onClick={() => {
                    setCommentInputActive(false);
                    setComment(eachTracking?.client_comment);
                  }}
                >
                  {<CrossIcon />}
                </button>
                <button
                  className='button-primary'
                  onClick={() => {
                    // handleClose();
                    refreshTracking();
                    setCommentInputActive(false);
                  }}
                >
                  {<TickIcon />}
                </button>
              </div>
              <div className={`d-flex justify-content-center align-items-center iconDiv clientCommentBtns ${commentInputActive ? 'hidden' : 'visible'}`}>
                <PenIcon
                  onClick={() => {
                    setCommentInputActive(true);
                    commentRef.current.focus();
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal showModal={openInvoiceModal} setShowModal={setOpenInvoiceModal}>
        <div className='d-flex flex-column'>
          <p className='modalText'>{t('_UPLOADED_ORDER_REMOVE_CONFIRM')}</p>
          <div className='d-flex align-items-center justify-content-center modal-btn-div mt-4'>
            <Button
              handleClick={() => {
                refreshInvoice();
                setOpenInvoiceModal(false);
              }}
              type='button'
              btnStyle='bg-orange me-4 fill-balance-btn'
              text={t('_YES')}
              color='color-white'
            />
            <Button
              handleClick={() => {
                setOpenInvoiceModal(false);
              }}
              type='button'
              btnStyle='bg-orange fill-balance-btn secondary-btn-bg'
              text={t('_NO')}
              color='color-white'
            />
          </div>
        </div>
      </Modal>
      <Modal showModal={openDeleteModal} setShowModal={setOpenDeleteModal}>
        <div className='d-flex flex-column'>
          <p className='modalText'>{t('_TRACKING_REMOVE_CONFIRMATION')}</p>
          <div className='d-flex align-items-center justify-content-center modal-btn-div mt-4'>
            <Button
              handleClick={() => {
                handleClick();
                setOpenDeleteModal(false);
              }}
              type='button'
              btnStyle='bg-orange me-4 fill-balance-btn'
              text={t('_YES')}
              color='color-white'
            />
            <Button
              handleClick={() => {
                setOpenDeleteModal(false);
              }}
              type='button'
              btnStyle='bg-orange fill-balance-btn secondary-btn-bg'
              text={t('_NO')}
              color='color-white'
            />
          </div>
        </div>
      </Modal>

      <SideBar openSidebar={openDeclaration} setOpenSidebar={setOpenDeclaration} ref={packageRef} backIcon>
        {openDeclaration ? <DeclarationWeb parcel={eachTracking && eachTracking} /> : null}
      </SideBar>

      {/* <SideBar openSidebar={openDeclaration} setOpenSidebar={setOpenDeclaration} ref={packageRef} backIcon>
        {openDeclaration ? (
          <Declaration
            parcel={eachTracking && eachTracking}
            passedGoods={goods}
            handleClose={() => setOpenDeclaration(false)}
            packageCountry={getDeclarations?.country}
            isAwaiting={true}
          />
        ) : null}
      </SideBar> */}
      <SideBar openSidebar={openBalanceSidebar} setOpenSidebar={setOpenBalanceSidebar} ref={balanceSidebarRef} backIcon>
        {openBalanceSidebar && (
          <FillBalance openSidebar={openBalanceSidebar} setOpenSidebar={setOpenBalanceSidebar} priceAmount={userData?.data?.result?.profile?.balance_info?.payable} />
        )}
      </SideBar>

      <Modal showModal={showCutdownModal} setShowModal={setShowCutdownModal} modal={true}>
        <div className='d-flex flex-column'>
          <p
            className='modalText'
            dangerouslySetInnerHTML={{ __html: cutdownSetting ? t('_POPUP_MESSAGE_FOR_TURNOFF_CUTDOWN_PER_PACKAGE') : t('_POPUP_MESSAGE_FOR_TURNON_CUTDOWN_PER_PACKAGE') }}
          ></p>
          <div className='d-flex align-items-center justify-content-center modal-btn-div mt-4'>
            <Button handleClick={cutDownAgree} type='button' btnStyle='bg-orange me-4 fill-balance-btn' text={t('_AGREE')} color='color-white' isLoading={false} />
            <Button handleClick={cutDownCancel} type='button' btnStyle='bg-orange fill-balance-btn secondary-btn-bg' text={t('_NOT_AGREE')} color='color-white' />
          </div>
        </div>
      </Modal>

      <Modal showModal={showRepeatCutdownModal} setShowModal={setShowRepeatCutdownModal} modal={true}>
        <div className='d-flex flex-column'>
          <p
            className='modalText'
            dangerouslySetInnerHTML={{ __html: cutdownSetting ? t('_POPUP_CONFIRMATION_FOR_TURNOFF_PER_PACKAGE') : t('_POPUP_CONFIRMATION_FOR_TURNON_PER_PACKAGE') }}
          ></p>
          <div className='d-flex align-items-center justify-content-center modal-btn-div mt-4'>
            <Button
              handleClick={() => {
                repeatConfirm();
              }}
              type='button'
              btnStyle='bg-orange me-4 fill-balance-btn'
              text={t('_CONFIRM')}
              color='color-white'
              isLoading={false}
            />
            <Button
              handleClick={() => {
                repeatCancel();
              }}
              type='button'
              btnStyle='bg-orange fill-balance-btn secondary-btn-bg'
              text={t('_DO_NOT_CONFIRM')}
              color='color-white'
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
