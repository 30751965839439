import React from 'react';
import { SearchIcon } from '../assets/icons';

const Search = ({ placeholder, value, onChange, ...props }) => {
  return (
    <div className="position-relative font-size-14 search-container">
      <SearchIcon className="search-icon" />
      <input
        placeholder={placeholder}
        className="search"
        type="text"
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export default Search;
