import React from 'react';

const StepIcons = ({ sliderStep, activeForm }) => {
  return (
    <div className="steps-container">
      <span className={`step-icon ${sliderStep === 0 && 'active-step'}`} />
      <span className={`step-icon ${sliderStep === 1 && 'active-step'}`} />
      <span className={`step-icon ${sliderStep === 2 && 'active-step'}`} />
      <span className={`step-icon ${sliderStep === 3 && 'active-step'}`} />
      {activeForm === 'legal' && (
        <span className={`step-icon ${sliderStep === 4 && 'active-step'}`} />
      )}
    </div>
  );
};

export default StepIcons;
