import React from 'react';
import Radio from '../radio/Radio';
import USAFlag from './../../assets/images/flags/DELAWARE.svg';
import TurkeyFlag from './../../assets/images/flags/TURKEY.svg';
import ChinaFlag from './../../assets/images/flags/CHINA.svg';

function CountryChooser({ country, setCountry }) {
  return (
    <div className='add-tracking-flag' style={{ marginBottom: 20, marginTop: -10 }}>
      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '80%', margin: '0 auto' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
          <img height='30px' width={'30px'} src={USAFlag} onClick={() => setCountry('DELAWARE')} style={{ cursor: 'pointer' }} />
          <Radio value={country === null ? '' : country} onChange={() => setCountry('DELAWARE')} selected={country === 'DELAWARE'} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
          <img height='30px' width={'30px'} src={TurkeyFlag} onClick={() => setCountry('TURKEY')} style={{ cursor: 'pointer' }} />
          <Radio value={country === null ? '' : country} onChange={() => setCountry('TURKEY')} selected={country === 'TURKEY'} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
          <img height='30px' width={'30px'} src={ChinaFlag} onClick={() => setCountry('CHINA')} style={{ cursor: 'pointer' }} />
          <Radio value={country === null ? '' : country} onChange={() => setCountry('CHINA')} selected={country === 'CHINA'} />
        </div>
      </div>
    </div>
  );
}

export default CountryChooser;
