// hooks
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// libraries
import * as dayjs from 'dayjs';

// assets
import { Arrow } from '../../assets/icons';
import TbcImg from '../../assets/images/tbc.svg';
import USA from '../../assets/images/trans_logo.png';

// components
import SideBar from '../../components/sidebar/SideBar';
import i18n from 'i18next';
import PriorityStatus from '../settings/components/tickets/components/PriorityStatus';
import TicketStatus from '../settings/components/tickets/components/TicketStatus';

const switchTitle = (status) => {
  switch (status) {
    case 4:
      return '_URGENT';
    case 3:
      return '_HIGH';
    case 2:
      return '_MEDIUM';
    case 1:
      return '_LOW';
  }
};

const switchStatus = (status) => {
  switch (status) {
    case 2:
      return '_TICKET_OPEN';
    case 3:
      return '_TICKET_PENDING';
    case 4:
      return '_TICKET_RESOLVED';
    case 5:
      return '_TICKET_CLOSED';
  }
};

export function MailItem({ item }) {
  const { t } = useTranslation();
  const transactionRef = useRef();
  const currentLang = i18n.languages[0];
  const [openTransaction, setOpenTransaction] = useState(false);

  console.log(item);

  return (
    <div>
      <div
        onClick={() => transactionRef.current.open()}
        className="flights-container transactions-div"
      >
        <div className="d-flex justify-content-between w-full align-items-center">
          <div className="d-flex flex-row">
            <div className="d-flex font-light flex-column">
              <p className="font-size-14 third-grey mb-2">
                {`#${item.id} • ${item.subject}`}
              </p>
              <p className='font-14'>{`${t('_UPDATE')}: ${item.updated_at}`}</p>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <p className="color-secondary-grey font-size-14">
              <div className="d-flex mt-2 align-items-center">
                <div >{t(switchTitle(item.priority))}</div>
              </div>
              <div className="d-flex mt-2 align-items-center">
                <div >{t(switchStatus(item.status))}</div>
              </div>
            </p>
            <span className="ms-4">
              <Arrow />
            </span>
          </div>
        </div>
      </div>
      {/* <SideBar
        openSidebar={openTransaction}
        setOpenSidebar={setOpenTransaction}
        ref={transactionRef}
      >
        <Details item={item} />
      </SideBar> */}
    </div>
  );
}
