// hooks
import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

// components
import Button from '../../../components/Button';
import Toggle from '../../../components/toggle/Toggle';
import apiRequest from '../../../components/ApiRequest';
import GlobalContext from '../../../components/GlobalContext';

const NotificationsSettings = ({ notifications, setOpenSettings }) => {
  const queryClient = useQueryClient();
  const { setAlert, token } = useContext(GlobalContext);
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormik({
    initialValues: {
      add_fund: notifications.email.add_fund,
      company_news: notifications.email.company_news,
      invoice_not_pay: notifications.email.invoice_not_pay,
      invoice_pay: notifications.email.invoice_pay,
      new_package: notifications.email.new_package,
      sent: notifications.email.sent,
      shipped: notifications.email.shipped,
      newpackage_sms: notifications.sms.new_package_sms,
      shipped_sms: notifications.sms.shipped_sms
    }
  });

  const changeNotificationsSettings = useMutation(
    (values) =>
      apiRequest(
        {
          command: 'save_customer_settings',
          sessionId: token
        },
        {
          data: JSON.stringify({
            notification_settings: values
          })
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
          setOpenSettings(false);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      }
    }
  );

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      await changeNotificationsSettings.mutateAsync(values);
      queryClient.invalidateQueries('getCustomerSettings');
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className="d-flex flex-column height-100 justify-content-between sideBarChildComponent">
      <h1 className="font-size-16 bold">{t('_NOTIFICATION_MANAGEMENT')}</h1>
      <form
        className="d-flex flex-column height-100 justify-content-between form"
        onSubmit={handleSubmit}
      >
        <div className="sidebar-child-scroll">
          <div className="pr-60">
            <div >
              <p className="font-size-14 mb-2 bold">
                {t('_EMAIL_NOTIFICATIONS')}
              </p>
              <Toggle
                fontStyle="color-third-grey"
                text={t('_RECEIVING_OF_PACKAGE_IN_USA')}
                name="newpackage"
                value={values.new_package}
                checked={parseInt(values.new_package) === 1}
                onChange={() =>
                  setFieldValue(
                    'new_package',
                    values.new_package === 1 ? 0 : 1
                  )
                }
              />
              <Toggle
                fontStyle="color-third-grey"
                text={t('_PACKAGE_DELIVERED')}
                name="shipped"
                value={values.shipped}
                checked={parseInt(values.shipped) === 1}
                onChange={() =>
                  setFieldValue(
                    'shipped',
                    values.shipped === 1 ? 0 : 1
                  )
                }
              />
              <Toggle
                fontStyle="color-third-grey"
                text={t('_INVOICE_PAYMENTS')}
                name="invoicepay"
                value={values.invoice_pay}
                checked={parseInt(values.invoice_pay) === 1}
                onChange={() =>
                  setFieldValue(
                    'invoice_pay',
                    values.invoice_pay === 1 ? 0 : 1
                  )
                }
              />
              <Toggle
                fontStyle="color-third-grey"
                text={t('_INSUFFICIENT_FUNDS')}
                name="invoicenotpay"
                value={values.invoice_not_pay}
                checked={parseInt(values.invoice_not_pay) === 1}
                onChange={() =>
                  setFieldValue(
                    'invoice_not_pay',
                    values.invoice_not_pay === 1 ? 0 : 1
                  )
                }
              />
              <Toggle
                fontStyle="color-third-grey"
                text={t('_PACKAGE_SENT')}
                name="sent"
                value={values.sent}
                checked={parseInt(values.sent) === 1}
                onChange={() =>
                  setFieldValue('sent', values.sent === 1 ? 0 : 1)
                }
              />
              <Toggle
                fontStyle="color-third-grey"
                text={t('_ADD_FUNDS')}
                name="addfund"
                value={values.add_fund}
                checked={parseInt(values.add_fund) === 1}
                onChange={() =>
                  setFieldValue(
                    'add_fund',
                    values.add_fund === 1 ? 0 : 1
                  )
                }
              />
              <Toggle
                fontStyle="color-third-grey"
                text={t('_COMPANY_NEWS_AND_PROMOTIONS')}
                name="companynews"
                value={values.company_news}
                checked={parseInt(values.company_news) === 1}
                onChange={() =>
                  setFieldValue(
                    'company_news',
                    values.company_news === 1 ? 0 : 1
                  )
                }
              />
            </div>
            <div>
              <p className="font-size-14 mb-2 mt-4 pt-2 bold">
                {t('_SMS_NOTIFICATIONS')}
              </p>
              <Toggle
                fontStyle="color-third-grey"
                text={t('_RECEIVING_OF_PACKAGE_IN_USA')}
                name="newpackage_sms"
                value={values.newpackage_sms}
                checked={parseInt(values.newpackage_sms) === 1}
                onChange={() =>
                  setFieldValue(
                    'newpackage_sms',
                    values.newpackage_sms === 1 ? 0 : 1
                  )
                }
              />
              <Toggle
                fontStyle="color-third-grey"
                text={t('_PACKAGE_DELIVERED')}
                name="shipped_sms"
                value={values.shipped_sms}
                checked={parseInt(values.shipped_sms) === 1}
                onChange={() =>
                  setFieldValue(
                    'shipped_sms',
                    values.shipped_sms === 1 ? 0 : 1
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="sidebarButtonDiv">
          <Button
            isLoading={changeNotificationsSettings.isLoading}
            type="submit"
            btnStyle="bg-orange primary-btn-w-h"
            text={t('_SAVE')}
            color="color-white"
          />
        </div>
      </form>
    </div>
  );
};

export default NotificationsSettings;
