// hooks
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { LockerBgIcon, LockerIcon, WhiteArrowIcon } from '../assets/icons';
import SmallIcon from './SmallIcon';

const LockerDetails = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center">
      <div className="mt-3 w-full position-relative">
        <div className="locker-details-bg">
          <div className="abs-img">
            <LockerBgIcon />
          </div>
        </div>
        <div className="locker-img-div">
          <div className="w-full mb-2">
            <LockerIcon />
          </div>
          <div
            className="color-gray font-size-14 mt-3"
            dangerouslySetInnerHTML={{ __html: t('_GET_PARCELS') }}
          />
        </div>
        <a
          className="go-to-locker-btn"
          href="https://www.locker.ge/"
          target="_blank"
          rel="noreferrer"
        >
          <SmallIcon
            containerStyle="small-icon-div bg-orange"
            img={<WhiteArrowIcon />}
            // imgStyle="rotate90"
          />
        </a>
      </div>
    </div>
  );
};

export default LockerDetails;
