// hooks
import { useCallback } from 'react';

// utils
import classNames from 'classnames';

// components
import Select from 'react-select';

const customControlStyles = {
  color: 'white',
  borderColor: '#C8D0E3BA',
  borderRadius: '12px',
  height: '60px'
};
const dropDownIndicatorStyles = {
  color: '#C8D0E3BA'
};

const menuStyles = {
  borderRadius: '8px',
  borderColor: 'red',
  color: '#8F9BB3',
  padding: '0',
  maxHeight: '400px',
  fontSize: '14px'
};
const placeholderStyles = {
  fontSize: '14px'
};

const Selector = ({
  label,
  required,
  error,
  className,
  labelClassName,
  placeholder,
  disabled = false,
  isCreatable = false,
  ...props
}) => {
  const selectStyle = useCallback(
    () => ({
      control: (provided) => ({
        ...provided,
        ...customControlStyles,
        height: '60px',
        marginTop: '24px',
        // eslint-disable-next-line no-extra-boolean-cast
        borderColor: '#DDDDDD',
        borderWidth: '1px',
        boxShadow: 'none !important',
        fontSize: '14px',
        ':hover': {
          borderColor: '#F68220',
          borderWidth: '1px',
          outline: 'none !important',
          boxShadow: 'none !important'
        },
        ':focus': {
          borderColor: '#F68220 !important',
          boxShadow: 'none !important'
        }
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        ...dropDownIndicatorStyles
      }),
      menu: (provided) => ({
        ...provided,
        ...menuStyles
      }),
      placeholder: (provided) => ({
        ...provided,
        ...placeholderStyles
      }),
      option: (provided, { isFocused }) => ({
        ...provided,
        color: isFocused ? 'white' : 'black',
        background: isFocused ? '#F68220' : 'inherit',
        fontSize: '14px',
        fontFamily: 'helvetica'
      }),
      input: (provided) => ({
        ...provided,
        border: 'none',
        fontSize: '14px',
        ':hover': {
          border: 'none'
        }
      }),
      menuList: (provided) => ({
        ...provided,
        padding: 0,
        border: '1px solid #DDDDDD',
        borderRadius: '12px'
      })
    }),

    [error]
  );

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div className={className}>
      {label && (
        // eslint-disable-next-line react/react-in-jsx-scope
        <label
          className={classNames(
            'block text-black text-sm font-bold mb-2',
            labelClassName
          )}
          htmlFor={label?.split(' ').join()}
        >
          {label}
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          {required && <span className="text-red ml-2">*</span>}
        </label>
      )}
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <Select
        inputId={label?.split(' ').join()}
        placeholder={placeholder}
        styles={selectStyle()}
        components={{
          IndicatorSeparator: null
        }}
        {...props}
      />
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <div>{error}</div>
    </div>
  );
};
export default Selector;
