import React from 'react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ExternalLinkIcon } from '../../../assets/icons';
import './BranchInfo.css';

SwiperCore.use([Pagination]);

export const BranchInfo = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Swiper
      pagination={{
        clickable: true
      }}
      loop={true}
      grabCursor={true}
      breakpoints={{
        768: {
          slidesPerView: 1
        },
        200: {
          slidesPerView: 1
        }
      }}
      slidesPerView={2}
      spaceBetween={10}
      className='u2g-branch-list'
    >
      <div>
        {data.map((branch, index) => (
          <SwiperSlide key={index}>
            <div style={{ border: '1px solid #ddd', borderRadius: 8, padding: 10, marginBottom: 30 }}>
              <div style={{ fontSize: 17, fontFamily: 'HelveticaBoldCaps', marginBottom: 0 }}>{branch.name}</div>
              <div style={{ fontSize: 13, marginBottom: 10 }}>{branch.address}</div>
              {branch.working_hour.map((element, index) => {
                return (
                  <div key={index}>
                    {element.key}: {element.value}
                  </div>
                );
              })}
              <hr />
              <div style={{ textAlign: 'center', marginTop: -5 }}>
                <a style={{ color: '#f68220', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }} href={branch.map_link} target={'_blank'} rel='noreferrer'>
                  {t('_SHOW_ON_MAP')}
                  <div>
                    <ExternalLinkIcon className='u2g-message-action-button-external-link' />
                  </div>
                </a>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </div>
    </Swiper>
  );
};
