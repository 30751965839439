// hooks
import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import OrangeLine from './OrangeLine';
import SmallIcon from './SmallIcon';
import { ChatIcon, PhoneIcon } from '../assets/icons';

export default function Contact({ title }) {
  const { t } = useTranslation();

  return (
    <div>
      {title && (
        <div className="contact-title">
          <OrangeLine />
          <h2 className="about-us-title mt-2">{title}</h2>
        </div>
      )}
      <div className="d-flex flex-wrap">
        <div className="mt-4">
          <div className="contact-div me-4">
            <h4 className="font-size-14 mb-3 contact-a font-weight-bold">
              {t('_CALL_US')}
            </h4>
            <a href="tel:+995322421818">
              <div className="d-flex justify-content-between align-items-center">
                <div className="me-4">
                  <p className="font-size-14 color-gray">(+995 32) 2-42-18-18</p>
                </div>
                <span>
                    <SmallIcon bg="bg-orange" img={<PhoneIcon />} />
                </span>
              </div>
            </a>
          </div>
        </div>
        <div className="mt-4">
          <div className="contact-div">
              <h4 className="font-size-14 contact-a mb-3 font-weight-bold">
                {t('_WRITE_US')}
              </h4>
              <a
                href="mailto:sales@usa2georgia.com"
                target="_blank"
                rel="noreferrer"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="me-4">
                    <p className="font-size-14 color-gray">
                      sales@usa2georgia.com
                    </p>
                  </div>
                  <SmallIcon bg="bg-orange" img={<ChatIcon />} />
                </div>
              </a>
          </div>
        </div>
      </div>
    </div>
  );
}
